import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { EducationCard, HighlightCard, MainCard, ServiceHighlight } from "../../components/cards/card";
import { useNavigate, useParams } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { deleteJobAction, jobDetailAction } from "../../redux1/Slices/servicesSlice";
import { useDispatch, useSelector } from "react-redux";
import budgetIcon from "../../assets/icons/budget.png";
import contractIcon from "../../assets/icons/contract.png";
import timeframeIcon from "../../assets/icons/timeframe.png";
import locationIcon from "../../assets/icons/location.png";
import notesIcon from "../../assets/icons/notes.png";
import { formatDate, imagePath, showFormErrors } from "../../utils/commonFunctions";
import { Chip } from "primereact/chip";
import { RadioButton } from "primereact/radiobutton";
import { coachRequestByIdAction, updateCoachRequestAction } from "../../redux1/Slices/coachRequestsSlice";
import PrimaryButton from "../../components/buttons/button";
import { showToast } from "../../redux1/Slices/toastSlice";

export default function ViewCoachRequest() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const service = useSelector((state) => state.services.details);
    const [dialog, setDialog] = useState({ visible: false });
    const [data, setData] = useState({});

    const breadCrumbItems = [{ label: "Coach Request Details" }];

    const getCoachRequest = () => {
        dispatch(coachRequestByIdAction({ id })).then((res) => {
            setData({
                ...res?.payload
            });
        });
    };

    const handleSubmitCoachRequest = () => {
        if (showFormErrors(data, setData)) {
            dispatch(updateCoachRequestAction({ id: data._id, data, setDialog }));
        }
    };

    const approveRejectCoachRequest = (id) => {
        if (data.status && data.status !== "Waiting") {
            setDialog({
                visible: true,
                title: `Are you sure you want to ${data.status === "Approved" ? "Approve" : "Reject"} this coach request?`,
                description: `Are you sure you want to ${data.status === "Approved" ? "Approve" : "Reject"} this coach request?`,
                type: "CONFIRM",
                cancel: true,
                saveFunction: () => handleSubmitCoachRequest(),
            });
        } else {
            dispatch(showToast({ severity: "primary", summary: "Please choose any status to continue." }));
        }
    };

    useEffect(() => {
        if (id) getCoachRequest();
    }, [id]);

    return (
        <DashboardLayout heading="Service Request">
            <BreadCrumb
                model={breadCrumbItems}
                home={{
                    label: "Service Requests",
                    template: () => (
                        <span className="cursor-pointer" onClick={() => navigate(-1)}>
                            Service Requests
                        </span>
                    ),
                }}
                pt={{
                    root: { className: "bg-transparent border-none p-0 font-semibold " },
                }}
            />
            <div className="flex justify-content-between align-items-center">
                <h2>Coach Request Details</h2>
            </div>
            <MainCard extraClass="mt-0" height="auto" dialog={dialog} setDialog={setDialog}>
                <div className="flex flex-wrap">
                    <ServiceHighlight icon={imagePath(service?.category?.icon || "")} title="Full Name" description={data?.employee?.fullName} />
                    <ServiceHighlight icon={imagePath(service?.service?.icon || "")} title="Service" description={data?.service} />
                    <ServiceHighlight icon={contractIcon} title="Requested Date" description={formatDate(data?.createdAt)} />

                </div>
            </MainCard>
            <div className="flex gap-5 mt-5">

                <div className="flex align-items-center">
                    <RadioButton inputId="status" name="status" value="Approved"
                        onChange={(e) => setData({ ...data, status: e.value })}
                        checked={data?.status === "Approved"} />
                    <label htmlFor="ingredient1" className="ml-2">Approve</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton inputId="status" name="status" value="Rejected" onChange={(e) => setData({ ...data, status: e.value })}
                        checked={data?.status === "Rejected"} />
                    <label htmlFor="ingredient1" className="ml-2">Reject</label>
                </div>

            </div>
            <h5 className="font-semibold mb-2">Reason</h5>
            <MainCard extraClass="mt-2" height="auto" dialog={dialog} setDialog={setDialog}>
                <p className="text-sm sub-headings">{data?.description}</p>
            </MainCard>

            <div className="flex w-full gap-3 justify-content-end mt-8">
                <PrimaryButton className={"px-7"} label={"Done"} onClick={approveRejectCoachRequest} />
                <PrimaryButton className={"px-7"} label={"Cancel"} secondary onClick={() => navigate(-1)} />
            </div>
        </DashboardLayout>
    );
}
