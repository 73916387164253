import React from "react";
import { Privatelayout } from "../layout/Privatelayout";
import LoginImg from "../../assets/images/loginleft.png";
import { CustomForm } from "../../components/input/input";
import PrimaryButton from "../../components/buttons/button";
import ApproveImg from "../../assets/icons/approveicon.png";
import { useNavigate } from "react-router-dom";

export const EmployeePasswordChanged = () => {
    const navigate = useNavigate();
    const navigateToLogin = () => {
        navigate("/employee-login");
    };
    return (
        <>
            {/* <PublicTopbar /> */}
            <Privatelayout
                childClassName="w-full md:w-8 flex flex-column justify-content-center align-items-center "
                src={LoginImg}
                SuccessImg={ApproveImg}
                heading="Password Reset"
                subheading="Your password has been successfully reset. Click below to log in magically."
                subtextsize={"text-sm w-5"}
            >
                <CustomForm>
                    <PrimaryButton col={12} className={"bg-white text-primary "} extraClass="w-full" label="Back to login" onClick={navigateToLogin} />
                </CustomForm>
            </Privatelayout>
        </>
    );
};
