import React, { useState, useEffect } from "react";
import { Privatelayout } from "../layout/Privatelayout";
import LoginImg from "../../assets/images/loginleft.png";
import { CustomForm, CustomInput, CustomPassword } from "../../components/input/input";
import PrimaryButton from "../../components/buttons/button";
import { Link, useNavigate } from "react-router-dom";
import { SocialLogin } from "../SocialButtons/SocialButton";
import { useDispatch } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { loginAction } from "../../redux1/Slices/loginSlice";
import { isAuthenticated } from "../../services/auth";
import PublicTopbar from "../../layout/PublicTopbar";
import { loginWithMicrosoftSSO } from "../../components/auth/MicrosoftAuth";

export const Login = () => {
    const [data, setData] = useState({
        email: "",
        loginPassword: "",
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleLogin = () => {
        if (showFormErrors(data, setData)) {
            dispatch(loginAction({ data, navigate }));
        }
    };
    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            navigate("/dashboard");
        }
    }, [navigate]);
    return (
        <>
            {/* <PublicTopbar /> */}
            <Privatelayout childClassName=" flex flex-column justify-content-center align-items-center col-8 " src={LoginImg} heading="Login to your Account" subheading="Welcome back! Glad to see you.">
                {/* <div className="md:col-6 flex justify-content-center  "> */}
                <CustomForm>
                    <CustomInput
                        labelClassName="text-white"
                        label="Email Address"
                        className="bg-white  w-full border-round-lg"
                        required
                        col={12}
                        placeholder="Enter Email"
                        name="email"
                        data={data}
                        onChange={handleChange}
                    />
                    <CustomPassword
                        label="Password "
                        hideFooter
                        labelClassName="text-white"
                        inputClassName="bg-white w-full border-round-lg"
                        col={12}
                        placeholder="Enter Password"
                        name="loginPassword"
                        data={data}
                        onChange={handleChange}
                    />
                    <div className="col-12 text-right mb-3">
                        <Link to="/forget-password" className="text-skyblue no-underline text-sm font-medium">
                            Forgot password?
                        </Link>
                    </div>
                    <PrimaryButton label="Sign In" className={"bg-white text-primary"} extraClass="w-full" onClick={handleLogin} />
                    <SocialLogin microsoftlabel="Sign In with Microsoft" onMicrosoftClick={() => loginWithMicrosoftSSO(dispatch, navigate)} />
                    <p className="text-center text-white font-normal col-12 mt-5 ">
                        Don’ t have Account? &nbsp;
                        <Link to="/register" className="no-underline text-skyblue font-semibold">
                            SignUp
                        </Link>
                    </p>
                </CustomForm>
                {/* </div> */}
            </Privatelayout>
        </>
    );
};
