import React, { useEffect, useState } from "react";
import { MainCard } from "../../components/cards/card";
import CustomTable from "../../components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { getSurveysAction, upadteSurveyAction } from "../../redux1/Slices/surveySlice";
import { useNavigate } from "react-router-dom";
import { formatDate, shareFunction } from "../../utils/commonFunctions";
import { useDebounce } from "primereact/hooks";

export default function SurveyTable({ status }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const surveyList = useSelector((state) => state.survey.surveyList?.data);
    const totalCreatedSurveys = useSelector((state) => state.survey.surveyList?.totalSurveyCount);
    const [dialog, setDialog] = useState({ visible: false });
    const [searchFor, searchString, setSearchFor] = useDebounce("", 1000);
    const [dates, setDates] = useState(null);
    const user = useSelector((state) => state.user.data);

    // Table Columns
    const activeSurveyColumns = [
        { field: "title", header: "Survey Name", sortable: true },
        { field: "startDate", header: "Start Date", body: "DATE", sortable: true },
        { field: "expiringOn", header: "End Date", body: "DATE", sortable: true },
        { field: "nextSurveyDate", header: "Next Survey", body: "DATE", sortable: true },
        { field: "status", header: "Status", body: "STATUS_ACTION", sortable: true },
        { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
    ];
    const savedSurveyColumns = [
        { field: "title", header: "Survey Name" },
        { field: "createdAt", header: "Created On", body: "DATE", sortable: true },
        { field: "startDate", header: "Starting On", body: "DATE", sortable: true },
        { field: "expiringOn", header: "Expiring On", body: "DATE", sortable: true },
        { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
    ];
    const inActiveSurveyColumns = [
        { field: "title", header: "Survey Name", sortable: true },
        { field: "expiringOn", header: "Expired On", body: "DATE", sortable: true },
        { field: "nextSurveyDate", header: "Activation Date", body: "DATE", sortable: true },
        { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
    ];

    // Required Functions

    const getSurveys = (query) => {
        dispatch(getSurveysAction({ query }));
        setDialog({ visible: false });
    };

    const searchDepartment = (searchString) => {
        const searchKeys = ["title"];
        const search = `searchKeys=${JSON.stringify(searchKeys)}&searchString=${searchString}`;
        return search;
    };

    const filterDates = (dates) => {
        let filterDates = {
            startDate: formatDate(dates[0], "YY-MM-DD"),
            endDate: dates[1] !== null ? formatDate(dates[1], "YY-MM-DD") : formatDate(dates[0], "YY-MM-DD"),
        };
        return filterDates;
    };

    // Funcations for Table Actions

    const viewSurvey = (col) => {
        navigate("/surveys/view-survey/" + col._id);
    };

    const shareSurvey = (col) => {
        navigate("/surveys/send-survey/" + col._id);
    };

    const editSurvey = (col) => {
        navigate("/surveys/edit/" + col._id);
    };

    const confirmDelete = (id) => {
        setDialog({
            visible: true,
            title: "Are you sure you want to delete Survey?",
            type: "DELETE",
            cancel: true,
            saveFunction: () => deleteSurvey(id),
        });
    };
    const handlecSurveyStatus = (id) => {
        setDialog({
            visible: true,
            title: "Are you sure you want to make Survey Inactive?",
            type: "CONFIRM",
            cancel: true,
            saveFunction: () => dispatch(upadteSurveyAction({ data: { status: "INACTIVE" }, surveyId: id, next: () => getSurveys(`filterData=${JSON.stringify({ status: "ACTIVE" })}`) })),
        });
    };

    const deleteSurvey = (surveyId) => {
        let data = { isDeleted: true };
        dispatch(upadteSurveyAction({ data, surveyId, next: getSurveys }));
    };

    const surveyAnalytics = (surveyId) => {
        navigate("analytics/" + surveyId);
    };

    // UseEffect calls
    useEffect(() => {
        let query = `filterData=${JSON.stringify({ status: status.toUpperCase() })}`;
        if (dates?.length) {
            query = `filterData=${JSON.stringify({ status: status.toUpperCase(), [status === "Inactive" ? "expiringOn" : "startDate"]: filterDates(dates) })}`;
        }
        if (searchString) {
            query = "&" + searchDepartment(searchString);
        }
        getSurveys(query);
    }, [status, searchString, dates]);

    return (
        <MainCard
            title={`${status ? status : ""} Surveys`}
            search
            onChange={setSearchFor}
            dateFilter={setDates}
            // subtitle={"Surveys " + totalCreatedSurveys + "/" + user?.numberOfSurveys}
            filterKey={status === "Saved" ? "Starting on" : status === "Active" ? "Started on" : "Expired on"}
            dates={dates}
            dialog={dialog}
            setDialog={setDialog}
        >
            <CustomTable
                columns={status === "Active" ? activeSurveyColumns : status === "Saved" ? savedSurveyColumns : inActiveSurveyColumns}
                data={surveyList && surveyList}
                view={viewSurvey}
                edit={status !== "Active" ? editSurvey : ""}
                trash={status !== "Active" ? confirmDelete : ""}
                analytics={status !== "Saved" ? surveyAnalytics : ""}
                shareFunction={shareSurvey}
                handlecSurveyStatus={handlecSurveyStatus}
            />
        </MainCard>
    );
}
