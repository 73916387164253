import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    data: []
};

export const addCoachRequestAction = createAsyncThunk("employee/request_coach", async ({ payload, setRequestCoachModal }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("post", Constants.END_POINT.REQUEST_COACH, payload);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            setRequestCoachModal(false);
            dispatch(coachRequestAction(""));
            return res.data;
        } else {
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

export const updateCoachRequestAction = createAsyncThunk("employee/update_coach_request", async ({ id, data, setDialog }, { dispatch }) => {
    try {
        dispatch(showLoader());
        const payload = {
            ...data,
        };
        console.log(payload)
        const res = await api("put", Constants.END_POINT.UPDATE_COACH_REQUEST + id, { ...payload });

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            dispatch(coachRequestAction(""));
            setDialog(false);
            return res.data;
        } else {
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

export const coachRequestAction = createAsyncThunk("employee/get_coach_requests", async ({ query = "" }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api("get", Constants.END_POINT.REQUEST_COACH + "?" + query);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(coachRequestSlice.actions.setCoachRequestDetail(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(coachRequestSlice.actions.setCoachRequestDetail([]));
            return Promise.reject(res.message);
        }
    } catch (error) {
        // dispatch(showToast({ severity: "error", summary: error.message }));

        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const coachRequestByIdAction = createAsyncThunk("employee/get_coachRequestById", async ({ id }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.REQUEST_COACH + id);
        if (res.success && res.data) {
            return res.data;
        } else {
            return Promise.reject(res.message);
        }
    } catch (error) {
        // dispatch(showToast({ severity: "error", summary: error.message }));

        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});


const coachRequestSlice = createSlice({
    name: "coachRequests",
    initialState,
    reducers: {
        setCoachRequestDetail: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setCoachRequestDetail } = coachRequestSlice.actions;

export default coachRequestSlice.reducer;
