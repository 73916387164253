import React, { useEffect, useState } from "react";
import { MainCard } from "../../../components/cards/card";
import { ReactComponent as NoSearchDepartments } from "../../../assets/svg/no-search-departments.svg";
import { ReactComponent as NoDepartments } from "../../../assets/svg/no-departments.svg";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../components/table/table";
import { departmentAction, updateDepartmentAction } from "../../../redux1/Slices/departmentSlice";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "primereact/hooks";

export default function ManageDepartments() {
    const departments = useSelector((state) => state.department.data);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({ visible: false });
    const [searchFor, searchString, setSearchFor] = useDebounce("", 1000);

    const tableColumns = [
        { field: "name", header: "Department Name", style: { width: "25%" } },
        { field: "description", header: "Description", style: { width: "60%", maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis" } },
        { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
    ];

    const emptyTable = () => {
        return <div className="flex justify-content-center">{searchString ? <NoSearchDepartments /> : <NoDepartments />}</div>;
    };

    const getDepartment = () => {
        dispatch(departmentAction({ accessToken: localStorage.getItem("authToken") }));
        setDialog({ visible: false });
    };

    const confirmDelete = (id) => {
        setDialog({
            visible: true,
            title: "Are you sure you want to delete Department?",
            type: "DELETE",
            cancel: true,
            saveFunction: () => deleteDepartment(id),
        });
    };

    const deleteDepartment = (id) => {
        const data = {
            id: id,
            isDeleted: true,
            enableDelete: true,
        };
        dispatch(updateDepartmentAction({ data, getDepartment }));
    };

    const viewDepartment = (col) => {
        console.log("col", col);
        setDialog({
            visible: true,
            title: col.name,
            description: col.description,
            type: "SAVE",
            cancelIcon: true,
        });
    };

    const editDepartment = (col) => {
        navigate("manage-departments/edit/" + col._id);
    };

    const searchDepartment = (searchString) => {
        const searchKeys = ["name", "description"];
        const search = `?searchKeys=${JSON.stringify(searchKeys)}&searchString=${searchString}`;
        dispatch(departmentAction({ search: search }));
    };

    useEffect(() => {
        getDepartment();
    }, []);

    useEffect(() => {
        searchDepartment(searchString);
    }, [searchString]);

    return (
        <MainCard
            title="Manage Departments"
            search
            onChange={setSearchFor}
            buttonLable="Add"
            onClick={() => navigate("manage-departments/add")}
            style={{ minHeight: "77vh" }}
            dialog={dialog}
            setDialog={setDialog}
        >
            <CustomTable columns={tableColumns} emptyMessage={emptyTable} view={viewDepartment} edit={editDepartment} trash={confirmDelete} showLines={departments.length} data={departments} />
        </MainCard>
    );
}
