import React, { useState } from "react";
import { Privatelayout } from "../layout/Privatelayout";
import LoginImg from "../../assets/images/loginleft.png";
import { CustomForm, CustomPassword } from "../../components/input/input";
import PrimaryButton from "../../components/buttons/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { api } from "../../services/api";
import { hideLoader, showLoader } from "../../redux1/Slices/loaderSlice";
import { showToast } from "../../redux1/Slices/toastSlice";
import { showFormErrors } from "../../utils/commonFunctions";
import Constants from "../../services/constants";
import PublicTopbar from "../../layout/PublicTopbar";
var base64 = require("base-64");

export const ResetPassword = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { encodedEmail } = params;
    const email = base64.decode(encodedEmail);
    const navigateToLogin = () => {
        navigate("/sign-in");
    };
    const [data, setData] = useState({
        password: "",
        confirmPassword: "",
    });
    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleContinue = async () => {
        if (showFormErrors(data, setData)) {
            dispatch(showLoader());
            const payload = { email: email, password: data?.password };
            const res = await api("post", Constants.END_POINT.RESET_PASSWORD, payload);
            dispatch(hideLoader());
            if (res.success) {
                navigate("/password-changed");
            } else {
                dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
            }
        }
    };
    return (
        <>
            {/* <PublicTopbar /> */}
            <Privatelayout
                childClassName=" flex flex-column justify-content-center align-items-center "
                src={LoginImg}
                heading="Reset Password"
                subheading="Your new password must be unique from those previously used."
                subtextsize="text-sm"
            >
                <CustomForm>
                    <CustomPassword
                        labelClassName="text-white"
                        label="New Password"
                        inputClassName="bg-white w-full border-round-lg"
                        col={12}
                        placeholder="Enter Password"
                        name="password"
                        data={data}
                        onChange={handleChange}
                    />
                    <CustomPassword
                        labelClassName="text-white"
                        label="Confirm Password"
                        inputClassName="bg-white w-full border-round-lg"
                        col={12}
                        placeholder="Enter Password"
                        name="confirmPassword"
                        data={data}
                        onChange={handleChange}
                    />
                    <PrimaryButton label="Continue" extraClass="w-full" col={12} className={"bg-white text-primary"} onClick={handleContinue} />
                </CustomForm>
            </Privatelayout>
        </>
    );
};
