export default class Constants {
    static WEBSITE_URL = "https://devapp.culturequest.io/#/";
    // static WEBSITE_URL = "https://app.culturequest.io/#/"
    // static WEBSITE_URL = "http://localhost:3000/#/";

    static BASE_URL = "https://devapi.culturequest.io/api/";
    // static BASE_URL = "https://api.culturequest.io/api/";
    // static BASE_URL = "http://localhost:3034/api/";

    static HOST = "https://devapi.culturequest.io/";
    // static HOST = "https://api.culturequest.io/";
    // static HOST = "http://localhost:3034/";

    // static MS_CLIENT_ID = "4802f36b-24e6-44f3-a274-af531c86306e"; //prod
    static MS_CLIENT_ID = "9d9e9710-ba18-491f-af11-0eb40ace7414"; // dev

    // static MS_TENANT_ID = "f8cdef31-a31e-4b4a-93e4-5f571e91255a" //prod
    // static MS_TENANT_ID = "681721ce-a5e9-42dc-9b5c-4800574bb59c" //dev 

    // static MS_REDIRECT_URI = "http://localhost:3000"// local
    static MS_REDIRECT_URI = "https://devapp.culturequest.io" //dev
    // static MS_REDIRECT_URI = "https://app.culturequest.io"

    static GOOGLE_CLIENT_ID = "965177103608-7j2br9ie9v6pmbdjc2hauddmpbgot8h9.apps.googleusercontent.com"; // dev //
    // static GOOGLE_CLIENT_ID = "855914693914-sfttvhi0j3rj28h4g12c2ou9gtekh35n.apps.googleusercontent.com"; // live //

    static BOOK_COACH_URL = "https://system.easypractice.net/book/culturequest#choose-where";


    static END_POINT = {
        getOtp: "user/request-otp",
        login: "user/login",
        forgetOtp: "user/forget-password",
        changePassword: "user/change-forget-password",
        checkEmail: "user/check-email",
        REQUEST_OTP: "user/request-otp",
        REGISTER: "user/signup",
        SOCIAL_LOGIN: "user/social-signin",
        LOGIN: "user/login",
        FORGEOT_PASSWORD_EMAIL: "user/forget-password",
        VERIFY_FORGOT_OTP: "user/verify-forget-otp",
        RESET_PASSWORD: "user/change-forget-password",
        UPDATE_PROFILE: "user/update",
        MY_PROFILE: "user/my-profile",
        UPLOAD_PROFILE_IMAGE: "user/upload-profile-image",
        UPDATE_PASSWORD: "user/change-password",
        DEACTIVATE_PROFILE: "user/deactivate",

        // Departments
        ADD_DEPARTMENT: "departments/create",
        GET_DEPARTMENT: "departments",
        GET_DEPARTMENT_BY_ID: "departments/",
        UPDATE_DEPARTMENT: "departments/update/",
        DELETE_DEPARTMENT: "departments/delete/",

        // Categories and Questions
        GET_CATEGORIES: "categories",
        GET_QUESTIONS: "questions",

        // Dashboard
        ANALYTICS_OVERVIEW: "survey-analytics",
        CATEGORY_SCORES: "survey-analytics/category-scores",
        DEPARTMENT_SCORES: "survey-analytics/department-scores",
        DEPARTMENT_SCORES_BY_CATEGORY: "survey-analytics/department-scores-by-category/",
        FOCUS_AREA: "survey-analytics/focus-area",
        FOCUS_AREA_BY_DEPARTMENT: "survey-analytics/focus-area-by-department/",
        ANALYTICS_PROGRESS: "survey-analytics/progress-by-categories",
        CATEGORY_SCORE_BY_ID: "survey-analytics/yearly-department-scores/",
        DEPARTMENT_SCORE_BY_ID: "survey-analytics/yearly-category-scores/",

        // Survey
        ADD_SURVEY: "surveys/create",
        GET_SURVEY: "surveys",
        GET_PUBLIC_SURVEY: "surveys/public",
        SEND_SURVEY: "surveys/send-survey-link/",
        UPDATE_SURVEY: "surveys/update/",
        SUBMIT_SURVEY: "user-surveys/create",

        // Services
        GET_SERVICES: "services",
        REQUEST_SERVICE: "request-services",
        REQUESTED_SERVICES: "request-services",

        // Employees
        EMP_LOGIN: "employees/login",
        EMP_PROFILE: "employees/my-profile",
        UPDATE_EMP_PASSWORD: "employees/change-password",
        EMP_FORGOT_PASSWORD_EMAIL: "employees/forget-password",
        EMP_VERIFY_FORGOT_OTP: "employees/verify-forget-otp",
        DEACTIVATE_EMP_PROFILE: "",
        UPDATE_EMP_PROFILE: "",
        EMPLOYEES: "employees",
        EMPLOYEES_BY_DEPT: "employees/by-dept-ids",
        EMPLOYEES_UPLOAD: "employees/upload",
        EMPLOYEES_VALIDATE_CSV: "employees/validate-csv",
        EMPLOYEES_BULK_UPDATE_DEPT: "employees/update-departments",
        GET_OPEN_SURVEYS: "employees/open-surveys",
        GET_FILLED_SURVEYS: "employees/filled-surveys",
        GET_PRIVATE_SURVEY: "employees/get-survey",
        GET_SURVEY_ANALYTICS: "employees/survey-analytics",
        EMP_CATEGORY_SCORES: "employees/progress-score",
        EMP_FOCUS_AREA: "employees/focus-area",

        //COACH REQUESTS
        REQUEST_COACH: "coaching-requests/",
        UPDATE_COACH_REQUEST: "coaching-requests/update/",
        GET_COACH_REQUEST_BY_ID: "",

        // Help
        HELP: "help",


        // Notifications
        NOTIFICATIONS: "notifications",
    };
}
