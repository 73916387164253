import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    overview: [],
    categoryScores: [],
    departmentScores: {},
    focusArea: [],
    progress: {},
    categoryScoresByDept: [],
    departmentScoresByCat: [],
    surveyAnalytic: {},
};

// Dashboard overview cards
export const overviewAction = createAsyncThunk("analytics/get_overview", async ({ age, gender }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.ANALYTICS_OVERVIEW + (age || gender ? "?" + (age ? "age=" + age + "&" : "") + (gender ? "gender=" + gender : "") : ""));
        if (res.success && res.data) {
            dispatch(analyticsSlice.actions.setOverview(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(analyticsSlice.actions.setOverview(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Pie chart
export const categoryScoresAction = createAsyncThunk("analytics/get_categoryScores", async ({ id, deptId, age, gender }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api(
            "get",
            Constants.END_POINT.CATEGORY_SCORES +
            (id ? `/${id + (deptId ? "?departmentId=" + deptId : "")}` : "") +
            (age || gender ? "?" + (age ? "age=" + age + "&" : "") + (gender ? "gender=" + gender : "") : "")
        );
        if (res.success && res.data) {
            dispatch(analyticsSlice.actions.setCategoryScores(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(analyticsSlice.actions.setCategoryScores(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Focus Area
export const focusAreaAction = createAsyncThunk("analytics/get_focusArea", async ({ id, department = false }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", department ? Constants.END_POINT.FOCUS_AREA + "?" + `departmentId=${id}` : Constants.END_POINT.FOCUS_AREA + (id ? "?" + `categoryId=${id}` : ""));
        if (res.success && res.data) {
            dispatch(analyticsSlice.actions.setFocusArea(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(analyticsSlice.actions.setFocusArea(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Progress Chart
export const progressAction = createAsyncThunk("analytics/get_progressScrores", async ({ id, age, gender, year, startMonth, endMonth, categoryId }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api(
            "get",
            Constants.END_POINT.ANALYTICS_PROGRESS +
            "?" +
            (age ? "age=" + age : "") +
            (gender ? "&gender=" + gender : "") +
            (year ? "&year=" + year : "") +
            (startMonth ? "&startMonth=" + startMonth : "") +
            (endMonth ? "&endMonth=" + endMonth : "") +
            (categoryId ? "&categoryId=" + categoryId : "")
        );
        if (res.success && res.data) {
            dispatch(analyticsSlice.actions.setProgress(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(analyticsSlice.actions.setProgress(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// dashboard department scores
export const departmentScoresAction = createAsyncThunk("analytics/get_departmentScores", async ({ id, age, gender, categoryId, departmentId }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api(
            "get",
            id
                ? Constants.END_POINT.DEPARTMENT_SCORES_BY_CATEGORY + id
                : Constants.END_POINT.DEPARTMENT_SCORES +
                (age || gender || categoryId || departmentId
                    ? "?" +
                    (age ? "age=" + age + "&" : "") +
                    (gender ? "gender=" + gender : "") +
                    (categoryId ? "categoryId=" + categoryId : "") +
                    (departmentId ? "departmentId=" + departmentId : "")
                    : "")
        );
        if (res.success && res.data) {
            dispatch(analyticsSlice.actions.setDepartmentScores(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(analyticsSlice.actions.setDepartmentScores(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Line Chart
export const categoryScoresByIdAction = createAsyncThunk("analytics/get_categoryScoresById", async ({ id, deptId }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.CATEGORY_SCORE_BY_ID + (id ? `${id + (deptId ? "?departmentId=" + deptId : "")}` : ""));
        if (res.success && res.data) {
            dispatch(analyticsSlice.actions.setCategoryScoresById(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(analyticsSlice.actions.setCategoryScoresById(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Line Chart
export const departmentScoresByIdAction = createAsyncThunk("analytics/get_departmentScoresById", async ({ id, year, catId }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api(
            "get",
            Constants.END_POINT.DEPARTMENT_SCORE_BY_ID + (id ? `${id + (year || catId ? "?" + (year ? "year=" + year + "&" : "") + (catId ? "categoryId=" + catId : "") : "")}` : "")
        );
        if (res.success && res.data) {
            dispatch(analyticsSlice.actions.setDepartmentScoresById(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(analyticsSlice.actions.setDepartmentScoresById(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Survey Analytics
export const surveyAnalyticsAction = createAsyncThunk("analytics/get_surveyAnalytics", async ({ id, month, category, department }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.ANALYTICS_OVERVIEW + "/" + (id ? `${id + (month ? "?month=" + month : "") +
            (category ? !month ? "?categoryId=" + category : "&categoryId=" + category : "") +
            (department ? !month && !category ? "?departmentId=" + department : "&departmentId=" + department : "")}` : ""));
        if (res.success && res.data) {
            dispatch(analyticsSlice.actions.setSurveyAnayltics(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(analyticsSlice.actions.setSurveyAnayltics(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setOverview: (state, action) => {
            state.overview = action.payload;
        },
        setCategoryScores: (state, action) => {
            state.categoryScores = action.payload;
        },
        setCategoryScoresById: (state, action) => {
            state.categoryScoresByDept = action.payload;
        },
        setSurveyAnayltics: (state, action) => {
            state.surveyAnalytic = action.payload;
        },
        setDepartmentScores: (state, action) => {
            state.departmentScores = action.payload;
        },
        setDepartmentScoresById: (state, action) => {
            state.departmentScoresByCat = action.payload;
        },
        setFocusArea: (state, action) => {
            state.focusArea = action.payload;
        },
        setProgress: (state, action) => {
            state.progress = action.payload;
        },
    },
});

export const { setOverview, setCategoryScores, setDepartmentScores, setFocusArea } = analyticsSlice.actions;

export default analyticsSlice.reducer;
