import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Tabs from "../../components/Tabs";
import ServiceTable from "./ServicesTable";
import RequestService from "./RequestService";
import CoachRequestsTable from "./CoachRequestsTable";

export default function Services() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tabs = [
        {
            name: "Services",
            path: "/services",
            element: RequestService,
            exact: true,
        },
        {
            name: "Service requests",
            path: "request-list",
            element: ServiceTable,
            exact: true,
        },
        {
            name: "Coach requests",
            path: "coach-requests",
            element: CoachRequestsTable,
            exact: true,
        }
    ];

    return (
        <>
            <DashboardLayout heading="Service Request">
                <Tabs tabs={tabs} status tabPedding="px-7" />
            </DashboardLayout>
        </>
    );
}
