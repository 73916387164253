import React, { useEffect, useState } from "react";
import EmployeeDashboardLayout from "../../../layout/employee";
import { useNavigate } from "react-router-dom";
import LineChart from "../../../components/Charts/LineChart";
import { DashobardOverviewCard, MainCard } from "../../../components/cards/card";
import CustomTable from "../../../components/table/table";
import PrimaryButton, { PrimaryButtonOutlined } from "../../../components/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import { CustomDropDown, CustomTextArea } from "../../../components/input/input";
import { Dialog } from "primereact/dialog";
import { empCategoryScoresAction, empFocusAreaAction, getSurveysAnalyticsAction } from "../../../redux1/Slices/employeeSurveySlice";
import { months } from "../../../utils/constants";
import { showFormErrors } from "../../../utils/commonFunctions";
import { addCoachRequestAction } from "../../../redux1/Slices/coachRequestsSlice";

export default function EmployeeDashboard() {
    const [progressMenu, setProgressMenu] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [progressScores, setProgressScores] = useState([]);
    const [requestCoach, setRequestCoach] = useState("");

    let focusArea = useSelector((state) => state.employeeSurvey.focusArea);
    const surveyAnalytics = useSelector((state) => state.employeeSurvey.surveyAnalytics);
    const progress = useSelector((state) => state.employeeSurvey.categoryScores);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [progressFilterData, setProgressFilterData] = useState({
        startMonth: "",
        endMonth: "",
        year: "",
        categoryId: "",
    });

    const [requestCoachModal, setRequestCoachModal] = useState(false);

    const handleSubmitCoachRequest = () => {
        if (showFormErrors(requestCoach, setRequestCoach)) {
            dispatch(addCoachRequestAction({ payload: { description: requestCoach }, setRequestCoachModal }));
            setRequestCoach("")
        }
    };

    const attentionTableColumns = [
        { field: "departmentName", header: "Area", body: "FOCUS_AREA" },
        { field: "lowScoreSuggestion", header: "Suggestion", body: "PARAGRAPH" },
    ];

    const handleProgressFilter = ({ name, value, e }) => {
        setProgressFilterData({ ...progressFilterData, [name]: value });
        // progressMenu.current.toggle(e);
    };

    const ProgressFilter = ({ display = "hidden" }) => {
        return (
            <div className={`w-15rem border bg-white shadow-1 z-5 right-0 top-100 absolute ${display}`}>
                <div className="border-bottom-1 border-gray-300 p-2">
                    <h3 className="m-0">Year</h3>
                    {/* <h4 className="m-0 font-medium">Select Year</h4> */}
                    <CustomDropDown
                        extraClassName="mb-2"
                        placeholder="Select Year"
                        value={progressFilterData.year}
                        name="year"
                        options={[
                            { name: 2023, value: 2023 },
                            { name: 2024, value: 2024 },
                        ]}
                        onChange={handleProgressFilter}
                    />
                </div>
                <div className="flex flex-wrap justify-content-between gap-2 border-bottom-1 border-gray-300 p-2">
                    <h3 className="m-0 w-full">Monthly</h3>
                    <div style={{ width: "48%" }}>
                        <h4 className="m-0 font-medium">Start</h4>
                        <CustomDropDown
                            extraClassName="mb-2"
                            placeholder="Select Month"
                            value={progressFilterData.startMonth}
                            name="startMonth"
                            options={months?.map((month) => ({ name: month?.fullName, value: month?.fullName }))}
                            onChange={handleProgressFilter}
                        />
                    </div>
                    <div style={{ width: "48%" }}>
                        <h4 className="m-0 font-medium">End</h4>
                        <CustomDropDown
                            extraClassName="mb-2"
                            placeholder="Select Month"
                            value={progressFilterData.endMonth}
                            name="endMonth"
                            options={months?.map((month, index) => {
                                let startMonth = progressFilterData?.start !== "" ? months.indexOf(progressFilterData?.start) : 0;
                                return { name: month?.fullName, value: month?.fullName, disabled: index < startMonth ? true : false };
                            })}
                            onChange={handleProgressFilter}
                        />
                    </div>
                </div>
                <div className="p-2">
                    <h3 className="m-0">Category</h3>
                    {/* <h4 className="m-0 font-medium">Select Category</h4> */}
                    <CustomDropDown
                        extraClassName="mb-2"
                        placeholder="Select Category"
                        value={progressFilterData.categoryId}
                        name="categoryId"
                        options={availableCategories.map((category, index) => {
                            return { name: category?.categoryName, value: category?._id };
                        })}
                        onChange={handleProgressFilter}
                    />
                </div>
                <div className="flex align-items-center justify-content-between p-2">
                    <PrimaryButton label="Apply" onClick={applyProgressFilter} />
                    <PrimaryButton label="Clear" secondary onClick={clearProgressFilter} />
                </div>
            </div>
        );
    };

    const applyProgressFilter = () => {
        dispatch(empCategoryScoresAction({ ...progressFilterData }));
        setProgressMenu(false);
    };

    const clearProgressFilter = () => {
        setProgressFilterData({
            startMonth: "",
            endMonth: "",
            year: "",
            categoryId: "",
        })
        dispatch(empCategoryScoresAction({}));
        setProgressMenu(false);
    };

    useEffect(() => {
        dispatch(getSurveysAnalyticsAction());
        dispatch(empCategoryScoresAction({}));
        dispatch(empFocusAreaAction());
    }, [dispatch]);

    useEffect(() => {
        if (progress) formatProgressScores(progress);
    }, [progress]);


    const mergeArrays = (data) => {
        let mergedArray = [];
        for (const key in data) {
            if (Array.isArray(data[key])) {
                mergedArray = mergedArray.concat(data[key]);
            }
        }
        return mergedArray;
    }

    const formatProgressScores = (progressData) => {
        let categories = [];
        let availableCategoryList = [];
        let scores = [];
        Object.keys(progressData).forEach((item) => {
            progressData[item].forEach((item) => {
                if (!categories.includes(item.categoryName)) {
                    categories.push(item.categoryName);
                    availableCategoryList.push(item);
                    let data = {
                        category: item.categoryName,
                        color: item.color,
                        scores: months?.map((month) => ({ month: month?.fullName, count: null })),
                    };
                    scores.push(data);
                }
            });
        });

        let updatedScores = scores.map((item) => {
            let scores = item.scores.map((data) => {
                // console.log("item", item);
                let newData = data;
                if (progressData[data.month]?.length) {
                    let category = progressData[data.month].filter((e) => e.categoryName === item.category);

                    newData = category.length
                        ? {
                            month: category[0].month,
                            count: category[0].overAllScore,
                        }
                        : data;
                }
                // console.log("newData", newData);
                return newData;
            });

            return { ...item, scores };
        });
        setProgressScores(updatedScores);
        setAvailableCategories(availableCategoryList);
    };

    return (
        <EmployeeDashboardLayout heading="Dashboard">
            <div className="bg-white grid my-3 ">
                <div className="md:col-7 h-full">
                    <div className="flex flex-wrap gap-3 mb-3">
                        <DashobardOverviewCard title="No. of Surveys" value={surveyAnalytics.totalSurveysFilled} user={true} fromLastMonth={0} />
                        <DashobardOverviewCard title="Coaching Sessions" value="0" user={true} fromLastMonth={0} />
                        <DashobardOverviewCard title="Overall score" value={surveyAnalytics.overAllHQScore} fromLastMonth={0} />
                    </div>
                    <div className="p-3 border h-full">
                        <div className="flex justify-content-between relative">
                            <ProgressFilter display={progressMenu ? "block" : "hidden"} />

                            <h2 className="m-0 text-xl">Progress</h2>
                            <PrimaryButtonOutlined label="Filter" icon="pi pi-filter" iconPos="right" onClick={(event) => setProgressMenu((prev) => !prev)} />
                        </div>
                        <LineChart
                            data={progressScores?.map((item) => ({ name: item.category, data: item.scores.map((month) => month.count), color: item.color }))}
                            colors={progressScores?.map((item) => item.color)}
                        />
                    </div>
                    {/* </div> */}
                </div>
                <div className="md:col-5">
                    <div className="border border-400 p-3">
                        <div className="flex mb-3 justify-content-between align-items-center">
                            <h2 className="mt-0 mb-3 text-xl pl-2">Recommendations</h2>
                            <PrimaryButton label="Request a Coach" onClick={() => setRequestCoachModal(true)} />
                        </div>
                        <div className="border border-400 p-3 ">
                            <CustomTable extraClassName="focus-table employee-focus-table" columns={attentionTableColumns} data={mergeArrays(focusArea)} showLines={false} />
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                header="Request a Coach"
                visible={requestCoachModal}
                className="overflow-hidden"
                style={{ width: "602px", borderRadius: "10px" }}
                onHide={() => {
                    if (!requestCoachModal) return;
                    setRequestCoachModal(false);
                }}
            >
                <CustomTextArea name="note" value={requestCoach} label="Write a Note" placeholder="Type here.." onChange={(e) => setRequestCoach(e.value)} />
                <div className="flex justify-content-end">
                    <PrimaryButton label="Request" extraClass={"w-4"} onClick={handleSubmitCoachRequest} />
                </div>
            </Dialog>
        </EmployeeDashboardLayout>
    );
}
