import React from "react";
// import { CurrentPlansCard, PlansCard } from "../../components/cards/card";
import { ReactComponent as CommingSoon } from "../../assets/svg/coming_soon.svg";
export default function Plans() {
    const data = {
        isPurchased: true,
        Heading: "Plan Benefits",
        CurrentPlan: [("1 admin user", "2 surveys ", "3 categories", "30 employee registrations")],
        Heading2: "OverView",
        overViewData: [{ Heading: "Admin users" }, { subHeading: "Full access" }, { noofAccess: "1" }, { uses: "1/1" }],
    };
    const yearlyData = {
        isPurchased: false,
        price: 59,
        features: ["Number of users", "Total credit limit", "Individual user analytics", "Behaviour analytics", "Daily reports", "Weekly reports", "Monthly reports"],
    };
    return (
        // <div>
        //     <h2>Current Plan</h2>
        //     <CurrentPlansCard type="month" data={data} />
        //     <h2>Available Plan</h2>
        //     <PlansCard type="year" data={yearlyData} />
        // </div>
        <div className="flex flex-column align-items-center pt-7">
            <CommingSoon />
            <h3>Feature Coming Soon. Stay tuned for updates. </h3>
        </div>
    );
}
