import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { MainCard } from "../../components/cards/card";
import EmployeeForm from "../Common/EmployeeForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { employeeByIdAction, manageEmployeeAction, uploadEmployeeAction, validateEmployeeAction } from "../../redux1/Slices/employeesSlice";
import { formatDate, showFormErrors } from "../../utils/commonFunctions";

export default function ManageEmployee() {
    const { id } = useParams();
    const employee = useSelector((state) => state.employees.employee);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({ visible: false });
    const [employeesFile, setEmployeesFile] = useState("");
    const [showErrorPage, setShowErrorPage] = useState(false);
    const [employeesData, setEmployeesData] = useState([]);
    const [mappedCsvHeaders, setMappedCsvHeaders] = useState({});
    const [validRowEmails, setValidRowEmails] = useState([]);
    const employeesErrorFile = useSelector((state) => state.employees.errorData);

    const [data, setData] = useState({
        fullName: "",
        dob: "",
        gender: "",
        email: "",
        department: "",
        isLicensed: false,
    });

    useEffect(() => {
        if (id) {
            dispatch(employeeByIdAction({ id }));
        }
    }, [id]);
    useEffect(() => {
        if (employee._id) {
            setData({
                id: employee?._id,
                fullName: employee?.fullName,
                dob: formatDate(employee?.dob, "UTC", "ISO"),
                gender: employee?.gender,
                email: employee?.email,
                department: employee?.department?._id,
                isLicensed: employee?.isLicensed,
                isUpdate: true,
            });
        } else {
            setData({ fullName: "", dob: "", gender: "", email: "", department: "", isLicensed: false });
        }
    }, [employee]);

    useEffect(() => {
        if (!id) {
            setData({
                fullName: "",
                dob: "",
                gender: "",
                email: "",
                department: "",
                isLicensed: "",
            });
        }
    }, [id]);

    const handleAddEmployee = () => {
        if (showFormErrors(data, setData)) {
            setDialog({
                visible: true,
                title: " Are you sure you want to save the details?",
                cancel: true,
                saveLabel: "Save",
                buttonPosition: "center",
                saveFunction: () => dispatch(manageEmployeeAction({ data, id: data?.id, setDialog, next: () => navigate(-1) })),
            });
        }
    };

    const validateEmployeesCSV = () => {
        const mappedEmpData = [];
        employeesData.filter(empData => empData).map(item => {
            if (item && Object.keys(item).length) {
                const newObj = {};
                Object.keys(item).forEach(key => {
                    const newKey = mappedCsvHeaders[key] || key; // Use new key name if exists in keyMap, otherwise use old key name
                    newObj[newKey] = item[key];
                });
                mappedEmpData.push(newObj);
            }
        });

        setEmployeesData([...mappedEmpData]);

        dispatch(validateEmployeeAction({ data: { jsonData: mappedEmpData }, next: () => null }));
    };

    const uploadEmployees = () => {
        const validEmpEmails = employeesErrorFile.filter(item => item.isValidRow).map(item => item.email);
        const dataToUplaod = employeesData.filter(item => validEmpEmails.some(email => item.email === email));
        setDialog({
            visible: true,
            title: " Are you sure you want to upload selected file?",
            cancel: true,
            saveLabel: "Upload",
            buttonPosition: "center",
            saveFunction: () => dispatch(uploadEmployeeAction({ data: { jsonData: dataToUplaod }, setDialog, setEmployeesData, next: () => navigate(-1) })),
        });
    };

    return (
        <DashboardLayout heading="Add Employees">
            <MainCard padding="p-0" border="border-none" background="bg-transparent" extraClass="my-0" dialog={dialog} setDialog={setDialog}>
                <EmployeeForm
                    data={data}
                    setData={setData}
                    handleAddEmployee={handleAddEmployee}
                    validateEmployeesCSV={validateEmployeesCSV}
                    mappedCsvHeaders={mappedCsvHeaders}
                    setMappedCsvHeaders={setMappedCsvHeaders}
                    setEmployeesData={setEmployeesData}
                    uploadEmployees={uploadEmployees}
                    employeesFile={employeesFile}
                    setEmployeesFile={setEmployeesFile}
                    hideFileUpload={id ? true : false}
                    dialog={dialog}
                    setDialog={setDialog}
                    showErrorPage={showErrorPage}
                    setShowErrorPage={setShowErrorPage}
                    setValidRowEmails={setValidRowEmails}
                />
            </MainCard>
        </DashboardLayout>
    );
}
