import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { BreadCrumb } from "primereact/breadcrumb";
import { categoryAction } from "../../redux1/Slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { departmentAction } from "../../redux1/Slices/departmentSlice";
import ShowCategories from "../Common/ShowCategories";
import { MainCard } from "../../components/cards/card";
import SurveyForm from "../Common/SurveyForm";
import { formatDate, shareFunction, showFormErrors } from "../../utils/commonFunctions";
import { addSurveyAction, surveyByIdAction, upadteSurveyAction } from "../../redux1/Slices/surveySlice";
import { showToast } from "../../redux1/Slices/toastSlice";

export const CreateSurvey = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const categories = useSelector((state) => state.category.data);
    const departments = useSelector((state) => state.department.data);
    const survey = useSelector((state) => state.survey.surveyDetail);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showSurveyForm, setSurveyForm] = useState(false);
    const [dialog, setDialog] = useState({ visible: false });
    const [isEnableShare, setIsEnaleShare] = useState(false);

    const breadCrumbItems = [{ label: id ? "Edit Survey" : "Create Survey" }];
    const breadCrumbItemsEdit = [{ label: "Edit Survey" }, { label: survey?.title }];
    const [data, setData] = useState({
        title: "",
        description: "",
        departments: [],
        categories: [],
        startDate: "",
        expiringOn: "",
        repetition: "",
        applicableFor: [],
    });

    const handleCategorySelection = (category) => {
        if (departments?.length === 0) {
            setDialog({
                visible: true,
                title: "Please start by adding departments to create your initial survey.",
                cancelIcon: true,
                saveLabel: "Add Department",
                buttonCol: 8,
                buttonPosition: "center",
                saveFunction: () => navigate("/settings/manage-departments/add"),
            });
        } else {
            if (selectedCategories.includes(category)) {
                const updatedCategories = selectedCategories.filter((item) => item !== category);
                setSelectedCategories(updatedCategories);
            } else {
                setSelectedCategories([...selectedCategories, category]);
            }
        }
    };

    const enableShare = (surveyId) => {
        setIsEnaleShare({ enable: true, surveyId: surveyId ? surveyId : id });
        setDialog({ visible: false });
    };

    const handleContinue = () => {
        if (selectedCategories.length) {
            setSurveyForm(true);
        } else {
            dispatch(showToast({ severity: "error", summary: "Please Select atleast one category", detail: "" }));
        }
    };

    const handleAddSurvey = () => {
        if (showFormErrors(data, setData) && data?.applicableFor?.length) {
            setDialog({
                visible: true,
                title: "Do you want to save Survey?",
                cancel: true,
                type: "SAVE",
                saveLabel: "Save",
                saveFunction: () => dispatch(addSurveyAction({ data, next: enableShare, setDialog })),
            });
        }
    };

    const handleUpdateSurvey = () => {
        if (showFormErrors(data, setData) && data?.applicableFor?.length) {
            setDialog({
                visible: true,
                title: "Do you want to update Survey?",
                cancel: true,
                type: "SAVE",
                saveLabel: "Save",
                saveFunction: () => dispatch(upadteSurveyAction({ data, surveyId: id, next: enableShare })),
            });
        }
    };

    useEffect(() => {
        dispatch(categoryAction({ accessToken: localStorage.getItem("authToken") }));
        dispatch(departmentAction({ accessToken: localStorage.getItem("authToken") }));
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(surveyByIdAction({ id }));
        }
    }, [id]);

    useEffect(() => {
        if (survey.title && id) {
            setData({
                title: survey?.title,
                description: survey?.description,
                departments: survey?.departments,
                repetition: survey?.repetition,
                categories: survey?.categories ? [...survey.categories.map((category) => category._id)] : [],
                startDate: formatDate(survey?.startDate, "UTC", "ISO"),
                expiringOn: formatDate(survey?.expiringOn, "UTC", "ISO"),
                applicableFor: survey?.applicableFor ? [...survey.applicableFor.map((dept) => dept._id)] : [],
            });
            setSurveyForm(true);
            setSelectedCategories(survey?.categories ? [...survey.categories.map((category) => category._id)] : []);
        }
    }, [survey, id]);

    return (
        <DashboardLayout heading={id ? "Edit Survey" : "Create Survey"}>
            <BreadCrumb
                model={id ? breadCrumbItemsEdit : breadCrumbItems}
                home={{
                    label: "Surveys",
                    template: () => (
                        <span className="cursor-pointer" onClick={() => navigate(-1)}>
                            Surveys
                        </span>
                    ),
                }}
                pt={{
                    root: { className: "bg-transparent border-none p-0 font-semibold " },
                }}
            />
            {/* <p>
                <span className="text-900 font-semibold">Note: </span>This survey will be repeated after 1 month.
            </p> */}
            <MainCard
                title={`${showSurveyForm ? "Selected" : "Select"} Categories`}
                subtitle={!showSurveyForm ? "(Min. one Category is required)" : ""}
                dialog={dialog}
                setDialog={setDialog}
                shareFunction={isEnableShare?.enable && showSurveyForm ? () => navigate("/surveys/send-survey/" + isEnableShare?.surveyId) : () => null}
                disableshare={isEnableShare?.enable ? false : true}
                saveLabel={isEnableShare?.enable ? "Share" : showSurveyForm ? "Save" : "Continue"}
                saveFunction={isEnableShare?.enable && showSurveyForm ? null : showSurveyForm && id ? () => handleUpdateSurvey() : showSurveyForm ? () => handleAddSurvey() : () => handleContinue()}
                cancelFuncation={() => navigate("/surveys/")}
                cancelLable={isEnableShare?.enable ? "Go to Surveys" : ""}
                background={"bg-transparent"}
                border="none"
                padding="p-0"
            >
                <ShowCategories
                    showSurveyForm={showSurveyForm}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    handleCategorySelection={handleCategorySelection}
                    hideAddMore={isEnableShare?.enable || survey.status === "ACTIVE" ? true : false}
                />
                {showSurveyForm ? (
                    <SurveyForm
                        selectedCategories={selectedCategories}
                        enableShare={isEnableShare?.enable}
                        departments={departments}
                        data={data}
                        setData={setData}
                        disable={isEnableShare?.enable}
                        disableActiveSurvey={survey?.status === "ACTIVE" ? true : false}
                        survey={survey.title ? survey : {}}
                        surveyId={id}
                    />
                ) : null}
            </MainCard>
        </DashboardLayout>
    );
};
