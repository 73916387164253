import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { CustomSearch } from "../../components/input/input";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useDispatch, useSelector } from "react-redux";
import { helpAction } from "../../redux1/Slices/helpSlice";
import { useDebounce } from "primereact/hooks";

export default function Help() {
    const dispatch = useDispatch();
    const FAQs = useSelector((state) => state.help.faq);
    const [searchFor, searchString, setSearchFor] = useDebounce("", 1000);
    const showFAQ = () => {
        return FAQs?.map((tab, i) => {
            return (
                <AccordionTab key={i} header={tab?.heading}>
                    <p className="m-0">{tab?.description}</p>
                </AccordionTab>
            );
        });
    };

    useEffect(() => {
        dispatch(helpAction({}));
    }, []);

    useEffect(() => {
        dispatch(helpAction({ searchString: searchString }));
    }, [searchString]);

    return (
        <DashboardLayout heading="Help">
            <div className="w-full md:w-10">
                <h2>How can we help you?</h2>
                <CustomSearch value={searchFor} iconPosition="p-input-icon-right" onChange={setSearchFor} />
                <h2>FAQ</h2>
                <div className="faq-section">
                    <Accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                        {showFAQ()}
                    </Accordion>
                </div>
            </div>
        </DashboardLayout>
    );
}
