export const noOfEmployee = [
    { name: "0-25", value: "0-25" },
    { name: "26-50", value: "26-50" },
    { name: "51-100", value: "51-100" },
    { name: "101-200", value: "101-200" },
    { name: "201-300", value: "201-300" },
    { name: "300+", value: "300+" },
];
export const passwordRequirements = [
    { regex: /[a-z]/, message: "At least one lowercase letter" },
    { regex: /[A-Z]/, message: "At least one uppercase letter" },
    { regex: /\d/, message: "At least one number" },
    { regex: /.{8,}/, message: "Minimum 8 characters" },
];

// export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const months = [
    { shortName: "Jan", fullName: "January" },
    { shortName: "Feb", fullName: "February" },
    { shortName: "Mar", fullName: "March" },
    { shortName: "Apr", fullName: "April" },
    { shortName: "May", fullName: "May" },
    { shortName: "Jun", fullName: "June" },
    { shortName: "Jul", fullName: "July" },
    { shortName: "Aug", fullName: "August" },
    { shortName: "Sep", fullName: "September" },
    { shortName: "Oct", fullName: "October" },
    { shortName: "Nov", fullName: "November" },
    { shortName: "Dec", fullName: "December" },];

export const employeeTableColumns = [
    { name: "Full Name", value: "fullName" },
    { name: "Date of Birth", value: "dob" },
    { name: "Gender", value: "gender" },
    { name: "E-mail", value: "email" },
    { name: "Department", value: "department" },
    { name: "License", value: "isLicensed" }
];
