import React, { useEffect } from "react";
import EmployeeDashboardLayout from "../../../layout/employee";
import FilledSurveyTable from "./SurveyTable";
import { Card } from "primereact/card";
import SurveyIcon from "../../../assets/icons/survey-icon.png";
import PrimaryButton from "../../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOpenSurveysAction, getFilledSurveysAction } from "../../../redux1/Slices/employeeSurveySlice";
import moment from "moment";

export default function EmployeeSurveys() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const openSurveys = useSelector((state) => state.employeeSurvey.openSurveyList);
    const filledSurveys = useSelector((state) => state.employeeSurvey.filledSurveyList);

    useEffect(() => {
        dispatch(getOpenSurveysAction());
        dispatch(getFilledSurveysAction());
    }, []);

    return (
        <EmployeeDashboardLayout heading="Surveys">
            <h3 class="m-0 heading-color font-semibold text-2xl mb-3 capitalize">Open Surveys</h3>
            <div className="grid mb-3">
                {openSurveys.map((survey) => (
                    <div className="col-6">
                        <Card className="shadow-1 border-round-xl overflow-hidden employee-survey" >
                            <div className="flex pb-3 align-items-center justify-content-between gap-3 border-bottom-1 border-300">
                                <div className="flex align-items-center gap-3">
                                    <img src={SurveyIcon} alt="" height={46} width={46} />
                                    <p className="text-lg font-semibold heading-color">{survey.title}</p>
                                </div>
                                <PrimaryButton className={"border-round-2xl"} label="Fill Survey" onClick={() => navigate('/survey/' + survey._id, { state: { privateSurvey: true } })} />
                            </div>
                            <p className="mb-0">Expiring On : <span className="text-skyblue">{moment(survey?.expiringOn).format("DD-MM-YYYY")}</span></p>
                        </Card>
                    </div>
                ))}
            </div>
            <FilledSurveyTable data={filledSurveys} />
        </EmployeeDashboardLayout>
    );
}
