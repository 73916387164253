import React from "react";
import ReactApexChart from "react-apexcharts";
import CustomSkelton from "../cards/CustomSkelton";

export default function DonutChat({ data, key, isLoading }) {
    const options = {
        chart: {
            height: "100%",
            type: "radialBar",
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                hollow: {
                    size: "50%",
                    background: "transparent",
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: true,
                        formatter: function (val) {
                            return val + "/100";
                        },
                        fontSize: "20px",
                        fontFamily: undefined,
                        fontWeight: 400,
                        color: data?.color,
                        offsetY: 10,
                    },
                },
            },
        },
        colors: [data?.color],

        tooltip: {
            enabled: false,
        },
        stroke: {
            lineCap: "round",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        show: false,
                    },
                },
            },
        ],
        noData: {
            text: "Data not available",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: "14px",
                fontFamily: undefined,
            },
        },
    };
    return (
        <div key={key} style={{ width: "225px" }} className="border bg-white flex flex-column align-items-center">
            {isLoading ? (
                <CustomSkelton width="100%" height="200px" />
            ) : (
                <>
                    <ReactApexChart options={options} series={[data?.overAllScore]} type="radialBar" height={200} />
                    <p className="text-center mt-0 text-dark">{data?.name}</p>
                </>
            )}
        </div>
    );
}
