import React from "react";
import SidebarIcons from "./SidebarIcon";
import Sidebar from "./sidebar";
import { Topbar } from "./topbar";
import LayoutData from "./LayoutData";

export default function EmployeeDashboardLayout({ children, heading, label, onClick }) {
    const { isFullSidebar, setIsFullSidebar } = LayoutData();
    return (
        <div className="w-screen">
            <div className="flex justify-content-end m-0 relative top-0 overflow-hidden">
                <div className="sidebar-container p-0 relative" style={{ width: isFullSidebar ? "300px" : "102px", height: "100vh" }}>
                    {isFullSidebar ? (
                        <Sidebar
                            style={{
                                width: "300px",
                                height: "100vh",
                            }}
                        />
                    ) : (
                        <SidebarIcons
                            style={{
                                width: "102px",
                                height: "100vh",
                            }}
                        />
                    )}
                </div>

                <div
                    className="main-content p-0  bg-dashboard"
                    style={{
                        width: isFullSidebar ? "calc(100% - 300px)" : "calc(100% - 102px)",
                        height: "100vh",
                    }}
                >
                    <Topbar
                        isFullSidebar={isFullSidebar}
                        toggleSidebar={setIsFullSidebar}
                        icon={isFullSidebar ? "pi pi-chevron-left" : "pi pi-chevron-right"}
                        heading={heading}
                        label={label}
                        onClick={onClick}
                    />
                    <div className="p-4 px-5 overflow-auto dashboard-detail-container">{children}</div>
                </div>
            </div>
        </div>
    );
}
