import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layout";
import { MainCard } from "../../../components/cards/card";
import { useNavigate, useParams } from "react-router-dom";
import { CustomInput, CustomTextArea } from "../../../components/input/input";
import { useDispatch } from "react-redux";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { addDepartmentAction, departmentByIdAction, updateDepartmentAction } from "../../../redux1/Slices/departmentSlice";

export default function DepartmentForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        name: "",
        description: "",
    });

    const [dialog, setDialog] = useState({ visible: false });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const handleSaveDepartment = () => {
        if (showFormErrors(data, setData)) {
            setDialog({
                visible: true,
                title: "Are you sure you want to Save ?",
                type: "SAVE",
                cancel: true,
                saveFunction: () => {
                    id? dispatch(updateDepartmentAction({ data, getDepartment: () => navigate(-1) })) :
                    dispatch(addDepartmentAction({ data, getDepartment: () => navigate(-1) }));
                    setData({
                        name: "",
                        description: "",
                    });
                },
            });
        }
    };
    const getDepartment = (id) => {
        dispatch(departmentByIdAction({ data: id, accessToken: localStorage.getItem("authToken") })).then((res) => {
            setData({
                id: id,
                name: res?.payload?.name,
                description: res?.payload?.description,
            });
        });
    };

    useEffect(() => {
        if (id) getDepartment(id);
    }, [id]);

    return (
        <DashboardLayout heading="Settings">
            <MainCard title={id ? "Edit Department" : "Add Department"} dialog={dialog} setDialog={setDialog} saveFunction={() => handleSaveDepartment()} cancelFuncation={() => navigate(-1)}>
                <CustomInput col={12} label="Department Name" name="name" data={data} value={data?.name} required onChange={handleChange} placeholder="Enter" />
                <CustomTextArea label="Description" name="description" data={data} value={data?.description} onChange={handleChange} placeholder="Enter department description" maxLength={150} />
            </MainCard>
        </DashboardLayout>
    );
}
