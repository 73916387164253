import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";
import { formatDate } from "../../utils/commonFunctions";

const initialState = {
    data: {},
    employeeSurveyDetail: {},
    openSurveyList: [],
    employeeSurveyList: [],
    filledSurveyList: [],
    surveyAnalytics: {},
    categoryScores: [],
    focusArea: {}
};


// Get Surveys with Filter
export const getOpenSurveysAction = createAsyncThunk("employee/open_surveys", async (_, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api("get", Constants.END_POINT.GET_OPEN_SURVEYS);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(employeeSurveySlice.actions.setEmpOpenSurveyList(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(employeeSurveySlice.actions.setEmpOpenSurveyList(res.data));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const getFilledSurveysAction = createAsyncThunk("employee/filled_surveys", async (_, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api("get", Constants.END_POINT.GET_FILLED_SURVEYS);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(employeeSurveySlice.actions.setEmpFilledSurveyList(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(employeeSurveySlice.actions.setEmpFilledSurveyList(res.data));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const getSurveysAnalyticsAction = createAsyncThunk("employee/survey_analytics", async (_, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api("get", Constants.END_POINT.GET_SURVEY_ANALYTICS);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(employeeSurveySlice.actions.setEmpSurveyAnalytics(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(employeeSurveySlice.actions.setEmpSurveyAnalytics(res.data));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const empCategoryScoresAction = createAsyncThunk("analytics/get_categoryScores", async ({ id, age, gender, year, startMonth, endMonth, categoryId }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api(
            "get",
            Constants.END_POINT.EMP_CATEGORY_SCORES + "?" +
            (age ? "age=" + age : "") +
            (gender ? "&gender=" + gender : "") +
            (year ? "&year=" + year : "") +
            (startMonth ? "&startMonth=" + startMonth : "") +
            (endMonth ? "&endMonth=" + endMonth : "") +
            (categoryId ? "&categoryId=" + categoryId : "")
        );
        if (res.success && res.data) {
            dispatch(employeeSurveySlice.actions.setEmpSurveyCategoryScores(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(employeeSurveySlice.actions.setEmpSurveyCategoryScores(res.data));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Focus Area
export const empFocusAreaAction = createAsyncThunk("employees/get_focusArea", async (_, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.EMP_FOCUS_AREA);
        if (res.success && res.data) {
            dispatch(employeeSurveySlice.actions.setFocusArea(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(employeeSurveySlice.actions.setFocusArea(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

const employeeSurveySlice = createSlice({
    name: "employeeSurvey",
    initialState,
    reducers: {
        setEmpSurveyDetails: (state, action) => {
            state.surveyDetail = action.payload;
        },
        setEmpSurveyList: (state, action) => {
            state.surveyList = action.payload;
        },
        setEmpOpenSurveyList: (state, action) => {
            state.openSurveyList = action.payload;
        },
        setEmpFilledSurveyList: (state, action) => {
            state.filledSurveyList = action.payload;
        },
        setEmpSurveyAnalytics: (state, action) => {
            state.surveyAnalytics = action.payload;
        },
        setEmpSurveyCategoryScores: (state, action) => {
            state.categoryScores = action.payload;
        },
        setFocusArea: (state, action) => {
            state.focusArea = action.payload;
        },
    },
});

export const { setEmpSurveyDetails } = employeeSurveySlice.actions;

export default employeeSurveySlice.reducer;
