import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    faq: [],
};

export const helpAction = createAsyncThunk("help/get_faq", async ({ searchString = "" }, { dispatch, getState }) => {
    try {
        // dispatch(showLoader());
        const res = await api("get", Constants.END_POINT.HELP + (searchString?.length ? `?searchString=${searchString}` : ""));
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(helpSlice.actions.getFAQs(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(helpSlice.actions.getFAQs(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

const helpSlice = createSlice({
    name: "help",
    initialState,
    reducers: {
        getFAQs: (state, action) => {
            state.faq = action.payload;
        },
    },
});

export const { getFAQs } = helpSlice.actions;

export default helpSlice.reducer;
