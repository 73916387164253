import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutData from "./LayoutData";
import LogoSmall from "../../assets/images/Logo-icon-only.png";
import { Avatar } from "primereact/avatar";
import ProfileImg from "../../assets/images/profile.png";
import { imagePath } from "../../utils/commonFunctions";
import { useSelector } from "react-redux";
import { Menu } from "primereact/menu";
import ConfirmDailog from "../../components/overlay/Dialog";

export default function SidebarIcons({ style, extraClass }) {
    const location = useLocation();
    const user = useSelector((state) => state.user.data);
    const navigate = useNavigate();
    const logoutBox = useRef(null);

    const [dialog, setDialog] = useState({
        visible: false,
    });

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };
    const activeLink = location.pathname;
    const { menuItems } = LayoutData();
    return (
        <div className={`flex sidebar-nav bg-primary flex-column justify-content-between h-full fixed z-5  overflow-y-scroll bg-lightest pt-3 left-0 text-center ${extraClass}`} style={style}>
            <ConfirmDailog dialog={dialog} setDialog={setDialog} />

            <div>
                <div className=" p-4 text-left">
                    <img src={LogoSmall} style={{ width: "49px", height: "49px" }} alt="" className="mb-3" />
                </div>
                <ul className=" m-0 pl-4 list-none p-0">
                    {menuItems.map((item, key) => (
                        <li key={key}>
                            <div className="flex justify-content-between align-items-center">
                                <Link
                                    to={item.link}
                                    className={`p-3 flex align-items-center font-medium w-full no-underline side_menuitem text-md ${activeLink.includes(item.link) ? " active " : " text-gray"}`}
                                >
                                    <img src={item.icon} alt="" width={item.iconWidth} height={item.iconHeight} />
                                </Link>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <ul className="my-8 m-0 px-4 list-none">
                <li className="flex justify-content-between align-items-center ">
                    <div className="flex align-items-center" onClick={(event) => logoutBox.current.toggle(event)}>
                        {user?.image ? (
                            <img src={imagePath(user?.image)} className="w-3rem h-3rem border-circle" alt="Profile " />
                        ) : (
                            <Avatar icon="pi pi-user" className="mt-2" size="large" shape="circle" />
                        )}
                    </div>
                </li>
            </ul>
            <Menu
                model={[
                    {
                        label: "Logout",
                        icon: "pi pi-sign-out",
                        command: () => {
                            setDialog({
                                visible: true,
                                title: "Are you sure you want to Logout?",
                                type: "LOGOUT",
                                cancel: true,
                                saveFunction: logout,
                            });
                        },
                    },
                ]}
                popup
                ref={logoutBox}
            />
        </div>
    );
}
