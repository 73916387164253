import React, { useEffect, useState } from "react";
import { CustomInput, CustomTextArea } from "../../components/input/input";
import PrimaryButton, { ButtonPosition } from "../../components/buttons/button";
import CustomTable from "../../components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { addDepartmentAction, departmentAction, updateDepartmentAction } from "../../redux1/Slices/departmentSlice";
import { MainCard } from "../../components/cards/card";

export default function AddDepartment({ setActiveStep }) {
    const dispatch = useDispatch();
    const departments = useSelector((state) => state.department.data);
    const [dialog, setDialog] = useState({
        visible: false,
    });

    const [data, setData] = useState({
        name: "",
        description: "",
    });

    const deptCols = [
        { field: "name", header: "Department Name", style: { width: "25%" } },
        { field: "description", header: "Description", style: { width: "60%", maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis" } },
        { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
    ];

    // Button actions and table Actions
    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleAddDepartment = () => {
        if (showFormErrors(data, setData)) {
            dispatch(addDepartmentAction({ data, getDepartment }));
        }
    };

    const handleUpdateDepartment = () => {
        if (showFormErrors(data, setData)) {
            dispatch(updateDepartmentAction({ data, getDepartment }));
        }
        setData({
            name: "",
            description: "",
        });
    };

    const editDepartment = (col) => {
        setData({
            id: col._id,
            name: col.name,
            description: col.description,
            enbleUpdate: true,
        });
    };

    const confirmDelete = (id) => {
        setDialog({
            visible: true,
            title: "Are you sure you want to delete Department?",
            type: "DELETE",
            cancel: true,
            saveFunction: () => deleteDepartment(id),
        });
    };

    const deleteDepartment = (id) => {
        const data = {
            id: id,
            isDeleted: true,
            enableDelete: true,
        };

        dispatch(updateDepartmentAction({ data, getDepartment }));
    };

    // Required Functions
    const getDepartment = () => {
        dispatch(departmentAction({ accessToken: localStorage.getItem("authToken") }));
        setDialog({ visible: false });
        setData({
            name: "",
            description: "",
        });
    };

    useEffect(() => {
        getDepartment();
    }, []);

    return (
        <MainCard
            title="Add Department"
            subtitle="Customize your experience by adding departments to access your personalized dashboard."
            dialog={dialog}
            setDialog={setDialog}
            saveFunction={() => setActiveStep(1)}
            saveLabel="Next"
            saveCol={1}
            disableSave={!departments.length}
        >
            <div>
                <CustomInput label="Department" col={12} placeholder="Ui/UX Designer" name="name" value={data.name} data={data} required onChange={handleChange} />
                <CustomTextArea
                    label="Description"
                    value={data?.description}
                    maxLength={150}
                    col={12}
                    placeholder="Department Description will go here"
                    name="description"
                    data={data}
                    onChange={handleChange}
                />

                <ButtonPosition>
                    <PrimaryButton label={data.enbleUpdate ? "Update" : "Add"} onClick={data.enbleUpdate ? handleUpdateDepartment : handleAddDepartment} />
                </ButtonPosition>
            </div>
            <div>
                <div className="border-1 border-round-xl border-400  my-3 pb-3">
                    <h2 className=" pl-2">Departments</h2>
                    <CustomTable columns={deptCols} edit={editDepartment} trash={confirmDelete} data={departments.length ? departments : []} />
                </div>
            </div>
        </MainCard>
    );
}
