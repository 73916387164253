import React from "react";
import PrimaryButton, { ButtonPosition } from "../../components/buttons/button";
import NotFound from "../../assets/images/not-found.png";
import { useNavigate } from "react-router-dom";

export default function Notfound() {
    const navigate = useNavigate();

    return (
        <div className="flex align-items-center w-screen h-screen justify-content-center text-center">
            <div className="w-screen lg:w-6 m-5">
                <h1>
                    <span className="text-primary">404</span> The page you were looking for is not found!
                </h1>
                <h3>Your Request resulted in an error</h3>
                <img src={NotFound} alt="Not Found" className="w-full lg:w-5 mx-auto my-5" />
                <ButtonPosition position="center">
                    <PrimaryButton  label="Go to Dashboard" onClick={() => navigate("/")} />
                </ButtonPosition>
            </div>
        </div>
    );
}
