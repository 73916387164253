import React, { useState } from "react";
import { Privatelayout } from "../layout/Privatelayout";
import LoginImg from "../../assets/images/loginleft.png";
import { CustomForm, CustomInput } from "../../components/input/input";
import PrimaryButton from "../../components/buttons/button";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { sendEmailApi } from "../../redux1/Slices/employeeLoginSlice";

export const EmployeeForgetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState({ email: "" });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const handleEmail = () => {
        if (showFormErrors(data, setData)) {
            sendEmailApi(data?.email, navigate, dispatch);
        }
    };

    return (
        <>
            <Privatelayout
                childClassName=" flex flex-column justify-content-center align-items-center "
                src={LoginImg}
                heading="Forgot Password"
                subheading="Don't worry! It occurs. Please enter the email address linked with your account."
                subtextsize="text-sm"
            >
                <CustomForm>
                    <CustomInput
                        labelClassName="text-white"
                        label="Email Address "
                        className="bg-white w-full border-round-lg"
                        required
                        col={12}
                        placeholder="Enter Email"
                        name="email"
                        onChange={handleChange}
                    />
                    <PrimaryButton className="bg-white text-primary " extraClass="w-full " label="Send OTP" onClick={handleEmail} />
                    <p className="text-center text-white font-normal col-12 ">
                        Remember Password? &nbsp;
                        <Link to="/login" className="no-underline text-skyblue font-semibold">
                            Login
                        </Link>
                    </p>
                </CustomForm>
            </Privatelayout>
        </>
    );
};
