import React, { useEffect, useRef } from "react";
import Logo from "../assets/images/logo.png";
import PrimaryButton from "../components/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import { myProfileAction } from "../redux1/Slices/loginSlice";
import { imagePath } from "../utils/commonFunctions";
import { Avatar } from "primereact/avatar";
export const Topbar = ({ label, onClick, heading, icon, toggleSidebar, onBoardingScreen }) => {
    const logoutBox = useRef(null);
    const user = useSelector((state) => state.user.data);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(myProfileAction({ accessToken: localStorage.getItem("authToken") }));
    }, []);

    return (
        <>
            <div className={`lg:p-3 lg:px-5 topbar-container relative flex align-items-center ${onBoardingScreen ? "bg-white" : ""}`}>
                {onBoardingScreen ? (
                    <div className="topbar-inner-container flex justify-content-between align-items-center">
                        <div className="logo-container">
                            <img src={Logo} alt="Culture Quest Logo" />
                        </div>
                        <div className="flex align-items-center" onClick={(event) => logoutBox.current.toggle(event)}>
                            <div className="profile-img-container px-2">
                                {user?.image ? (
                                    <img src={imagePath(user?.image)} className="w-3rem h-3rem border-circle" alt="Profile " />
                                ) : (
                                    <Avatar icon="pi pi-user" className="mt-2" size="large" shape="circle" />
                                )}
                            </div>
                            <div className="flex flex-column px-2">
                                <span className="text-sm font-medium capitalize">{user?.firstName ? `${user?.firstName}  ${user?.lastName ? user?.lastName : ""}` : user?.companyName}</span>
                                <span className="text-xs capitalize">{user?.firstName ? user?.companyName : "Company"}</span>
                            </div>
                            <i className="pi pi-chevron-down lg:pl-6 md:pl-6 pl-3" />
                        </div>
                        <Menu
                            model={[
                                {
                                    label: "Logout",
                                    icon: "pi pi-sign-out",
                                    command: () => {
                                        localStorage.clear();
                                        navigate("/");
                                    },
                                },
                            ]}
                            popup
                            ref={logoutBox}
                        />
                    </div>
                ) : (
                    <div className="w-full">
                        <div className="toggle-menu z-5 cursor-pointer" onClick={() => toggleSidebar((prev) => !prev)}>
                            <i className={`text-sm z-5 text-white ${icon}`}></i>
                        </div>
                        <div className="h-full w-full flex align-items-center justify-content-between">
                            <h3 className="m-0 heading-color font-semibold text-2xl capitalize">{heading}</h3>
                            {label ? (
                                <div className="flex align-items-center">
                                    <div className="flex align-items-center ml-5">
                                        <PrimaryButton label={label} onClick={onClick} />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
            <hr className="m-0" style={{ border: "1px solid #EDEDED" }} />
        </>
    );
};
