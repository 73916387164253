import React from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layout";
import SurveyTable from "./SurveyTable";
import Tabs from "../../components/Tabs";

export default function Surveys() {
    const navigate = useNavigate();

    const navigateToCreateSurvey = () => {
        navigate("/surveys/create-survey");
    };

    const tabs = [
        {
            name: "Active",
            path: "active",
            element: SurveyTable,
            exact: true,
        },
        {
            name: "Saved",
            path: "saved",
            element: SurveyTable,
            exact: true,
        },
        {
            name: "Inactive",
            path: "inactive",
            element: SurveyTable,
            exact: true,
        },
    ];

    return (
        <DashboardLayout heading="Surveys" label="Create Survey" onClick={navigateToCreateSurvey}>
            <Tabs tabs={tabs} status tabPedding="px-7" />
        </DashboardLayout>
    );
}
