import { HashRouter, Route, Routes } from "react-router-dom";
import ToastContainer from "./components/toast.jsx";
import Loader from "./utils/Loader.js";
import { RegisterPage } from "./pages/signup";
import "./styles.scss";
import { Login } from "./pages/login/index.jsx";
import { ForgetPassword } from "./pages/forget-password/ForgetPassword.jsx";
import { ResetPassword } from "./pages/forget-password/ResetPassword.jsx";
import { OtpVerification } from "./pages/signup/OtpVerification.jsx";
import { PasswordChanged } from "./pages/forget-password/PasswordChanged.jsx";
import { ProtectedRoute } from "./routes/index.jsx";
import { PrivateRoutes } from "./routes/routes.js";
import ViewSurvey from "./views/Surveys/PublicSurvey/viewSurvey.jsx";
import Success from "./views/Surveys/PublicSurvey/Success.jsx";
import Notfound from "./pages/Error/Notfound.jsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Constants from "./services/constants.js";
import "./i18n";
import SelectRole from "./pages/select-role/SelectRole.jsx";
import { EmployeeLogin } from "./pages/Employee/EmployeeLogin.jsx";
import EmployeeResetPassword from "./pages/Employee/EmployeeResetPassword.jsx";
import { EmployeePasswordChanged } from "./pages/Employee/EmployeePasswordChanged.jsx";
import { EmployeeForgetPassword } from "./pages/Employee/EmployeeForgetPassword.jsx";
import { EmployeeOtpVerification } from "./pages/Employee/EmployeeOtpVerification.jsx";
import EmployeeSurveys from "./views/Employee-panel/Surveys/Surveys.jsx";
import Guidelines from "./views/Employee-panel/Surveys/PublicSurvey/Guidelines.jsx";
import CoachingSession from "./views/Employee-panel/coaching-session/CoachingSession.jsx";
import Notifications from "./views/Employee-panel/Notifications/Notifications.jsx";
import Help from "./views/Employee-panel/Help/Help.jsx";

function App() {
    const googleClientId = Constants.GOOGLE_CLIENT_ID;

    return (
        <GoogleOAuthProvider clientId={googleClientId}>
            <div className="App">
                <HashRouter>
                    {/* <Loader /> */}
                    <Routes>
                        {/* employee */}

                        <Route path="/employee-login" element={<EmployeeLogin />} />
                        <Route path="/employee/reset-password/:encodedEmail" element={<EmployeeResetPassword />} />
                        <Route path="/employee/password-changed" element={<EmployeePasswordChanged />} />
                        <Route path="/employee/forgot-password" element={<EmployeeForgetPassword />} />
                        <Route path="/employee/otp-verification/:encodedEmail" element={<EmployeeOtpVerification />} />
                        <Route path="/employee/surveys" element={<EmployeeSurveys />} />
                        <Route path="/employee/surveys/guidelines" element={<Guidelines />} />
                        <Route path="/employee/coaching-session" element={<CoachingSession />} />
                        <Route path="/employee/notifications" element={<Notifications />} />
                        <Route path="/employee/help" element={<Help />} />

                        {/*  */}
                        <Route path="/" element={<SelectRole />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/forget-password" element={<ForgetPassword />} />
                        <Route path="/otp-verification/:encodedEmail" element={<OtpVerification />} />
                        <Route path="/reset-password/:encodedEmail" element={<ResetPassword />} />
                        <Route path="/password-changed" element={<PasswordChanged />} />
                        <Route path="/survey/:id" element={<ViewSurvey />} />
                        <Route path="/success" element={<Success />} />
                        <Route element={<ProtectedRoute />}>
                            {PrivateRoutes.map((route, key) => (
                                <Route key={key} path={route.path} element={route.element} exact={route.exact}>
                                    {route.items?.map((item, index) => (
                                        <Route key={index} path={item.path} element={item.element} exact={item.exact} />
                                    ))}
                                </Route>
                            ))}
                        </Route>
                        <Route path="*" element={<Notfound />} />
                    </Routes>
                </HashRouter>
                <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </div>
        </GoogleOAuthProvider>
    );
}

export default App;
