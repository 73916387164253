import React from "react";
import ReactApexChart from "react-apexcharts";

export default function CircularChart({ data, labels, colors }) {
    const options = {
        chart: {
            height: "100%",
            type: "radialBar",
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 5,
                    size: "30%",
                    background: "transparent",
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: false,
                    },
                },
            },
        },
        colors: colors,
        labels: labels,
        // tooltip: {
        //     enabled: true,
        //     shared: true,
        // },
        tooltip: {
            enabled: true,
            shared: true,

            y: {
                formatter: (value) => (value !== null ? value + "%" : "N/A"),
            },
        },
        stroke: {
            lineCap: "round",
        },
        legend: {
            show: true,
            floating: false,
            // fontSize: "16px",
            position: "bottom",
            // offsetX: 60,
            // offsetY: 0,
            labels: {
                // useSeriesColors: true,
            },
            markers: {
                size: 0,
            },
            // tooltipHoverFormatter: function (seriesName, opts) {
            //     return seriesName;
            // },
            formatter: function (seriesName, opts) {
                // return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
                return Math.round(opts.w.globals.series[opts.seriesIndex]) + "%";
            },
            itemMargin: {
                vertical: 0,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        show: false,
                    },
                },
            },
        ],
        noData: {
            text: "Data not available",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: "14px",
                fontFamily: undefined,
            },
        },
    };

    return <ReactApexChart options={options} series={data} type="radialBar" height="350" />;
}
