import React from "react";
import { Topbar } from "../../layout/topbar";
import { CustomRadioButtons } from "../../components/input/input";
import { CustomSlider } from "../../components/Slider/Slider";
import PrimaryButton from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";

const CustomizePlan = () => {
    const navigate = useNavigate();
    const option = [
        {
            name: "0-50 Employees",
            value: "0-50 Employees",
        },
        {
            name: "51-100 Employees",
            value: "0-50 Employees",
        },
        {
            name: "101-249 Employees",
            value: "0-50 Employees",
        },
        {
            name: "250+ Employees",
            value: "0-50 Employees",
        },
    ];
    return (
        <>
            <Topbar onBoardingScreen={true} />
            <div className="container">
                <h1 className="text-semibold">Customize your plan based on needs and go PRO</h1>
                <div className="flex">
                    <div className="col-8">
                        <div className=" border-1 border-gray-300 p-3 bg-white border-round-lg">
                            <h3 className="mb-2">Please select number of employees your company have</h3>
                            <small className="text-gray-400">
                                The start price includes 1 admin user and 12 surveys and unlimited employee registrations (in terms of sending out surveys automatically via mail).
                            </small>

                            <CustomRadioButtons options={option} className={"flex gap-3 flex-column my-3"}></CustomRadioButtons>
                            <h4 className="text-base font-normal text-dark">
                                If you’ve more than 500 employees <span className=" text-primary underline font-semibold">Contact Us</span>
                            </h4>
                        </div>
                        <div className=" border-1 border-gray-300 p-3 bg-white border-round-lg my-3">
                            <h3 className="mb-2">Add ons based on your needs</h3>
                            <small className="text-gray-400">51-100 Employees at 12 months (internal)</small>
                            <div className="my-3">
                                <CustomSlider label={"Add more admin users"} valueTitle={"user"} max={10} minTitle={"0"} maxTitle={"10"} />
                                <CustomSlider label={"Employee logins"} valueTitle={"Licenses"} max={50} minTitle={"0"} maxTitle={"50"} />
                                <CustomSlider label={"Add extra surveys"} valueTitle={"surveys"} max={100} minTitle={"0"} maxTitle={"100"} />
                                <CustomSlider label={"Add 1-1 coaching sessions for employees"} valueTitle={"sessions"} max={200} minTitle={"0"} maxTitle={"200"} />
                                <CustomSlider label={"Add status meetings with HQ consultants"} valueTitle={"meetings"} max={10} minTitle={"0"} maxTitle={"10"} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className=" border-1 border-gray-300 p-3 bg-white border-round-lg">
                            <h3 className="text-2xl">Plan Details</h3>
                            <h3 className="my-2 text-sm">Number of employees</h3>
                            <div className="flex justify-content-between align-items-center border-round-lg  border-1 border-gray-300 p-2">
                                <small>0-50 Employees</small>
                                <div className="flex align-items-center">
                                    <h3 className="text-xl">dkk 1.999</h3>
                                    <span className="text-gray-300"> &nbsp; / Month</span>
                                </div>
                            </div>{" "}
                            <h3 className="text-xl text-skyblue">Add ons</h3>
                            <div className="mb-3">
                                <h3 className="my-2 text-sm">Admin User</h3>
                                <div className="flex justify-content-between align-items-center border-round-lg  border-1 border-gray-300 p-2 relative ">
                                    <small>
                                        <span className="bg-gray-300 text-gray-500 border-round-lg p-2">7</span> License X dkk 19,00
                                    </small>
                                    <div className="flex align-items-center">
                                        <h3 className="text-xl">dkk 1.999</h3>
                                    </div>{" "}
                                    <span className="bg-gray-300 absolute right-0 top-0 -m-2 border-circle p-1 flex align-items-center justify-content-center border-1 border-gray-400">
                                        <i className="pi pi-times text-sm  text-gray-500 cursor-pointer"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <h3 className="my-2 text-sm">Employee logins</h3>
                                <div className="flex justify-content-between align-items-center border-round-lg  border-1 border-gray-300 p-2 relative ">
                                    <small>
                                        <span className="bg-gray-300 text-gray-500 border-round-lg p-2">7</span> License X dkk 19,00
                                    </small>
                                    <div className="flex align-items-center">
                                        <h3 className="text-xl">dkk 1.999</h3>
                                    </div>{" "}
                                    <span className="bg-gray-300 absolute right-0 top-0 -m-2 border-circle p-1 flex align-itemscenter justify-content-center border-1 border-gray-400">
                                        <i className="pi pi-times text-sm  text-gray-500 cursor-pointer"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <h3 className="my-2 text-sm">Extra surveys</h3>
                                <div className="flex justify-content-between align-items-center border-round-lg  border-1 border-gray-300 p-2 relative ">
                                    <small>
                                        <span className="bg-gray-300 text-gray-500 border-round-lg p-2">7</span> License X dkk 19,00
                                    </small>
                                    <div className="flex align-items-center">
                                        <h3 className="text-xl">dkk 1.999</h3>
                                    </div>{" "}
                                    <span className="bg-gray-300 absolute right-0 top-0 -m-2 border-circle p-1 flex align-itemscenter justify-content-center border-1 border-gray-400">
                                        <i className="pi pi-times text-sm  text-gray-500 cursor-pointer"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <h3 className="my-2 text-sm">1-1 coaching sessions for employees</h3>
                                <div className="flex justify-content-between align-items-center border-round-lg  border-1 border-gray-300 p-2 relative ">
                                    <small>
                                        <span className="bg-gray-300 text-gray-500 border-round-lg p-2">7</span> License X dkk 19,00
                                    </small>
                                    <div className="flex align-items-center">
                                        <h3 className="text-xl">dkk 1.999</h3>
                                    </div>{" "}
                                    <span className="bg-gray-300 absolute right-0 top-0 -m-2 border-circle p-1 flex align-itemscenter justify-content-center border-1 border-gray-400">
                                        <i className="pi pi-times text-sm  text-gray-500 cursor-pointer"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <h3 className="my-2 text-sm">Status meetings with HQ consultants</h3>
                                <div className="flex justify-content-between align-items-center border-round-lg  border-1 border-gray-300 p-2 relative ">
                                    <small>
                                        <span className="bg-gray-300 text-gray-500 border-round-lg p-2">7</span> License X dkk 19,00
                                    </small>
                                    <div className="flex align-items-center">
                                        <h3 className="text-xl">dkk 1.999</h3>
                                    </div>{" "}
                                    <span className="bg-gray-300 absolute right-0 top-0 -m-2 border-circle p-1 flex align-itemscenter justify-content-center border-1 border-gray-400">
                                        <i className="pi pi-times text-sm  text-gray-500 cursor-pointer"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="flex justify-content-between align-items-center ">
                                <span className="text-lg text-gray-500 font-normal my-2">Subtotal</span>
                                <span className="text-lg text-gray-500 font-normal">dkk 1.999</span>
                            </div>
                            <div className="flex justify-content-between align-items-center border-bottom-1 py-3 border-gray-300">
                                <span className="text-lg text-gray-500 font-normal">Tax (10%)</span>
                                <span className="text-lg text-gray-500 font-normal">dkk 19</span>
                            </div>
                            <div className="flex justify-content-between align-items-center py-3 ">
                                <span className="text-lg text-gray-500 font-medium my-2">Total</span>
                                <span className="text-2xl text-primary font-semibold">dkk 1.999</span>
                            </div>
                            <PrimaryButton label={"Buy Now"} onClick={() => navigate("/setup-board")}></PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomizePlan;
