import DashboardIcon from "../../assets/icons/dashboard.png";
import SurveyIcon from "../../assets/icons/surveys.png";
import ServiceIcon from "../../assets/icons/services.png";
import CoachingSessionIcon from "../../assets/icons/jobopening.png";
import EmployeeIcon from "../../assets/icons/employees.png";
import NotificationIcon from "../../assets/icons/notification-bing.png";
import HelpIcon from "../../assets/icons/help.png";
import SettingIcon from "../../assets/icons/setting.png";
import { useState } from "react";

const LayoutData = () => {
    const [isFullSidebar, setIsFullSidebar] = useState(true);
    const menuItems = [
        {
            name: "Dashboard",
            link: "/employee/dashboard",
            icon: DashboardIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Surveys",
            link: "/employee/surveys",
            icon: SurveyIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Coaching Sessions",
            link: "/employee/coaching-session",
            icon: CoachingSessionIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Notifications",
            link: "/employee/notifications",
            icon: NotificationIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Help",
            link: "/employee/help",
            icon: HelpIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Settings",
            link: "/employee/settings",
            icon: SettingIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
    ];
    return { menuItems, isFullSidebar, setIsFullSidebar };
};
export default LayoutData;
