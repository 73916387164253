import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    data: [],
    requested: [],
    details: {},
};

export const serviceAction = createAsyncThunk("service/get_services", async ({ category = "", search = "" }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api(
            "get",
            category || search ? Constants.END_POINT.GET_SERVICES + "?" + (category ? "category=" + category + "&" : "") + (search ? search : "") : Constants.END_POINT.GET_SERVICES
        );
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(serviceSlice.actions.setServiceList(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(serviceSlice.actions.setServiceList(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const requestedServicesAction = createAsyncThunk("service/requested_services", async ({ query = "" }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.REQUESTED_SERVICES + "?" + query);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(serviceSlice.actions.setRequestedServices(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(serviceSlice.actions.setRequestedServices(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const requestServiceAction = createAsyncThunk("service/request_service", async ({ data, id = null, next }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = id ? await api("put", Constants.END_POINT.REQUEST_SERVICE + "/" + id, data) : await api("post", Constants.END_POINT.REQUEST_SERVICE, data);
        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            next();
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const jobDetailAction = createAsyncThunk("service/get_job_details", async ({ id }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.REQUESTED_SERVICES + "/" + id);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(serviceSlice.actions.setServiceDetails(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(serviceSlice.actions.setServiceDetails(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const deleteJobAction = createAsyncThunk("service/delete_job", async ({ id, setDialog, next }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("delete", Constants.END_POINT.REQUESTED_SERVICES + "/" + id, {});
        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            setDialog({ visible: false });
            next();
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            setDialog({ visible: false });
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
        setServiceList: (state, action) => {
            state.data = action.payload;
        },
        setRequestedServices: (state, action) => {
            state.requested = action.payload;
        },
        setServiceDetails: (state, action) => {
            state.details = action.payload;
        },
    },
});

export const { setServiceList } = serviceSlice.actions;

export default serviceSlice.reducer;
