import React, { useState } from "react";
import { Slider } from "primereact/slider";
import { InputText } from "primereact/inputtext";
import InputLayout from "../input/InputLayout";
import { react } from "../input/InputLayout";

export function CustomSlider({ label, col, name, onChange, data, errorMessage, extraClassName, className, max, selected, required, maxTitle, valueTitle, minTitle, ...props }) {
    const [value, setValue] = useState(7);

    return (
        <InputLayout col={col || 12} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <div className="card flex justify-content-center">
                <div className="w-full p-3">
                    {value > 0 ? (
                        <p
                            className="range_value text-sm relative text-center px-2  bg-primary text-white border-round-xl"
                            id="label_number"
                            style={{ width: "110px", padding: "10px", left: `calc(${(100 / max) * value}% - 55px )` }}
                        >
                            {value} {valueTitle}
                        </p>
                    ) : (
                        <div className="" style={{ paddingTop: "49px" }}></div>
                    )}
                    <Slider aria-labelledby="label_number" max={max} value={value} onChange={(e) => setValue(e.value)} className="w-full" />
                </div>
            </div>{" "}
            <div className="flex justify-content-between w-full">
                <div className="text-sm text-gray-500">{minTitle}</div> <div className="text-sm text-gray-500">{maxTitle} </div>
            </div>
        </InputLayout>
    );
}
