// authService.js
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import Constants from '../../services/constants';
import jwtDecode from "jwt-decode";
import { SocialLoginAction } from '../../redux1/Slices/loginSlice';

const msalConfig = {
    auth: {
        clientId: Constants.MS_CLIENT_ID,
        // authority: `https://login.microsoftonline.com/${Constants.MS_TENANT_ID}`,
        redirectUri: Constants.MS_REDIRECT_URI, // Redirect URI configured in Azure AD app
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

// Call the initialize function and await its completion
async function initializeMsal() {
    try {
        await msalInstance.initialize(); // Handle redirect response if there is any
        // Other initialization logic if needed
    } catch (error) {
        console.error('Failed to initialize MSAL:', error);
    }
}

export const loginWithMicrosoftSSO = async (dispatch, navigate) => {
    try {

        await initializeMsal();
        const loginRequest = {
            prompt: 'select_account',
            scopes: ['openid', 'email'], // Scopes required for authentication
        };
        const response = await msalInstance.loginPopup(loginRequest);
        const decodeduser = jwtDecode(response.idToken);

        const payload = {
            firstName: decodeduser.name.split(" ").length ? decodeduser.name.split(" ")[0] : decodeduser.name,
            lastName: decodeduser.name.split(" ").length ? decodeduser.name.split(" ")[1] : "",
            socialId: response.uniqueId,
            email: decodeduser.email,
            loginType: 'MICROSOFT'
        }
        dispatch(SocialLoginAction({ payload, navigate }));

        // Handle successful login
    } catch (error) {
        console.error('Microsoft SSO login error:', error);
        // Handle login error
    }
};