import React from "react";
import deleteIcon from "../../assets/icons/delete-rounded.png";
import PrimaryButton, { ButtonPosition } from "../buttons/button";
import { Dropdown } from "primereact/dropdown";
import { employeeTableColumns } from "../../utils/constants";

// Main Dynamic Popup for all projects

export default function ConfirmDailog({ dialog, setDialog, ...props }) {
    const initalState = {
        visible: false,
        title: "",
        description: null,
        type: "",
        cancel: false,
        saveFunction: null,
        buttonCol: ""
    };
    return (
        <>
            {dialog?.visible ? (
                <div className="fixed w-full h-full left-0 bg-black-alpha-60 top-0 flex justify-content-center align-items-center" style={{ zIndex: 999999999 }} {...props}>
                    <div className="bg-white p-5 border w-25rem relative">
                        {dialog?.cancelIcon ? (
                            <div className="text-right">
                                <i className="pi pi-times cursor-pointer" onClick={() => setDialog(initalState)} />
                                <h2 className={`text-xl m-0 text-center`}>{dialog?.title}</h2>
                            </div>
                        ) : null}
                        {dialog?.type === "DELETE" ? <img src={deleteIcon} alt="Delete Icon" width={48} height={48} /> : null}
                        {dialog?.title && !dialog.cancelIcon ? <h2 className={`text-xl`}>{dialog?.title}</h2> : null}
                        <p className={dialog.type === "SHARE" ? "border  overflow-hidden p-3 text-overflow-ellipsis" : ""}>{dialog?.description ? dialog?.description : null}</p>
                        <div className="px-2">
                            <ButtonPosition position={dialog?.buttonPosition ? dialog?.buttonPosition : "center"}>
                                {dialog?.cancel ? <PrimaryButton label="Cancel" col={dialog?.buttonCol || 6} onClick={() => setDialog(initalState)} secondary /> : null}
                                {dialog?.saveFunction ? (
                                    <PrimaryButton
                                        label={
                                            dialog?.type === "DELETE"
                                                ? "Delete"
                                                : dialog?.type === "CONFIRM"
                                                    ? "Yes"
                                                    : dialog?.type === "SAVE"
                                                        ? "Save"
                                                        : dialog?.type === "LOGOUT"
                                                            ? "Log Out"
                                                            : dialog.saveLabel
                                        }
                                        col={dialog?.buttonCol || 6}
                                        onClick={() => dialog?.saveFunction()}
                                        severity={dialog.type === "DELETE" ? "danger" : ""}
                                        icon={dialog.type === "SHARE" ? "pi pi-link" : ""}
                                    />
                                ) : null}
                            </ButtonPosition>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export function CategoryDialog({ children, title, visible, setVisible, saveFunction, cancelFunction, ...props }) {
    return (
        <>
            {visible ? (
                <div className="fixed w-full h-full left-0 bg-black-alpha-60 top-0 flex justify-content-center align-items-center" style={{ zIndex: 999999999 }} {...props}>
                    <div className="bg-white p-5 border w-full md:w-7 m-5 ">
                        <h2 className="text-center mb-0">{title ? title : "Add More Categories"}</h2>
                        <div className="text-center mb-3"><small className="text-500">(Min. one Category is required)</small></div>
                        <div className="flex flex-wrap gap-3 overflow-auto mb-5" style={{ maxHeight: "50vh" }}>
                            {children}
                        </div>
                        <ButtonPosition>
                            {cancelFunction ? <PrimaryButton label="Cancel" onClick={() => cancelFunction()} secondary /> : null}
                            {saveFunction ? <PrimaryButton label="Save" onClick={() => saveFunction()} /> : null}
                        </ButtonPosition>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export function CSVMappingDialog({ title, visible, setVisible, saveFunction, cancelFunction, csvHeaders, mappedCsvHeaders, setMappedCsvHeaders, ...props }) {
    return (
        <>
            {visible ? (
                <div className="fixed w-full h-full left-0 bg-black-alpha-60 top-0 flex justify-content-center align-items-center" style={{ zIndex: 999 }} {...props}>
                    <div className="bg-white p-5 border w-full md:w-7 m-5 ">
                        <h2 className="text-center mb-0">{title}</h2>
                        <div className="text-center mb-3"><small className="text-500">(Please choose right columns.)</small></div>
                        <div className="p-5">
                            <div className="flex justify-content-between p-2">
                                <div className="flex flex-column">
                                    <h3>File Columns</h3>
                                </div>
                                <div className="flex flex-column">
                                    <h3>Database Columns</h3>
                                </div>
                            </div>
                            {csvHeaders.map((item, index) => {
                                return (
                                    <div className="flex justify-content-between p-2" key={index}>
                                        <div className="flex flex-column">
                                            {item}
                                        </div>
                                        {/* <div className="w-5">----------></div> */}
                                        <div className="flex flex-column">
                                            <Dropdown className="border-round-lg" value={mappedCsvHeaders[item.trim()]} style={{ width: "150px" }}
                                                onChange={(e) => setMappedCsvHeaders({ ...mappedCsvHeaders, [item.trim()]: e.value })} options={employeeTableColumns.map((col, index) => {
                                                    return { name: col?.name, value: col?.value, disabled: Object.values(mappedCsvHeaders).includes(col.value) ? true : false }
                                                })} optionLabel="name" optionValue="value"></Dropdown>
                                        </div>
                                    </div>
                                )
                            })}



                        </div>
                        <ButtonPosition>
                            {cancelFunction ? <PrimaryButton label="Cancel" onClick={() => cancelFunction()} secondary /> : null}
                            {saveFunction ? <PrimaryButton label="Save" onClick={() => saveFunction()} disable={!Object.keys(mappedCsvHeaders) || (Object.keys(mappedCsvHeaders).length < employeeTableColumns.length)} /> : null}
                        </ButtonPosition>
                    </div>
                </div >
            ) : null
            }
        </>
    );
}
