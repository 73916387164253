import React from "react";
import { SocialLoginButton } from "../../components/buttons/button";
import GoogleIcon from "../../assets/icons/google.png";
import MicrosoftIcon from "../../assets/icons/microsoft.png";
import GoogleSignInButton from "../../components/auth/GoogleAuth";

export const SocialLogin = ({ microsoftlabel, googlelabel, onGoogleClick, onMicrosoftClick }) => {
    return (
        <>
            <div className="col-12">
                <p className="sub-heading-divider relative text-center">Or</p>
            </div>
            <GoogleSignInButton />

            {/* <div className="col-12">
                <SocialLoginButton onClick={onGoogleClick} src={GoogleIcon} label={googlelabel} />
            </div> */}
            <div className="col-12 m-0">
                <SocialLoginButton src={MicrosoftIcon} label={microsoftlabel} onClick={onMicrosoftClick} />
            </div>
        </>
    );
};
