import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Provider } from "react-redux";
import store from "./redux1";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PrimeReactProvider } from 'primereact/api';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId="63604964081-lik5kft97cf58dhmh8ffbu6irfc93cis.apps.googleusercontent.com">
        <Provider store={store}>
        <PrimeReactProvider value={{ripple: true}}>
            <App />
            </PrimeReactProvider>
        </Provider>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
