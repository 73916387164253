import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { EducationCard, HighlightCard, MainCard, ServiceHighlight } from "../../components/cards/card";
import { useNavigate, useParams } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { deleteJobAction, jobDetailAction } from "../../redux1/Slices/servicesSlice";
import { useDispatch, useSelector } from "react-redux";
import budgetIcon from "../../assets/icons/budget.png";
import contractIcon from "../../assets/icons/contract.png";
import timeframeIcon from "../../assets/icons/timeframe.png";
import locationIcon from "../../assets/icons/location.png";
import notesIcon from "../../assets/icons/notes.png";
import { imagePath } from "../../utils/commonFunctions";
import { Chip } from "primereact/chip";

export default function ViewService() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const service = useSelector((state) => state.services.details);
    const [dialog, setDialog] = useState({ visible: false });

    const breadCrumbItems = [{ label: "Job Details" }];

    const deleteJob = () => {
        if (id) {
            setDialog({
                visible: true,
                title: "Are you sure you want to this Job?",
                type: "DELETE",
                cancel: true,
                saveFunction: () => dispatch(deleteJobAction({ id, setDialog, next: () => navigate("/services/request-list") })),
            });
        }
    };

    useEffect(() => {
        if (id) dispatch(jobDetailAction({ id }));
    }, [id]);

    return (
        <DashboardLayout heading="Job Details">
            <BreadCrumb
                model={breadCrumbItems}
                home={{
                    label: "Service Requests",
                    template: () => (
                        <span className="cursor-pointer" onClick={() => navigate(-1)}>
                            Service Requests
                        </span>
                    ),
                }}
                pt={{
                    root: { className: "bg-transparent border-none p-0 font-semibold " },
                }}
            />
            <div className="flex justify-content-between align-items-center">
                <h2>Job Details</h2>
                {service?.applicant === null ? (
                    <div>
                        <i className="pi pi-pencil mr-3 cursor-pointer text-xl" onClick={() => navigate("/services/request-list/edit/" + id)} style={{ color: "green" }} />
                        <i className="pi pi-trash cursor-pointer text-xl" onClick={deleteJob} style={{ color: "red" }} />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <MainCard extraClass="mt-0" height="auto" dialog={dialog} setDialog={setDialog}>
                <div className="flex flex-wrap">
                    <ServiceHighlight icon={imagePath(service?.category?.icon || "")} title="Category" description={service?.category?.name} />
                    <ServiceHighlight icon={imagePath(service?.service?.icon || "")} title="Service" description={service?.service?.name} />
                    <ServiceHighlight icon={contractIcon} title="Length of Contract" description={service?.lengthOfContract} />
                    <ServiceHighlight icon={budgetIcon} title="Max Budget" description={service?.maxBudget} />
                    <ServiceHighlight icon={timeframeIcon} title="Timeframe" description={service?.timeframe} />
                    <ServiceHighlight icon={locationIcon} title="Location" description={service?.location} />
                    <ServiceHighlight icon={notesIcon} title="Notes" description={service?.notes} col={12} />
                </div>
            </MainCard>
            {service?.applicant !== null ? (
                <>
                    <h2>Consultant Details</h2>
                    <MainCard extraClass="mt-0" height="auto">
                        <ServiceHighlight
                            icon={imagePath(service?.applicant?.profilePicture || "")}
                            title={service?.applicant?.fullName}
                            description={service?.applicant?.designation || "Consultant"}
                        />
                    </MainCard>
                    <MainCard height="auto">
                        <h3>Education</h3>
                        {service?.applicant?.education?.map((education, index) => (
                            <EducationCard
                                key={index}
                                degree={education.degree}
                                university={education.university}
                                startDate={education.startDate}
                                endDate={education.endDate}
                                className={index < service.applicant.education.length - 1 && "border-bottom-1 border-gray-400"}
                            />
                        ))}
                    </MainCard>
                    <MainCard height="auto">
                        <h3>References and Recommendations </h3>
                        <div className="flex gap-3">
                            {service?.applicant?.referencesAndRecommendations?.map((ref, index) => (
                                <HighlightCard key={index} title={ref.fullName} description={ref.designation} col={3} />
                            ))}
                        </div>
                    </MainCard>
                    <MainCard height="auto">
                        <h3>Specialty</h3>
                        <div className="flex flex-wrap gap-3 specialty-container">
                            {service?.applicant?.specialties?.map((ref, index) => (
                                <Chip label={ref} />
                            ))}
                        </div>
                    </MainCard>
                    <MainCard height="auto">
                        <h3>Additional Information</h3>
                        <p>{service?.applicant?.additionalInformation}</p>
                    </MainCard>
                </>
            ) : (
                ""
            )}
        </DashboardLayout>
    );
}
