import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import UploadImg from "../../assets/images/upload-cloud.png";
import sampleEmployeesCSV from '../../assets/files/sample_employees.csv'
import csvtojson from 'csvtojson';
import { Skeleton } from "primereact/skeleton";

export default function CustomUploadFile({ name, employeesFile, setEmployeesData, setEmployeesFile, setCsvMappingDialoge, setCsvHeaders, validateEmployeesCSV, uploadEmployees, isLoading = false }) {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const [temp, setTemp] = useState({ fn: () => null });
    const fileUploadRef = useRef(null);

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        const reader = new FileReader();
        reader.onload = async (e) => {
            let fileContent = e.target.result;
            if (fileContent.includes(";")) {
                fileContent = fileContent.replace(/;/g, ',');
            }
            const csvHeaders = fileContent.split('\n')[0].split(',').filter(header => header.trim());
            const json = await csvtojson().fromString(fileContent);
            const cleanJson = json.map(row => {
                const cleanedRow = {};
                for (const key in row) {
                    if (row[key].trim() !== '') {
                        cleanedRow[key] = row[key];
                    }
                }
                return cleanedRow;
            });
            setEmployeesData(cleanJson);
            setCsvHeaders(csvHeaders);
        };
        reader.readAsText(e.files[0]);

        setEmployeesFile(e.files[0]);

        setTotalSize(_totalSize);
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
        setEmployeesFile("");
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { chooseButton } = options;

        return <>{employeesFile ? "" : <div className="w-full text-center p-2">{chooseButton}</div>}</>;
    };

    const mapCSVModal = () => {
        // console.log(csvHeaders);
        setCsvMappingDialoge(true);
    }

    useEffect(() => {
        if (employeesFile === "") {
            temp.fn();
        }
    }, [employeesFile]);

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap justify-content-between">
                <div className="flex align-items-center" style={{ width: "40%" }}>
                    <i className="pi pi-file text-5xl" />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <div className="flex align-items-center gap-3">
                    <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                    <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
                    <Button
                        type="button"
                        icon="pi pi-check"
                        className="p-button-outlined p-button-rounded p-button-success ml-auto"
                        onClick={() => {
                            setTemp({ fn: props.onRemove });
                            mapCSVModal()
                            // validateEmployeesCSV(onTemplateRemove);
                        }}
                    />
                </div>
            </div>
        );
    };

    const emptyTemplate = (options) => {
        const { className, chooseButton } = options;

        return (
            <>
                {employeesFile ? (
                    ""
                ) : (
                    <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
                        <div className="w-full flex align-items-center flex-column">
                            <img src={UploadImg} alt="" />
                            <span style={{ fontSize: "1.2em", color: "var(--text-gray-100)" }} className="mt-5">
                                Select a file or drag and drop here
                            </span>
                            <p>.csv, .xls, file size no more than 10MB</p>
                            {chooseButton}
                        </div>
                    </div>
                )}
            </>
        );
    };

    const chooseOptions = { icon: "pi ", iconOnly: false, label: "Select file", className: "custom-choose-btn p-button-rounded p-button-outlined" };
    const uploadOptions = { icon: "pi pi-fw pi-cloud-upload", iconOnly: true, className: "custom-upload-btn p-button-success p-button-rounded p-button-outlined" };
    const cancelOptions = { icon: "pi pi-fw pi-times", iconOnly: true, className: "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined" };
    return (

        <div className="custom-csv-upload my-3">
            <h3 className="text-xl mb-0">Import xls or csv file</h3>
            <p className="mt-0"><a href={sampleEmployeesCSV} download="sample_employees.csv">Download sample CSV file</a></p>
            <h4 className="mb-2">Upload File</h4>
            <Toast ref={toast}></Toast>
            {isLoading ? (
                <>
                    <Skeleton height="30rem" className="mb-2" />
                    <Skeleton className="mb-2" /> <Skeleton /> <Skeleton />{" "}
                </>
            ) : (
                <div className="border-1 border-round-xl p-4 border-gray-300 bg-white">
                    <FileUpload
                        className=""
                        contentClassName="p-0"
                        ref={fileUploadRef}
                        name={name}
                        accept=".csv, .xls"
                        maxFileSize={10485760}
                        emptyTemplate={emptyTemplate}
                        onSelect={onTemplateSelect}
                        onError={onTemplateClear}
                        onClear={onTemplateClear}
                        headerTemplate={headerTemplate}
                        itemTemplate={itemTemplate}
                        chooseOptions={chooseOptions}
                        uploadOptions={uploadOptions}
                        cancelOptions={cancelOptions}
                    />
                </div>
            )}
        </div>
    );
}
