import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { CustomCalenderIcon, CustomCheckbox, CustomDropDown, CustomSearch } from "../input/input";
import { Link, useLocation } from "react-router-dom";
import PrimaryButton, { ButtonPosition, PrimaryButtonOutlined } from "../buttons/button";
import ConfirmDailog from "../overlay/Dialog";
import { ReactComponent as MonthlyImg } from "../../assets/svg/monthly-plan.svg";
import { ReactComponent as YearlyImg } from "../../assets/svg/yearly-plan.svg";
import { ReactComponent as YearlyFeeImg } from "../../assets/svg/yearlyfree logo.svg";
import { ReactComponent as IncreaseIcon } from "../../assets/svg/increase.svg";
import { ReactComponent as DecreaseIcon } from "../../assets/svg/decrease.svg";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import { formatCurrency, imagePath } from "../../utils/commonFunctions";
import { Chip } from "primereact/chip";
import { Avatar } from "primereact/avatar";
import moment from "moment";
import { Menu } from "primereact/menu";
import { departmentAction } from "../../redux1/Slices/departmentSlice";

// Main Card for whole project
export const MainCard = ({
    children,
    title,
    subtitle,
    headingCenter,
    extraClass,
    underline,
    search,
    searchValue,
    onChange,
    link,
    dateFilter,
    dates,
    filterKey,
    buttonLable,
    height,
    buttonIcon,
    onClick,
    saveLabel,
    saveFunction,
    saveCol,
    disableSave,
    cancelLable,
    cancelFuncation,
    disableCancel,
    cancelCol,
    dialog,
    border,
    background,
    padding,
    setDialog,
    shareFunction,
    disableshare,
    employeeFilter,
    changeDepartment,
    assignLicense,
    setServiceFilter,
    serviceFilter,
    disableChangeDepartment,
    selectionMode,
    ...props
}) => {
    const dispatch = useDispatch();
    const serviceFilterMenu = useRef(null);
    const handleCancelFuncation = () => {
        setDialog({
            visible: true,
            title: `Are you sure you want to ${cancelLable ? cancelLable : " go back"} ?`,
            saveLabel: "Yes",
            buttonPosition: "center",
            cancel: true,
            saveFunction: () => cancelFuncation(),
        });
    };

    const employeeFiletrOptions = [
        { label: "All Departments", command: () => setServiceFilter("UI/UX Departments") },
        { label: "Human Resources", command: () => setServiceFilter("Human Resources") },
        { label: "PHP Developer", command: () => setServiceFilter("PHP Developer") },
        { label: "UI/ UX Designer", command: () => setServiceFilter("UI/UX Designer") },
        { label: "Graphic Designer", command: () => setServiceFilter("Graphic Designer") },
        { label: "Mern Stack Developer", command: () => setServiceFilter("Mern Stack Developer") },
        { label: "Quality Analyst", command: () => setServiceFilter("Quality Analyst") },
        { label: "React Native", command: () => setServiceFilter("React Native") },
        { label: "Wordpress Developer", command: () => setServiceFilter("Wordpress Developer") },
    ];
    const departments = useSelector((state) => state.department.data);

    useEffect(() => {
        dispatch(departmentAction());
    }, []);
    return (
        <div
            className={`${border ? border : "border"} flex flex-column ${padding ? padding : "p-5"} ${background ? background : "bg-white"} relative  ${extraClass ? extraClass : "my-5"} `}
            style={{ minHeight: height ? height : "77vh" }}
            {...props}
        >
            <Menu
                model={departments.map((item) => {
                    return {
                        label: item.name,
                        name: item.name,
                        command: () => setServiceFilter(item.name),
                    };
                })}
                popup
                ref={serviceFilterMenu}
                className="filter-menu"
            />
            <ConfirmDailog dialog={dialog} setDialog={setDialog} />

            <div className="flex justify-content-between align-items-center">
                <div className={headingCenter ? "text-center w-full" : !title && !subtitle ? "max-h-0" : ""}>
                    <div className="flex">
                        <h2 className="my-1">{title}</h2>
                    </div>
                    <p className="mt-1">{subtitle}</p>
                </div>
                <div className="flex gap-2 justify-content-end align-items-center">
                    {changeDepartment ? <PrimaryButton className="w-13" label={"Change Department"} col={5} onClick={changeDepartment} disabled={disableChangeDepartment} /> : null}
                    {/* {assignLicense ? <PrimaryButton className="w-14" secondary label={"Assign License"} col={3} /> : null} */}

                    {employeeFilter ? (
                        <div className="col">
                            <PrimaryButtonOutlined label={"Filters"} icon="pi pi-sliders-h" iconPos="left" onClick={(event) => serviceFilterMenu.current.toggle(event)} />
                        </div>
                    ) : null}
                    {dateFilter ? <CustomCalenderIcon onChange={dateFilter} filterKey={filterKey} value={dates} selectionMode={selectionMode} /> : null}
                    {search && onChange ? <CustomSearch col={4} value={searchValue} onChange={onChange} /> : null}
                    {link && buttonLable ? (
                        <Link to={link} className="text-primary ">
                            {buttonLable}
                        </Link>
                    ) : null}
                    {(buttonLable || buttonIcon) && !link ? <PrimaryButton onClick={onClick} className={`py-2 ${buttonIcon ? "p-2" : ""}`} label={buttonLable} icon={buttonIcon} /> : null}
                </div>
            </div>
            {underline ? <hr className="border-400 w-full" /> : null}
            <div className="flex flex-column col p-0 justify-content-between h-full">
                <div>{children}</div>
                <ButtonPosition>
                    {shareFunction ? <PrimaryButton label="Send" onClick={() => shareFunction()} disable={disableshare} col={saveCol} /> : null}
                    {saveFunction ? <PrimaryButton label={saveLabel ? saveLabel : "Save"} onClick={() => saveFunction()} disable={disableSave} col={saveCol} /> : null}
                    {/* {shareFunction ? <PrimaryButton label="Share" onClick={() => shareFunction()} /> : null} */}
                    {cancelFuncation ? <PrimaryButton label={cancelLable ? cancelLable : "Cancel"} secondary onClick={() => handleCancelFuncation()} disable={disableCancel} col={cancelCol} /> : null}
                </ButtonPosition>
            </div>
        </div>
    );
};

// Dashboard top cards
export const DashobardOverviewCard = ({ title, value, fromLastMonth, user }) => {
    const isLoading = useSelector((state) => state.loader.isLoading);
    return (
        <div className="border col min-w-min p-3">
            <h3 className="text-skyblue">{isLoading ? <Skeleton className="mb-2"></Skeleton> : title}</h3>
            <h2 className="text-2xl">{isLoading ? <Skeleton className="mb-2" width="5rem"></Skeleton> : value ? value + `${!user ? "%" : ""}` : 0 + `${!user ? "%" : ""}`}</h2>
            <div className="flex align-items-center">
                {isLoading ? (
                    <Skeleton className="mb-2"></Skeleton>
                ) : fromLastMonth ? (
                    <>
                        <Chip
                            label={`${fromLastMonth > 0 ? "+" + fromLastMonth : fromLastMonth}%`}
                            icon={(option) => (fromLastMonth > 0 ? <IncreaseIcon /> : <DecreaseIcon />)}
                            style={{ background: fromLastMonth > 0 ? "#E3F4E3" : "#F4E3E3", color: fromLastMonth > 0 ? "#65C565" : "#C56565" }}
                        />
                        <p className="ml-2">from last month</p>
                    </>
                ) : null}
            </div>
        </div>
    );
};

// Focus area row diff action
export const FocusArea = ({ department, score, action, category, border = true }) => {
    return (
        <div className={`flex justify-content-between align-items-center border-400 py-2 ${border ? "border-bottom-1 " : ""}`}>
            <div>
                <h3 className="my-1">{department}</h3>
                {category ? <p className="mt-1">{category}</p> : null}
            </div>
            <Chip
                label={action}
                style={{ background: score < 50 ? "#FFCECE" : score >= 50 && score < 75 ? "#FFF8E3" : "", color: score < 50 ? "#CF2933" : score >= 50 && score < 75 ? "#FFD248" : "" }}
            />
        </div>
    );
};

// Show Plans
export const PlansCard = ({ type, data, heading = "Culture Quest Pro", className, extraClassName, onClick, ...props }) => {
    const location = useLocation();

    const features = data?.features || [];
    return (
        <div className={`border p-5 pb-0 mb-5 ${type === "month" ? "bg-primary text-white" : ""} relative ${extraClassName} `}>
            {data.type === "yearly" && location.pathname === "/membership/yearly" ? (
                <>
                    <div className="discount-style">
                        <div className="">Get 15% off</div>{" "}
                    </div>
                </>
            ) : (
                ""
            )}

            <div className="flex justify-content-between align-items-center">
                <div className="">
                    <h1 className=" m-0">{heading}</h1>
                    <h4 className="font-normal mt-0 text-gray-400">
                        {data.type === "yearly" && location.pathname === "/membership/yearly" ? (
                            <>
                                billing monthly at <span className="text-primary font-semibold line-through">dkk1.77</span> <span className="text-primary font-semibold"> dkk1.499</span>
                            </>
                        ) : (
                            ""
                        )}
                    </h4>
                </div>

                <h1 className="col- m-0 text-right">
                    {data.price ? (
                        <>
                            <div className="flex gap-2 align-items-end ">
                                <span className="text-base text-normal mb-2 heading-color">starts with</span>{" "}
                                <span className="text-dark">
                                    {formatCurrency(data.price)} &nbsp;
                                    {data.type === "yearly" ? (
                                        <>
                                            <small className="text-gray-400 text-sm  mr-6">/ month</small>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </div>
                        </>
                    ) : (
                        <span className="text-primary">Free</span>
                    )}
                </h1>
            </div>
            <div className="flex justify-content-between ">
                <div className="col-5 p-0 mb-5">
                    <div className="flex h-15rem flex-wrap flex-column">
                        {features.map((feature, index) => {
                            return (
                                <p key={index} className={type === "month" ? "text-white" : ""}>
                                    <i className="pi pi-check font-bold text-blue mr-3" />
                                    <span className="text-base">{feature}</span>
                                </p>
                            );
                        })}
                    </div>
                    <ButtonPosition position="left">
                        <PrimaryButton
                            secondary={type === "month" ? true : false}
                            className={type === "month" ? "text-primary bg-white" : ""}
                            label={data.isPurchased ? "Cancel Plan" : "Select Plan"}
                            onClick={onClick}
                        />
                    </ButtonPosition>
                </div>
                <div className="col-3 p-0 flex justify-content-end align-items-end text-right">{type === "month" ? <MonthlyImg /> : data.price ? <YearlyImg /> : <YearlyFeeImg />}</div>
            </div>
        </div>
    );
};

export const CurrentPlansCard = ({ type, data, className, ...props }) => {
    return (
        <div className={`border p-5 pb-0 mb-5 ${type === "month" ? "bg-primary text-white" : ""}`}>
            <div className="flex justify-content-between align-items-center">
                <h1 className="col m-0">Culture Quest Pro</h1>
                <div className="flex align-items-center gap-3">
                    <h1>Free</h1>
                    <PrimaryButtonOutlined className={"text-primary bg-white"} label={"Buy add ons"} />
                    <PrimaryButtonOutlined className={"text-primary bg-white "} label={"Upgrade Plan"} />
                </div>
            </div>
            <div className="flex justify-content-between align-items-end">
                <div className="col-9 p-0 mb-5">
                    <h1 className="text-xl">{data.Heading}</h1>
                    <div className="flex flex-wrap ">
                        {data.CurrentPlan.map((item, index) => {
                            return (
                                <p key={index} className={type === "month" ? "text-white" : ""}>
                                    <i className="pi pi-check font-bold mr-3" />
                                    <span>{item}</span>
                                </p>
                            );
                        })}
                    </div>
                    <div className="col-9 p-0 mb-5">
                        <h1 className="text-xl">{data.Heading2}</h1>
                        <div className="">
                            {data.overViewData.map((item, index) => {
                                return (
                                    <p key={index} className={type === "month" ? "text-white" : ""}>
                                        <div className="text-center">
                                            <small>{item.Heading}</small>
                                            <span>{item.Heading}</span>
                                            <h1 className="text-5xl p-0 m-0">{item.noofAccess}</h1>
                                        </div>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <ButtonPosition position="left">
                        <PrimaryButton secondary={type === "month" ? true : false} className={type === "month" ? "text-primary bg-white" : ""} label={data.isPurchased ? "Cancel Plan" : "Buy Plan"} />
                    </ButtonPosition>
                </div>
            </div>
        </div>
    );
};

// improve card
export const ImproveCard = ({ id, src, data, title, selectedFilter, category, navigate, ...props }) => {
    return (
        <div key={id} className={`py-3 mb-3 ${selectedFilter !== "ourSuggestions" ? "flex justify-content-between gap-3 border-bottom-1 border-400 border-custom" : undefined}`}>
            <div className={selectedFilter !== "ourSuggestions" ? "flex gap-5 w-8" : "block"}>
                {
                    src ? <div className="improvement-img mb-2">
                        <img src={src} alt="" />
                    </div> : null
                }
                <div class={selectedFilter === "topThreeServices" ? "" : selectedFilter === "otherCompanySuggestions" ? " w-full" : null}>
                    <h2 className="mt-0 mb-2 text-lg ">{title}</h2>
                    <p className="mt-2 text-sm">{data}</p>
                </div>
            </div>
            {
                selectedFilter === "topThreeServices" ? <PrimaryButton label={"Request Service"} onClick={() => navigate('/services', {
                    state: {
                        service: id,
                        category
                    }
                })}></PrimaryButton> : ""
            }
        </div>
    );
};

// Show Categories
export const CategoryOverviewcard = ({ imgclass, src, subtitle, hideDescription = false, disable, extraClass, title, onChange, selected, name, hideCheckbox }) => {
    const isLoading = useSelector((state) => state.loader.isLoading);

    return (
        <div className={`border p-4 transition-ease-in transition-duration-200  shadow-none hover:shadow-1 ${extraClass} ${!disable ? "cursor-pointer" : ""}`} onClick={!disable ? onChange : null}>
            <div className="flex justify-content-between align-items-start mb-3">
                <div className={`survey-img flex justify-content-center align-items-center ${imgclass}`}>
                    {isLoading ? <Skeleton shape="circle" size="3rem" /> : <img src={src} alt="" width={54} height={54} />}
                </div>
                {hideCheckbox ? null : (
                    <span>
                        <CustomCheckbox value={selected} name={name} disable={disable} extraclass="justify-content-end" />
                    </span>
                )}
            </div>
            <h3 className="text-base font-semibold heading-color m-0"> {isLoading ? <Skeleton /> : title}</h3>
            {!hideDescription ? <> {isLoading ? <Skeleton /> : <p className="text-sm font-normal text-gray-light mb-0 ">{subtitle}</p>}</> : null}
        </div>
    );
};

// Survey highlight details
export const HighlightCard = ({ title, description, col }) => {
    const isLoading = useSelector((state) => state.loader.isLoading);
    return (
        <div className={`col-12 md:col-6 lg:col-${col || 2} ${isLoading ? "pr-5" : "p-0"}`}>
            <h5>{isLoading ? <Skeleton /> : title}</h5>
            {isLoading ? <Skeleton /> : <p>{description}</p>}
        </div>
    );
};

// Service overview card
export const ServiceCard = ({ data, setData, services, className, ...props }) => {
    const isLoading = useSelector((state) => state.loader.isLoading);

    return (
        <div className="flex flex-wrap">
            {services?.map((service, index) => {
                return (
                    <div className="col-6">
                        <div key={index} className="border text-center bg-white p-5">
                            <div className={`flex flex-column justify-content-center align-items-center `}>
                                {isLoading ? <Skeleton shape="circle" size="3rem" /> : <img src={imagePath(service?.icon)} alt="Service" width={54} height={54} />}
                                {isLoading ? <Skeleton className="mt-2" /> : <h2 className="mb-0">{service?.name}</h2>}
                                {isLoading ? <Skeleton className="mt-2" /> : <p>{service?.description}</p>}
                                {/* {isLoading ? <Skeleton className="mt-2" /> : <PrimaryButton label="Request Service" onClick={() => setData({ ...data, service: service?._id })} />} */}
                                {isLoading ? (
                                    <Skeleton className="mt-2" />
                                ) : (
                                    <PrimaryButton
                                        label="Request Service"
                                        onClick={() => {
                                            setData({ ...data, category: service?.category?._id, service: service?._id });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

// service highlight card
export const ServiceHighlight = ({ title, description, icon, col = 4 }) => {
    const isLoading = useSelector((state) => state.loader.isLoading);
    return (
        <div className={`flex gap-3 align-items-center col-${col}`}>
            <div className={`survey-img flex justify-content-center align-items-center`}>{isLoading ? <Skeleton shape="circle" size="3rem" /> : <img src={icon} alt="" width={54} height={54} />}</div>
            <div className="col">
                {isLoading ? <Skeleton width="5rem" className="mb-3" /> : <h3 className="m-0">{title}</h3>}
                {isLoading ? <Skeleton width="7rem" /> : <p className="m-0">{description}</p>}
            </div>
        </div>
    );
};

// Notification Card
export const NotificationCard = ({ img, message, time, unread }) => {
    return (
        <div className={`w-full flex align-items-center border-bottom-1 border-gray-300 gap-3 p-3 ${unread ? "bg-cyan-50" : ""}`}>
            {img ? <img src={img} alt="user-img" /> : <Avatar icon="pi pi-user" size="xlarge" shape="circle" />}
            <div>
                <p className="mb-0">{message}</p>
                <p className="text-gray-900 mt-0">{moment(time).startOf("seconds").fromNow()}</p>
            </div>
        </div>
    );
};

// Education card
export const EducationCard = ({ degree, university, startDate, endDate, key, className = "" }) => {
    return (
        <div className={`flex justify-content-between align-items-center ${className}`} key={key}>
            <div>
                <h3 className="mb-0">{university}</h3>
                <p className="mt-0">{degree}</p>
            </div>
            <div className="flex gap-2 align-items-center">
                <p>
                    <i className="pi pi-calendar" />
                </p>
                <p>{moment(startDate).format("MMMM YYYY")}</p>
                <p>-</p>
                <p>{moment(endDate).format("MMMM YYYY")}</p>
            </div>
        </div>
    );
};

// Question Analytics Card

export const AnalyticsQuestionCard = ({ data }) => {
    const actionColor = (value, isText) => {
        // if (value === 0) return !isText ? "#E8E8E8" : "#898989";
        if (value >= 0 && value < 50) return !isText ? "#FFA4A4" : "#CF2933";
        else if (value >= 50 && value < 75) return !isText ? "#FBEAB6" : "#FFD248";
        else return !isText ? "#8FDAA5" : "#34A853";
    };
    return (
        <>
            {data?.map((item, index) => (
                <div key={index}>
                    <h3>{index + 1 + ". " + item?.question}</h3>
                    <div className="flex align-items-start gap-3 flex-wrap">
                        <div className="flex COL-12 md:col-8">
                            {item?.choices?.map((choice, i) => (
                                <div className="p-0 border-1 border-white" style={{ width: "20%" }}>
                                    <div
                                        className=" w-full p-1 mt-2"
                                        style={{
                                            background: actionColor(choice?.value),
                                            height: "10px",
                                            borderRadius: i === 0 ? "10px 0 0 10px" : i === item.choices.length - 1 ? "0 10px 10px 0" : "0",
                                        }}
                                    ></div>
                                    <p className="text-center my-1" style={{ color: actionColor(choice?.value, true) }}>
                                        {choice?.name}
                                    </p>
                                    <div className="flex justify-content-center">
                                        <Chip
                                            label={choice?.answerSelectionPercentage + "%"}
                                            className="shadow-1"
                                            style={{ borderRadius: "8px", background: actionColor(choice?.value), color: actionColor(choice?.value, true) }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Chip
                            label={item?.overAllScore + "/100"}
                            className="shadow-1"
                            style={{ borderRadius: "8px", background: actionColor(item?.overAllScore), color: actionColor(item?.overAllScore, true) }}
                        />
                        <Chip
                            className="shadow-1"
                            label={item?.recommendation}
                            style={{ borderRadius: "8px", background: actionColor(item?.overAllScore), color: actionColor(item?.overAllScore, true) }}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};
