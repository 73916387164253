import React, { useEffect, useState } from "react";
import { CustomCalenderInput, CustomDropDown, CustomForm, CustomInput } from "../../components/input/input";
import PrimaryButton, { ButtonPosition } from "../../components/buttons/button";
import CustomUploadFile from "../../components/UploadFile/CustomUploadFile";
import { allValidations } from "../../utils/formValidations";
import { departmentAction } from "../../redux1/Slices/departmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmployeeErrorTable from "../Employees/EmployeeErrorTable";
import { setEmployeeErrorList } from "../../redux1/Slices/employeesSlice";
import { CSVMappingDialog, CategoryDialog } from "../../components/overlay/Dialog";

const EmployeeForm = ({
    data,
    setData,
    handleAddEmployee,
    uploadEmployees,
    validateEmployeesCSV,
    employeesFile,
    setEmployeesFile,
    hideFileUpload = false,
    dialog,
    setDialog,
    onBoarding = false,
    showErrorPage,
    setShowErrorPage,
    setEmployeesData,
    mappedCsvHeaders,
    setMappedCsvHeaders
}) => {
    const departments = useSelector((state) => state.department.data);
    const employeesErrorFile = useSelector((state) => state.employees.errorData);
    const isLoading = useSelector((state) => state.loader.isLoading);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showErrorList, setShowshowErrorList] = useState([]);
    const [csvMappingDialoge, setCsvMappingDialoge] = useState(false);
    const [csvHeaders, setCsvHeaders] = useState([]);

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const genderOptions = [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" },
        { name: "Other", value: "Other" },
    ];
    const licenseOptions = [
        { name: "Yes", value: true },
        { name: "No", value: false },
    ];

    const clearData = () => {
        setData({
            userId: "",
            department: "",
            fullName: "",
            dob: "",
            gender: "",
            email: "",
            isLicensed: false,
        });
    };

    // Required Functions
    const getDepartment = () => {
        dispatch(departmentAction({ accessToken: localStorage.getItem("authToken") }));
    };

    useEffect(() => {
        getDepartment();
    }, []);

    useEffect(() => {
        if (employeesFile !== "") {
            setShowErrorPage(true);
        } else {
            setShowErrorPage(false);
        }
    }, [employeesErrorFile, employeesFile]);

    useEffect(() => {
        if (employeesErrorFile.length) {
            setShowshowErrorList(employeesErrorFile);
            // console.log("employeeErrorFile", employeesErrorFile);
        } else {
            setShowshowErrorList([]);
        }
    }, [employeesErrorFile]);

    const retryUpload = () => {
        setEmployeesFile("");
        setShowErrorPage(false);
        dispatch(setEmployeeErrorList([]));
    };

    const cancelFunction = () => {
        setEmployeesFile("");
        setShowErrorPage(false);
        dispatch(setEmployeeErrorList([]));
        navigate(-1);
    };

    const cancelCSVMappingDialoge = () => {
        setCsvMappingDialoge(false);
        setMappedCsvHeaders({});
    }

    return (
        <>
            <CSVMappingDialog visible={csvMappingDialoge} setVisible={setCsvMappingDialoge} title={"Please map your file headers."}
                csvHeaders={csvHeaders} saveFunction={() => { validateEmployeesCSV(); cancelCSVMappingDialoge() }} cancelFunction={() => { cancelCSVMappingDialoge() }} mappedCsvHeaders={mappedCsvHeaders} setMappedCsvHeaders={setMappedCsvHeaders}></CSVMappingDialog >

            {showErrorPage && showErrorList.length ? (
                <EmployeeErrorTable
                    retryUpload={retryUpload}
                    uploadEmployees={uploadEmployees}
                    setShowshowErrorList={setShowshowErrorList}
                    dialog={dialog}
                    setDialog={setDialog}
                    cancelFunction={!onBoarding ? cancelFunction : ""}
                    onBoarding={onBoarding}
                    employeesErrorFile={employeesErrorFile}
                />
            ) : (
                <>
                    <div>
                        <h2 className="text-xl">Manual Add</h2>
                        <CustomForm extraclass="-mx-2 ">
                            <CustomInput extraClassName="p-2" label="Full Name" data={data} value={data.fullName} onChange={handleChange} name="fullName" required placeholder="Enter" col={4} />
                            <CustomCalenderInput extraClassName="p-2" label="Date of Birth" data={data} value={data.dob} onChange={handleChange} name="dob" required placeholder="Enter" col={4} />
                            <CustomDropDown
                                extraClassName="p-2 "
                                label="Gender"
                                options={genderOptions}
                                required
                                data={data}
                                value={data.gender}
                                onChange={handleChange}
                                name="gender"
                                placeholder="Enter"
                                col={4}
                            />
                            <CustomInput extraClassName="p-2" label="E-mail" required data={data} value={data.email} onChange={handleChange} name="email" placeholder="Enter" col={4} />
                            <CustomDropDown
                                extraClassName="p-2"
                                label="Department"
                                options={departments.map((dept) => ({ name: dept.name, value: dept._id }))}
                                required
                                data={data}
                                value={data.department}
                                onChange={handleChange}
                                name="department"
                                placeholder="Enter"
                                col={4}
                            />
                            <CustomDropDown
                                extraClassName="p-2"
                                label="License"
                                options={licenseOptions}
                                required
                                data={data}
                                value={data.isLicensed}
                                onChange={handleChange}
                                name="isLicensed"
                                placeholder="Enter"
                                col={4}
                            />
                        </CustomForm>
                        <div className="flex gap-2 justify-content-end">
                            {data.fullName || data.dob || data.gender || data.email || data.isLicensed || data.department ? (
                                <PrimaryButton secondary label={data.isUpdate ? "Cancel" : "Clear Data"} onClick={() => (!hideFileUpload ? clearData() : navigate(-1))}></PrimaryButton>
                            ) : null}

                            <PrimaryButton label={data.isUpdate ? "Update" : "Add"} className={"px-7"} onClick={handleAddEmployee}></PrimaryButton>
                        </div>
                    </div>
                    {!hideFileUpload ? (
                        <>
                            <CustomUploadFile isLoading={isLoading} extraClassName setEmployeesData={setEmployeesData} employeesFile={employeesFile} setEmployeesFile={setEmployeesFile} setCsvMappingDialoge={setCsvMappingDialoge} setCsvHeaders={setCsvHeaders} />
                            {!onBoarding ? (
                                <ButtonPosition className="my-4">
                                    <PrimaryButton secondary={true} label={"Go Back"} onClick={() => navigate(-1)} />
                                </ButtonPosition>
                            ) : (
                                ""
                            )}
                        </>
                    ) : null}
                </>
            )
            }
        </>
    );
};

export default EmployeeForm;
