import React, { useState } from "react";
import { MainCard } from "../../../components/cards/card";
import { CustomForm, CustomInput, CustomInputPhoneNumber, ImageUpload } from "../../../components/input/input";
import { useDispatch, useSelector } from "react-redux";
import { showFormErrors, uploadFile } from "../../../utils/commonFunctions";
import { updateProfileAction } from "../../../redux1/Slices/loginSlice";

export default function EditProfile() {
    const user = useSelector((state) => state.user.data) || JSON.parse(localStorage.getItem("User"));
    const dispatch = useDispatch();
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        image: "",
        companyName: "",
        email: "",
        phoneNumber: "",
    });
    const [dialog, setDialog] = useState({
        visible: false,
        title: "",
        description: null,
        type: "",
        cancel: false,
        saveFunction: null,
    });
    const saveFunction = () => {
        let ignoreFields = ["firstName", "lastName"];
        console.log("data", data);
        if (showFormErrors(data, setData, ignoreFields)) {
            setDialog({
                visible: true,
                title: "Are you sure you want to Save ?",
                description: "Are you sure you want to save? This action cannot be undone.",
                type: "SAVE",
                cancel: true,
                saveFunction: updateProfile,
            });
        }
    };
    const updateProfile = async () => {
        let image = await uploadFile(data?.image);
        let newData = { ...data, image: image };
        setData(newData);
        dispatch(updateProfileAction({ data: newData, setDialog }));
    };
    const setUserData = () => {
        if (user)
            setData({
                firstName: user?.firstName,
                lastName: user?.lastName,
                image: user?.image,
                companyName: user?.companyName,
                email: user?.email,
                phoneNumber: user?.phoneNumber,
            });
    };
    return (
        <MainCard title="Edit Profile" saveFunction={saveFunction} dialog={dialog} setDialog={setDialog} cancelFuncation={setUserData}>
            <CustomForm>
                <ImageUpload  />
                <CustomInput label="First Name" name="firstName" placeholder="Enter" col={12} />
                <CustomInput label="Last Name" name="lastName" placeholder="Enter" col={12} />
                <CustomInput label="Company Name" name="companyName" required placeholder="Enter" col={12} />
                <CustomInput label="Email Address" name="email" disabled placeholder="Enter" col={12} />
                <CustomInputPhoneNumber
                    label="Phone Number"
                    className="bg-white w-full border-round-lg "
                    required
                    col={12}
                    placeholder="Enter "
                    data="55"
                    // onChange={handleChange}
                ></CustomInputPhoneNumber>
            </CustomForm>
        </MainCard>
    );
}
