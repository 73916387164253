import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleIcon from "../../assets/icons/google.png";
import { SocialLoginButton } from '../buttons/button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SocialLoginAction } from '../../redux1/Slices/loginSlice';

const GoogleSignInButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {

            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo',
                    {
                        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                    })
            const decodeduser = userInfo.data;

            const payload = {
                firstName: decodeduser.name.split(" ").length ? decodeduser.name.split(" ")[0] : decodeduser.name,
                lastName: decodeduser.name.split(" ").length ? decodeduser.name.split(" ")[1] : "",
                socialId: decodeduser.sub,
                email: decodeduser.email,
                loginType: 'GOOGLE'
            }

            dispatch(SocialLoginAction({ payload, navigate }));
        },
    });

    return (
        <SocialLoginButton onClick={login} src={GoogleIcon} label={"Sign In with Google"} />
    );
};

export default GoogleSignInButton;