import React from "react";
import checkIcon from "../../../../assets/icons/double-check.png";
import PrimaryButton, { ButtonPosition } from "../../../../components/buttons/button";
import { MainCard } from "../../../../components/cards/card";
import { useTranslation } from "react-i18next";

export default function Guidelines({ setIsGuideLines }) {
    const { t } = useTranslation();

    return (
        <div className="p-5">
            <MainCard extraClass="">
                <h2 className="text-center m-0">{t("guidelines.Hi And Welcome")}</h2>
                <p>{t("guidelines.description")}</p>
                <h2>{t("guidelines.Practical Information.header")}:</h2>
                <div className="flex align-items-start mb-3">
                    <img src={checkIcon} className="mt-2" width={22} height={12} alt="CheckMark" />
                    <p className="m-0 pl-3" dangerouslySetInnerHTML={{ __html: t("guidelines.Practical Information.point1") }} />
                </div>
                <div className="flex align-items-start mb-3">
                    <img src={checkIcon} className="mt-2" width={22} height={12} alt="CheckMark" />
                    <p className="m-0 pl-3" dangerouslySetInnerHTML={{ __html: t("guidelines.Practical Information.point2") }} />
                </div>
                <div className="flex align-items-start mb-3">
                    <img src={checkIcon} className="mt-2" width={22} height={12} alt="CheckMark" />
                    <p className="m-0 pl-3" dangerouslySetInnerHTML={{ __html: t("guidelines.Practical Information.point3") }} />
                </div>
                <div className="flex align-items-start mb-3">
                    <img src={checkIcon} className="mt-2" width={22} height={12} alt="CheckMark" />
                    <p className="m-0 pl-3" dangerouslySetInnerHTML={{ __html: t("guidelines.Practical Information.point4") }} />
                </div>
                <div className="flex align-items-start mb-3">
                    <img src={checkIcon} className="mt-2" width={22} height={12} alt="CheckMark" />
                    <p className="m-0 pl-3" dangerouslySetInnerHTML={{ __html: t("guidelines.Practical Information.point5") }} />
                </div>

                <div className="mt-8 text-center">
                    <h2>{t("guidelines.Enjoy the process!")}</h2>
                    <ButtonPosition position="center">
                        <PrimaryButton col={2} label="Start" onClick={() => setIsGuideLines(false)} />
                    </ButtonPosition>
                </div>
            </MainCard>
        </div>
    );
}
