import moment from "moment/moment";

export const setDepartmentsData = (departments, departmentScores, setDeptColumns, setDeptScores) => {
    let scores = [];
    departments?.forEach((dept) => {
        let departmentData = {
            id: dept?._id,
            name: dept?.name,
        };
        Object.keys(departmentScores)?.forEach((key, index) => {
            departmentData[key] = "";
            departmentScores[key]?.forEach((item) => {
                if (item.departmentName === departmentData.name) {
                    departmentData[key] = item.overAllScore;
                }
            });
        });
        scores.push(departmentData);
    });
    if (scores.length) {
        let columns = [
            { field: "name", header: "Departments", body: "DEPARTMENT_NAME" },
            { field: Object.keys(scores[0])[2], header: Object.keys(scores[0])[2], body: "SCORE" },
            { field: Object.keys(scores[0])[3], header: Object.keys(scores[0])[3], body: "SCORE" },
            { field: Object.keys(scores[0])[4], header: Object.keys(scores[0])[4], body: "SCORE" },
        ];

        setDeptColumns(columns);
        setDeptScores(scores);
    }
};

export const getYears = (startYear) => {
    const currentYear = moment().year();

    // Generate an array of years from specific year to current year
    const yearsArray = [];
    for (let year = startYear; year <= currentYear; year++) {
        yearsArray.push(year);
    }
    return yearsArray;
}
