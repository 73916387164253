import React, { useState } from "react";
import { Privatelayout } from "../layout/Privatelayout";
import SingupImg from "../../assets/images/singupleft.png";
import { CustomForm, CustomOtp } from "../../components/input/input";
import PrimaryButton from "../../components/buttons/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ResendOTP } from "otp-input-react";
import { verifyOTP } from "../../redux1/Slices/registerSlice";
import { hideLoader, showLoader } from "../../redux1/Slices/loaderSlice";
import Constants from "../../services/constants";
import { api } from "../../services/api";
import { showToast } from "../../redux1/Slices/toastSlice";
import { sendEmailApi } from "../../redux1/Slices/loginSlice";
import PublicTopbar from "../../layout/PublicTopbar";

var base64 = require("base-64");

export const OtpVerification = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { encodedEmail } = params;
    const email = base64.decode(encodedEmail);
    const [verifyotp, setOtpVerify] = useState(false);

    const [otp, setOtp] = useState("");

    const sendOtpApi = async () => {
        dispatch(showLoader());
        var encodedEmail = base64.encode(email);

        const payload = { email, otp };
        const res = await api("post", Constants.END_POINT.VERIFY_FORGOT_OTP, payload);
        dispatch(hideLoader());

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));

            navigate(`/reset-password/${encodedEmail}`);
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
        }
    };
    const renderButton = (buttonProps) => {
        console.log("buttonProps", buttonProps);
        return (
            <button className="border-none bg-transparent text-skyblue text-lg font-medium mb-2 shadow-none" {...buttonProps}>
                {buttonProps.remainingTime !== 0 ? `Resend Code in  ${buttonProps.remainingTime} sec` : "Resend"}
            </button>
        );
    };
    const renderTime = (remainingTime) => {
        return (
            <div className="col-12 -  mx-0 px-0 ">
                {/* <p className="mt-0 flex align-items-end text-blue-300 ">{remainingTime} sec</p> */}
                <PrimaryButton label="Continue" className="w-full bg-white text-primary" onClick={sendOtpApi} />
            </div>
        );
    };

    console.log("otp::", otp);

    return (
        <>
            {/* <PublicTopbar /> */}
            <Privatelayout
                childClassName=" flex flex-column justify-content-center align-items-center "
                src={SingupImg}
                heading={"We’ve sent a code to your email  "}
                subheading={"Enter the 6-digit verification code sent to "}
                subtext={email ? email?.replace(email?.substring(1, 4), "***") : "z****@gmail.com"}
                subtextsize="text-sm"
            >
                <CustomForm>
                    <div className="flex justify-content-center w-full">
                        <CustomOtp onChange={(e) => setOtp(e)} value={otp} col={12} />
                    </div>
                    <p className="col-12 m-0 text-lg  font-medium" style={{ color: "#425466" }}>
                        <ResendOTP onResendClick={() => sendEmailApi(email, navigate, dispatch)} renderTime={renderTime} renderButton={renderButton} className="flex-column-reverse " />
                    </p>
                </CustomForm>
            </Privatelayout>
        </>
    );
};
