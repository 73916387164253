import React, { useEffect, useState } from "react";
import EmployeeForm from "../Common/EmployeeForm";
import { MainCard } from "../../components/cards/card";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "primereact/hooks";
import EmployeeTable from "../Common/EmployeeTable";
import { showFormErrors } from "../../utils/commonFunctions";
import { deleteEmployeeAction, employeeAction, manageEmployeeAction, uploadEmployeeAction, validateEmployeeAction } from "../../redux1/Slices/employeesSlice";
import Loader from "../../utils/Loader";

export default function AddEmployees({ setActiveStep }) {
    const employees = useSelector((state) => state.employees.data);
    const [searchFor, searchString, setSearchFor] = useDebounce("", 1000);
    const [employeesFile, setEmployeesFile] = useState("");
    const [showErrorPage, setShowErrorPage] = useState(false);
    const [employeesData, setEmployeesData] = useState([]);
    const [mappedCsvHeaders, setMappedCsvHeaders] = useState({});

    const dispatch = useDispatch();
    const [dialog, setDialog] = useState({ visible: false });
    const [data, setData] = useState({
        department: "",
        fullName: "",
        dob: "",
        gender: "",
        email: "",
        isLicensed: false,
    });

    const getEmployees = () => {
        dispatch(employeeAction({}));
        setData({
            department: "",
            fullName: "",
            dob: "",
            gender: "",
            email: "",
            isLicensed: false,
        });
        setEmployeesFile("");
    };

    const handleAddEmployee = () => {
        if (showFormErrors(data, setData)) {
            setDialog({
                visible: true,
                title: " Are you sure you want to save the details?",
                cancel: true,
                saveLabel: "Save",
                buttonPosition: "center",
                saveFunction: () => dispatch(manageEmployeeAction({ data, id: data?.id, setDialog, next: () => getEmployees() })),
            });
        }
    };
    const uploadEmployees = (setShowshowErrorList) => {
        setDialog({
            visible: true,
            title: " Are you sure you want to upload selected file?",
            cancel: true,
            saveLabel: "Upload",
            buttonPosition: "center",
            saveFunction: () =>
                dispatch(
                    uploadEmployeeAction({
                        data: { jsonData: employeesData },
                        setDialog,
                        next: () => {
                            getEmployees();
                            setShowshowErrorList(false);
                        },
                    })
                ),
        });
    };

    const searchEmployee = (searchString) => {
        const searchKeys = ["fullName", "email", "department.name"];
        const search = `?searchKeys=${JSON.stringify(searchKeys)}&searchString=${searchString}`;
        dispatch(employeeAction({ search: search }));
    };

    const validateEmployeesCSV = () => {
        const mappedEmpData = [];
        employeesData.map(item => {
            const newObj = {};
            Object.keys(item).forEach(key => {
                const newKey = mappedCsvHeaders[key] || key; // Use new key name if exists in keyMap, otherwise use old key name
                newObj[newKey] = item[key];
            });
            mappedEmpData.push(newObj);
        });

        setEmployeesData([...mappedEmpData]);

        dispatch(validateEmployeeAction({ data: { jsonData: mappedEmpData }, next: () => null }));
    };

    useEffect(() => {
        searchEmployee(searchString);
    }, [searchString]);

    useEffect(() => {
        getEmployees();
    }, []);

    return (
        <MainCard
            title="Add Employees"
            subtitle={"Add your employees to your company profile."}
            saveFunction={!showErrorPage ? () => setActiveStep(2) : null}
            saveLabel={"Next"}
            dialog={dialog}
            setDialog={setDialog}
        >
            <Loader />
            <EmployeeForm
                data={data}
                setData={setData}
                handleAddEmployee={handleAddEmployee}
                mappedCsvHeaders={mappedCsvHeaders}
                setMappedCsvHeaders={setMappedCsvHeaders}
                setEmployeesData={setEmployeesData}
                uploadEmployees={uploadEmployees}
                validateEmployeesCSV={validateEmployeesCSV}
                employeesFile={employeesFile}
                setEmployeesFile={setEmployeesFile}
                dialog={dialog}
                setDialog={setDialog}
                onBoarding={true}
                showErrorPage={showErrorPage}
                setShowErrorPage={setShowErrorPage}
            />
            {!showErrorPage ? (
                <MainCard title="Employees" title1={"Licensed users"} height="auto" search onChange={setSearchFor}>
                    <EmployeeTable data={employees} setSearchFor={setSearchFor} setData={setData} setDialog={setDialog} getEmployees={getEmployees} />
                </MainCard>
            ) : (
                ""
            )}
        </MainCard>
    );
}
