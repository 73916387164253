import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryAction, getQuestionsAction } from "../../redux1/Slices/categorySlice";
import ShowCategories from "../Common/ShowCategories";
import { showFormErrors } from "../../utils/commonFunctions";
import { addSurveyAction } from "../../redux1/Slices/surveySlice";
import { useNavigate } from "react-router-dom";
import { MainCard } from "../../components/cards/card";
import SurveyForm from "../Common/SurveyForm";

export default function CreateSurvey({ setActiveStep, setSurveyForm, showSurveyForm }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state) => state.category.data);
    const departments = useSelector((state) => state.department.data);

    const [selectedCategories, setSelectedCategories] = useState([]);

    const [dialog, setDialog] = useState({ visible: false });

    const [data, setData] = useState({
        title: "",
        description: "",
        departments: [],
        categories: [],
        startDate: "",
        expiringOn: "",
        repetition: "",
        applicableFor: [],
    });

    const getSurveyCategories = () => {
        dispatch(categoryAction({ accessToken: localStorage.getItem("authToken") }));
    };

    const handleCategorySelection = (category) => {
        if (departments?.length === 0) {
            setDialog({
                visible: true,
                title: "Please start by adding departments to create your initial survey.",
                cancelIcon: true,
                saveLabel: "Add Department",
                buttonCol: 8,
                buttonPosition: "center",
                saveFunction: () => setActiveStep((prev) => prev - 1),
            });
        } else {
            if (selectedCategories.includes(category)) {
                const updatedCategories = selectedCategories.filter((item) => item !== category);
                setSelectedCategories(updatedCategories);
            } else {
                setSelectedCategories([...selectedCategories, category]);
            }
        }
    };

    const getCategoryQuestions = () => {
        dispatch(getQuestionsAction({ selectedCategories }));
        let companyDepartments = departments?.map((dept) => dept._id);
        setData({
            ...data,
            categories: [...selectedCategories],
            departments: [...companyDepartments],
            applicableFor: [...companyDepartments],
        });
        setSurveyForm(true);
    };

    useEffect(() => {
        getSurveyCategories();
    }, []);

    const handleAddSurvey = () => {
        if (showFormErrors(data, setData)) {
            setDialog({
                visible: true,
                title: " Are you sure you want to save the details?",
                cancel: true,
                saveLabel: "Save Survey",
                buttonPosition: "center",
                saveFunction: () => dispatch(addSurveyAction({ data, next: navigateSuccess, setDialog })),
            });
        }
    };

    const navigateSuccess = (id) => {
        navigate("/survey-created/" + id);
    };
    return (
        <MainCard
            title="Create Survey"
            subtitle="Customize your experience by creating survey to access your personalized dashboard."
            dialog={dialog}
            setDialog={setDialog}
            saveFunction={showSurveyForm ? handleAddSurvey : getCategoryQuestions}
            disableSave={!selectedCategories.length}
            saveCol={1}
            saveLabel={showSurveyForm ? "Save" : "Next"}
        >
            {showSurveyForm ? (
                <h3 className="text-lg">Selected Category</h3>
            ) : (
                <p>
                    <span className="text-900">Note-</span> Min. one category is required
                </p>
            )}
            <ShowCategories
                showSurveyForm={showSurveyForm}
                categories={categories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                handleCategorySelection={handleCategorySelection}
            />
            {showSurveyForm ? <SurveyForm selectedCategories={selectedCategories} departments={departments} data={data} setData={setData} /> : null}
        </MainCard>
    );
}
