import React, { useEffect, useState } from "react";
import { Privatelayout } from "../layout/Privatelayout";
import LoginImg from "../../assets/images/loginleft.png";
import { CustomForm, CustomInput, CustomPassword } from "../../components/input/input";
import PrimaryButton from "../../components/buttons/button";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { employeeLoginAction } from "../../redux1/Slices/employeeLoginSlice";
import { showFormErrors } from "../../utils/commonFunctions";
import { allValidations } from "../../utils/formValidations";
import { isAuthenticated } from "../../services/auth";

export const EmployeeLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        email: "",
        loginPassword: "",
    });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleLogin = () => {
        if (showFormErrors(data, setData)) {
            dispatch(employeeLoginAction({ data, navigate }));
        }
    };

    useEffect(() => {
        if (isAuthenticated()) {
            navigate("/employee/dashboard");
        }
    }, [navigate]);

    return (
        <>
            {/* <PublicTopbar /> */}
            <Privatelayout childClassName=" flex flex-column justify-content-center align-items-center col-8 " src={LoginImg} heading="Login to your Account" subheading="Welcome back! Glad to see you.">
                {/* <div className="md:col-6 flex justify-content-center  "> */}
                <CustomForm>
                    <CustomInput
                        labelClassName="text-white"
                        label="Email Address"
                        className="bg-white  w-full border-round-lg"
                        col={12}
                        placeholder="Enter Email"
                        name="email"
                        onChange={handleChange}
                    />
                    <CustomPassword
                        label="Password "
                        hideFooter
                        labelClassName="text-white"
                        inputClassName="bg-white w-full border-round-lg"
                        col={12}
                        placeholder="Enter Password"
                        name="loginPassword"
                        onChange={handleChange}
                    />
                    <div className="col-12 text-right mb-3">
                        <Link to="/employee/forgot-password" className="text-skyblue no-underline text-sm font-medium">
                            Forgot password?
                        </Link>
                    </div>
                    <PrimaryButton label="Sign In" className={"bg-white text-primary"} extraClass="w-full" onClick={handleLogin} />

                </CustomForm>
                {/* </div> */}
            </Privatelayout>
        </>
    );
};
