import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    data: [],
    questions: []
};


export const categoryAction = createAsyncThunk("category/get_category", async ({ accessToken}, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.GET_CATEGORIES, accessToken);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(categorySlice.actions.setCategoriesDetail(res.data));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const getQuestionsAction = createAsyncThunk("category/get_questions", async ({ selectedCategories }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());


        const res = await api("post", Constants.END_POINT.GET_QUESTIONS, {"categoryIds": selectedCategories});

        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(categorySlice.actions.setQuestions(res.data));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        setCategoriesDetail: (state, action) => {
            state.data = action.payload;
        },
        setQuestions: (state, action) =>{
            state.questions = action.payload
        }
    },
});

export const {setCategoriesDetail} = categorySlice.actions;

export default categorySlice.reducer;
