import React from "react";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";

export const ButtonPosition = ({ children, position, flexWrap, className }) => {
    return <div className={`flex gap-3 align-items-center justify-content-${position ? position : "end"} ${flexWrap ? "flex-wrap" : ""} ${className ? className : ""}`}>{children}</div>;
};

export default function PrimaryButton({ type, onClick, label, col, extraClass, loading, className, disable, secondary, ...props }) {
    return (
        <div className={`p-0 ${col ? "md:col-" + col : ""} ${extraClass}`}>
            <Button
                type={type}
                label={label}
                onClick={onClick}
                loading={loading}
                disabled={disable}
                className={`font-semibold p-button-info w-full border-round-lg ${className}`}
                style={{ background: secondary ? "var(--surface-600)" : "", border: secondary ? "none" : "" }}
                {...props}
                
            >
                {" "}
                <Ripple />
            </Button>
        </div>
    );
}
export function PrimaryButtonOutlined({ type, onClick, label, col, extraClass, className, loading, ...props }) {
    return (
        <div className={`${col ? col : ""} ${extraClass}`}>
            <Button type={type} label={label} onClick={onClick} loading={loading} className={`p-button-outlined w-full border-round-lg p-button-primary ${className}`} {...props}>
                <Ripple />
            </Button>
        </div>
    );
}
export const SocialLoginButton = ({ id, col, onClick, loading, src, label, className, ...props }) => {
    return (
        <Button
            id={id}
            className={` mb-3 px-2  justify-content-center w-full bg-transparent border-round-lg bg-white ${className}`}
            onClick={onClick}
            loading={loading}
            {...props}
            style={{ borderColor: "#E8E8E8" }}
        >
            <img src={src} height={20} width={20} alt=""></img>
            <span className=" ml-2 heading-color font-normal">{label}</span>
            <Ripple />
        </Button>
    );
};
