import React, { useEffect } from "react";
import DashboardLayout from "../../../layout";
import { MainCard, NotificationCard } from "../../../components/cards/card";
import { useDispatch, useSelector } from "react-redux";
import { notificationAction } from "../../../redux1/Slices/notificationSlice";
import EmployeeDashboardLayout from "../../../layout/employee";

export default function Notifications() {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notification.data);

    useEffect(() => {
        dispatch(notificationAction({}));
    }, [dispatch]);

    return (
        <EmployeeDashboardLayout heading="Notifications">
            <MainCard extraClass="mt-0">
                {notifications.length ? (
                    notifications.map((notification, index) => <NotificationCard message={notification.message} time={notification.createdAt} unread={notification.status === "Unread"} />)
                ) : (
                    <p>No new notifications</p>
                )}
            </MainCard>
        </EmployeeDashboardLayout>
    );
}
