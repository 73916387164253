import React from "react";
import { CustomDropDown, CustomForm, CustomInputNumber, CustomTextArea } from "../../components/input/input";

export default function ServiceForm({ data, categories, services, handleChange, disable }) {
    const timeframes = [
        { name: "As soon as possible", value: "As soon as possible" },
        { name: "Within 1 month", value: "Within 1 month" },
        { name: "Within 3 months", value: "Within 3 months" },
        { name: "Within 6 montsh", value: "Within 6 months" },
    ];

    const locations = [
        { name: "Hybrid work", value: "Hybrid work" },
        { name: "On-site", value: "On-site" },
        { name: "Remote", value: "Remote" },
    ];

    const lengthOfContract = [
        { name: "3 Months", value: "3 Months" },
        { name: "6 Months", value: "6 Months" },
        { name: "12 Months", value: "12 Months" },
        { name: "We don't know", value: "We don't know" },
    ];

    return (
        <CustomForm extraclass=" -mx-2 ">
            <CustomDropDown
                value={data?.category}
                onChange={handleChange}
                options={categories?.map((category) => ({ name: category.name, value: category._id }))}
                col={data?.service !== null ? 6 : 4}
                placeholder="Select"
                label="Category"
                name="category"
                data={data}
                required
                extraClassName="mb-0 p-2"
                errorMessage={""}
                disabled={disable}
            />
            {data?.service !== null ? (
                <>
                    <CustomDropDown
                        value={data?.service}
                        onChange={handleChange}
                        options={services?.map((service) => ({ name: service.name, value: service._id }))}
                        col={6}
                        placeholder="Select"
                        label="Services"
                        data={data}
                        name="service"
                        required
                        extraClassName="mb-0 p-2"
                        errorMessage={""}
                        disabled={disable}
                    />
                    <CustomInputNumber
                        col={6}
                        label="Max Budget"
                        name="maxBudget"
                        value={data?.maxBudget}
                        data={data}
                        onChange={handleChange}
                        extraClassName="p-2"
                        placeholder="Enter"
                        required={true}
                        disabled={disable}
                    />
                    <CustomDropDown
                        value={data?.lengthOfContract}
                        onChange={handleChange}
                        options={lengthOfContract}
                        col={6}
                        placeholder="Select"
                        name="lengthOfContract"
                        label="Length of Contract"
                        data={data}
                        required
                        extraClassName="mb-0 p-2"
                        errorMessage={""}
                        disabled={disable}
                    />
                    <CustomDropDown
                        value={data?.timeframe}
                        onChange={handleChange}
                        options={timeframes}
                        col={6}
                        placeholder="Select"
                        label="Timeframe"
                        name="timeframe"
                        data={data}
                        required
                        extraClassName="mb-0 p-2"
                        errorMessage={""}
                        disabled={disable}
                    />
                    <CustomDropDown
                        value={data?.location}
                        onChange={handleChange}
                        options={locations}
                        col={6}
                        placeholder="Select"
                        label="Location"
                        name="location"
                        data={data}
                        required
                        extraClassName="mb-0 p-2"
                        errorMessage={""}
                        disabled={disable}
                    />
                    <CustomTextArea
                        label="Notes"
                        value={data?.notes}
                        maxLength={150}
                        placeholder="Please add your note here."
                        extraClassName="mb-0 p-2"
                        name="notes"
                        data={data}
                        onChange={handleChange}
                    />
                </>
            ) : null}
        </CustomForm>
    );
}
