import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { MainCard } from "../../components/cards/card";
import EmployeeTable from "../Common/EmployeeTable";
import { useDebounce } from "primereact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { formatDate, showFormErrors } from "../../utils/commonFunctions";
import { departmentAction } from "../../redux1/Slices/departmentSlice";
import { manageEmployeeAction, employeeAction, deleteEmployeeAction, updateBulkDepartmentsAction } from "../../redux1/Slices/employeesSlice";
import { useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomDropDown } from "../../components/input/input";

export default function Employees() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);

    const [selectedEmployees, setSelectedEmployees] = useState(null);
    const [serviceFilter, setServiceFilter] = useState("");
    const departments = useSelector((state) => state.department.data);
    const employees = useSelector((state) => state.employees.data);
    const [searchFor, searchString, setSearchFor] = useDebounce("", 1000);
    const [updatedDepartment, setUpdatedDepartment] = useState([]);
    const [data, setData] = useState({
        fullName: "",
        email: "",
        department: "",
        isUpdate: false,
    });
    const [dialog, setDialog] = useState({ visible: false });
    const handleDepartmentChange = (col) => (e) => {
        let temp = {
            empId: col._id,
            deptId: e.value,
        };
        let updatedData = updatedDepartment.find((item) => item.empId === col._id) ? updatedDepartment.map((item) => (item.empId === col._id ? temp : item)) : [...updatedDepartment, temp];
        setUpdatedDepartment(updatedData);
    };

    const handleChangeDepartment = () => {
        setDialog({
            visible: true,
            title: "Are you sure you want to update departments for selected employees?",
            type: "SAVE",
            cancel: true,
            saveFunction: () => dispatch(updateBulkDepartmentsAction({ payload: { data: updatedDepartment }, next: getEmployees, setDialog })),
        });
    };

    const getEmployees = () => {
        dispatch(employeeAction({}));
        setData({
            fullName: "",
            email: "",
            department: "",
        });
        setSelectedEmployees([]);
        setUpdatedDepartment([]);
    };

    const priceBodyTemplate = (rowData) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(rowData.price);
    };

    const searchEmployee = (searchString) => {
        const searchKeys = ["fullName", "email", "department.name"];
        const search = `?searchKeys=${JSON.stringify(searchKeys)}&searchString=${searchString}`;
        dispatch(employeeAction({ search: search }));
    };

    useEffect(() => {
        getEmployees();
        dispatch(departmentAction({}));
    }, []);

    useEffect(() => {
        searchEmployee(searchString);
    }, [searchString]);

    useEffect(() => {
        searchEmployee(serviceFilter);
    }, [serviceFilter]);

    useEffect(() => {
        setUpdatedDepartment(updatedDepartment.filter((item) => selectedEmployees.filter((emp) => emp._id === item.empId).length));
    }, [selectedEmployees]);

    const editEmployee = (col) => {
        // if (setData !== null) {
        //     setData({
        //         id: col?._id,
        //         fullName: col?.fullName,
        //         dob: formatDate(col?.dob, "UTC", "ISO"),
        //         gender: col?.gender,
        //         email: col?.email,
        //         department: col?.department?._id,
        //         isLicensed: col?.isLicensed,
        //         isUpdate: true,
        //     });
        // } else {
        navigate("update/" + col?._id);
        // }
    };

    const deleteEmployee = (id) => {
        setDialog({
            visible: true,
            title: "Are you sure you want to delete Department?",
            type: "DELETE",
            cancel: true,
            saveFunction: () => dispatch(deleteEmployeeAction({ id, next: () => getEmployees(), setDialog })),
        });
    };

    const actionTemplate = (col) => {
        return (
            <>
                <i className="pi pi-pencil mr-3 cursor-pointer" onClick={() => editEmployee(col)} style={{ color: "green" }} />
                <i className="pi pi-trash cursor-pointer" onClick={() => deleteEmployee(col._id)}></i>
            </>
        );
    };
    const licenseTemplate = (col) => {
        return (
            <div
                className="text-center p-1 text-xs border-round-xl"
                style={{ color: col.isLicensed === true ? "#ffffff" : "#ffffff", backgroundColor: col.isLicensed === true ? "#36626B" : "#CBCBCB" }}
            >
                {col.isLicensed === true ? "Licensed" : "Unlicensed"}
            </div>
        );
    };

    const empDeptBodyTempate = (col, options) => {
        return selectedEmployees?.filter((emp) => emp._id === col._id).length ? (
            <CustomDropDown
                value={updatedDepartment?.find((item) => item.empId === col._id)?.deptId || col?.department?._id}
                data={col?.department?._id || updatedDepartment}
                options={departments.map((item) => {
                    return {
                        name: item?.name,
                        value: item?._id,
                    };
                })}
                name="Department"
                onChange={handleDepartmentChange(col)}
                extraClassName="p-2"
                placeholder="Select Department"
                required={true}
            />
        ) : (
            <span>{col?.department?.name}</span>
        );
    };

    return (
        <DashboardLayout heading="Employees" onClick={() => navigate("add")} label="Add">
            <MainCard
                title="Employees"
                employeeFilter
                changeDepartment={selectedEmployees?.length ? handleChangeDepartment : null}
                disableChangeDepartment={updatedDepartment?.length ? false : true}
                assignLicense
                subtitle={"Employees Added " + employees.length + "/" + user?.numberOfEmployees}
                search
                setServiceFilter={setServiceFilter}
                serviceFilter={serviceFilter}
                onChange={setSearchFor}
                dialog={dialog}
                setDialog={setDialog}
            >
                <div>
                    {serviceFilter ? (
                        <>
                            <span className="relative">
                                Department Filter: {serviceFilter} <i className="pi pi-times-circle text-red-600 cursor-pointer absolute" onClick={() => setServiceFilter("")}></i>
                            </span>
                        </>
                    ) : (
                        ""
                    )}
                </div>
                {/* <EmployeeTable data={employees} setDialog={setDialog} getEmployees={getEmployees} /> */}
                <DataTable
                    value={employees}
                    selectionMode={"checkbox"}
                    selection={selectedEmployees}
                    onSelectionChange={(e) => setSelectedEmployees(e.value)}
                    dataKey="_id"
                    tableStyle={{ minWidth: "50rem" }}
                    emptyMessage={"No Data Available"}
                    editMode="row"
                >
                    <Column selectionMode="multiple" editor={true} headerStyle={{ width: "3rem" }}></Column>
                    <Column field="fullName" header="Full Name" sortable></Column>
                    <Column field="isLicensed" header="Licensed" body={licenseTemplate} sortable></Column>
                    <Column field={(e) => formatDate(e.dob)} header="DOB" sortable></Column>
                    <Column field="gender" header="Gender" sortable></Column>
                    <Column field="email" header="Email" sortable></Column>
                    <Column field="department.name" header="Department" sortable body={(col) => empDeptBodyTempate(col)}></Column>
                    <Column field="" header="Actions" body={actionTemplate}></Column>
                </DataTable>
            </MainCard>
        </DashboardLayout>
    );
}
