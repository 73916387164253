import React, { useEffect } from "react";
import DashboardLayout from "../../layout";
import { BreadCrumb } from "primereact/breadcrumb";
import { FocusArea, MainCard } from "../../components/cards/card";
import { useDispatch, useSelector } from "react-redux";
import noDataImage from "../../assets/images/no-data.png";
import { focusAreaAction } from "../../redux1/Slices/analyticsSlice";
import { Link, useNavigate } from "react-router-dom";

export default function AllFocusArea() {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const focusArea = useSelector((state) => state.analytics.focusArea);
    const navigate = useNavigate();
    const breadCrumbItems = [{ label: "Focus Area" }];

    useEffect(() => {
        dispatch(focusAreaAction({}));
    }, [dispatch]);

    return (
        <DashboardLayout heading="Focus Area">
            <BreadCrumb
                model={breadCrumbItems}
                home={{
                    label: "Dashboard",
                    template: () => (
                        <span className="cursor-pointer" onClick={() => navigate("/")}>
                            Dashboard
                        </span>
                    ),
                }}
                pt={{
                    root: { className: "bg-transparent border-none p-0 font-semibold " },
                }}
            />
            <MainCard>
                {focusArea ? (
                    <>
                        {Object.keys(focusArea)?.map((data, index) => {
                            return (
                                focusArea[data].length ?
                                    <>
                                        <h4>{data}</h4>
                                        {focusArea[data].map((item, j) => (
                                            <FocusArea
                                                //    department={item?.departmentName}
                                                score={item?.overAllScore}
                                                category={item?.categoryName || null}
                                                action={item?.recommendation}
                                                key={index}
                                                border={index !== focusArea.length - 1}
                                            />
                                        ))}
                                    </> : null
                            );
                        })}
                    </>
                ) : (
                    <div className="text-center py-8">
                        <img className="w-7" src={noDataImage} alt="No Data Available" />
                    </div>
                )}
            </MainCard>
        </DashboardLayout>
    );
}
