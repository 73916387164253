import React from "react";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import DeactiveAccount from "./DeactiveAccount";
import EmployeeDashboardLayout from "../../../layout/employee";
import Tabs from "../../../components/Tabs";

export default function EmployeeSettings() {
    const tabs = [
        {
            name: "Edit Profile",
            path: "edit-profile",
            element: EditProfile,
            exact: true,
        },
        {
            name: "Change Password",
            path: "change-password",
            element: ChangePassword,
            exact: true,
        },
        {
            name: "Deactivate Account",
            path: "deactivate-account",
            element: DeactiveAccount,
            exact: true,
        },
    ];

    return (
        <EmployeeDashboardLayout heading="Settings">
            <Tabs tabs={tabs} />
        </EmployeeDashboardLayout>
    );
}
