import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { surveyByIdAction } from "../../redux1/Slices/surveySlice";
import { employeeByDeptAction } from "../../redux1/Slices/employeesSlice";
import { MainCard } from "../../components/cards/card";
import { shareFunction } from "../../utils/commonFunctions";
import { Skeleton } from "primereact/skeleton";

export default function SurveySharedTo() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const survey = useSelector((state) => state.survey.surveyDetail);
    const employees = useSelector((state) => state.employees.employeeByDept);

    const [departments, setDepartments] = useState([]);
    const [dialog, setDialog] = useState({ visible: false });

    useEffect(() => {
        if (id) {
            dispatch(surveyByIdAction({ id }));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (survey?.applicableFor) {
            setDepartments(survey?.applicableFor?.map((dept) => dept?._id));
        }
    }, [survey]);

    useEffect(() => {
        if (departments?.length) {
            dispatch(employeeByDeptAction({ departments }));
        }
    }, [departments, dispatch]);

    return (
        <DashboardLayout heading="Send Survey">
            <MainCard
                title="This survey is intended to be shared with"
                saveLabel="Send"
                saveFunction={() => {
                    shareFunction(setDialog, survey?._id, survey?.startDate, dispatch);
                }}
                cancelFuncation={() => navigate(-1)}
                dialog={dialog}
                setDialog={setDialog}
            >
                {employees?.length ? (
                    employees?.map((dept, index) => {
                        return (
                            <>
                                <h3 className={index > 0 ? "mt-6" : null}>{dept.departmentName}</h3>
                                {dept.employees.map((emp, i) => (
                                    <p>{emp}</p>
                                ))}
                            </>
                        );
                    })
                ) : (
                    <>
                        <Skeleton className="mb-3" />
                        <Skeleton className="mb-3" />
                        <Skeleton className="mb-3" />
                    </>
                )}
            </MainCard>
        </DashboardLayout>
    );
}
