import React from "react";
import { MainCard } from "../../components/cards/card";
import CustomTable from "../../components/table/table";
import { ReactComponent as NoServices } from "../../assets/svg/no-services.svg";
import { useSelector } from "react-redux";

export default function EmployeeErrorTable({ retryUpload, uploadEmployees, setShowshowErrorList, dialog, setDialog, cancelFunction, onBoarding, setValidRowEmails, employeesErrorFile }) {
    const emptyMessage = () => {
        return (
            <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
                <NoServices />
                <h2>Employees not found</h2>
            </div>
        );
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const RecordDetail = (col) => {
        return (
            <>
                <div> {col.record && col.record.join()}</div>
            </>
        );
    };
    const ErrorDetail = (col) => {
        return (
            <>
                {col.isValidRow ? (
                    <span className="text-green-400 text-bold">Successfully Validated</span>
                ) : (
                    <div className="text-red-400"> {col.rowErrors?.map((item) => capitalizeFirstLetter(item)).join()}</div>
                )}
            </>
        );
    };

    // Table
    const columns = [
        { field: "RecordNo", header: "Record No", body: RecordDetail, sortable: true },
        { field: "errors", body: ErrorDetail, header: "Errors", sortable: true },
    ];

    return (
        <>
            <MainCard
                height="auto"
                dialog={dialog}
                setDialog={setDialog}
                title="Employee Record"
                border={onBoarding ? "none" : ""}
                padding={onBoarding ? "none" : ""}
                cancelFuncation={cancelFunction}
                saveLabel={employeesErrorFile.filter((item) => !item.isValidRow).length >= employeesErrorFile.length ? "Retry" : "Import"}
                saveFunction={employeesErrorFile.filter((item) => !item.isValidRow).length >= employeesErrorFile.length ? () => retryUpload() : () => uploadEmployees(setShowshowErrorList)}
            >
                <MainCard height="auto" extraClass="mb-4 mt-0">
                    <CustomTable columns={columns} emptyMessage={emptyMessage} data={employeesErrorFile} />
                </MainCard>{" "}
                {/* <div className="flex justify-content-end gap-3">
                    {employeesErrorFile.filter((item) => item.isValidRow).length ? (
                        <PrimaryButton
                            className={"px-6"}
                            label={"Retry"}
                            onClick={() => {
                                retryUpload();
                            }}
                        ></PrimaryButton>
                    ) : (
                        <PrimaryButton
                            onClick={() => uploadEmployees(setShowshowErrorList)}
                            label={"Import"}
                            className={"px-6"}
                            disable={employeesErrorFile.filter((item) => !item.isValidRow).length ? true : false}
                        ></PrimaryButton>
                    )}
                </div> */}
            </MainCard>
        </>
    );
}
