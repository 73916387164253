import React, { useEffect, useState } from "react";
import PrimaryButton, { ButtonPosition } from "../../components/buttons/button";
import congratsImage from "../../assets/images/congrats.png";
import { useNavigate, useParams } from "react-router-dom";
import { Topbar } from "../../layout/topbar";
import { shareFunction } from "../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { MainCard } from "../../components/cards/card";

export default function SuccessScreen() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const [dialog, setDialog] = useState({ visible: false });

    useEffect(() => {
        if (!user?.isFirstLogin) navigate("/");
    }, [user]);
    return (
        <>
            <Topbar onBoardingScreen={true} />
            <div className="success-screen flex justify-content-center align-items-center">
                <MainCard className="p-5 w-screnn lg:w-3 text-center" dialog={dialog} setDialog={setDialog}>
                    <img src={congratsImage} alt="Party" />
                    <h1>Congratulations!</h1>
                    <p>You've created your first survey successfully.</p>
                    <p>Manage all your surveys conveniently by heading to the 'Surveys' section on the dashboard.</p>
                    <ButtonPosition position="center" flexWrap>
                        <PrimaryButton label="Send Survey" onClick={() => navigate(`/surveys/send-survey/${id}`)} />
                        <PrimaryButton label="Go to Dashboard" onClick={() => navigate("/dashboard")} />
                    </ButtonPosition>
                </MainCard>
            </div>
        </>
    );
}
