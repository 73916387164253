import React, { useEffect } from "react";
import { MainCard } from "../../components/cards/card";
import CustomTable from "../../components/table/table";
import { ReactComponent as NoServices } from "../../assets/svg/no-services.svg";
import { useNavigate, useParams } from "react-router-dom";
import { deleteEmployeeAction } from "../../redux1/Slices/employeesSlice";
import { useDispatch } from "react-redux";
import { formatDate } from "../../utils/commonFunctions";

export default function EmployeeTable({ data = [], setDialog, getEmployees, setData = null }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const emptyMessage = () => {
        return (
            <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
                <NoServices />
                <h2>Employees not found</h2>
            </div>
        );
    };

    const editEmployee = (col) => {
        if (setData !== null) {
            setData({
                id: col?._id,
                fullName: col?.fullName,
                dob: formatDate(col?.dob, "UTC", "ISO"),
                gender: col?.gender,
                email: col?.email,
                department: col?.department?._id,
                isLicensed: col?.isLicensed,
                isUpdate: true,
            });
            window.scrollTo({ top: 0 });
        } else {
            navigate("update/" + col?._id);
        }
    };

    const deleteEmployee = (id) => {
        setDialog({
            visible: true,
            title: "Are you sure you want to delete Department?",
            type: "DELETE",
            cancel: true,
            saveFunction: () => dispatch(deleteEmployeeAction({ id, next: () => getEmployees(), setDialog })),
        });
    };

    // Table
    const columns = [
        { field: "fullName", header: "Full Name", sortable: true },
        { field: "isLicensed", header: "License", sortable: true, body: "LICENSE" },
        { field: "dob", header: "Date of Birth", body: "DATE", sortable: true },
        { field: "gender", header: "Gender", sortable: true },
        { field: "email", header: "Email", sortable: true },
        { field: "department.name", header: "Department", sortable: true },
        { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
    ];
    return <CustomTable columns={columns} emptyMessage={emptyMessage} data={data} edit={editEmployee} trash={deleteEmployee} />;
}
