import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, multipartApi } from "../../services/api";
import Constants from "../../services/constants";
import { authenticate } from "../../services/auth";
import { convertToFormdata } from "../../utils/commonFunctions";
import { hideLoader, showLoader } from "./loaderSlice";
import { showToast } from "./toastSlice";
var base64 = require("base-64");

const initialState = {
    data: null,
    // loading: false,
    // error: null,
};

export const loginAction = createAsyncThunk("user/login", async ({ data, navigate }, { dispatch, getState }) => {
    try {
        let payload = {
            email: data.email,
            password: data.loginPassword,
        };
        dispatch(showLoader());

        const res = await api("post", Constants.END_POINT.LOGIN, payload);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(loginSlice.actions.setUserDetail(res.data));
            authenticate(res.data.token, res.data.isFirstLogin ? () => navigate("/setup-board") : () => navigate("/dashboard"));
            // navigate("/dashboard")
            localStorage.setItem("User", JSON.stringify(res.data));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const myProfileAction = createAsyncThunk("user/my_profile", async ({ accessToken }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.MY_PROFILE);
        if (res.success && res.data) {
            dispatch(loginSlice.actions.setUserDetail(res.data));
            localStorage.setItem("User", JSON.stringify(res.data));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const updatePasswordAction = createAsyncThunk("user/update_password", async ({ data, setDialog, setData }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const payload = {
            oldPassword: data?.oldPassword,
            newPassword: data?.password,
        };

        const res = await api("post", Constants.END_POINT.UPDATE_PASSWORD, payload);
        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            setDialog({ visible: false });
            setData({
                oldPassword: "",
                password: "",
                confirmPassword: "",
            });
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            setDialog({ visible: false });
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        setDialog({ visible: false });
        return Promise.reject("An error occurred.");
    } finally {
        setDialog({ visible: false });
        dispatch(hideLoader());
    }
});

export const sendEmailApi = async (email, navigate, dispatch) => {
    dispatch(showLoader());

    var encodedEmail = base64.encode(email);
    const payload = { email };
    const res = await api("post", Constants.END_POINT.FORGEOT_PASSWORD_EMAIL, payload);
    dispatch(hideLoader());

    if (res.success) {
        navigate(`/otp-verification/${encodedEmail}`);
        dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
    }
};

export const SocialLoginAction = createAsyncThunk("user/socialLogin", async ({ payload, navigate }, { dispatch, getState }) => {

    try {
        const res = await api("post", Constants.END_POINT.SOCIAL_LOGIN, payload);
        if (res.success) {
            dispatch(loginSlice.actions.setUserDetail(res.data));
            authenticate(res.data.token, res.data.isFirstLogin ? () => navigate("/setup-board") : () => navigate("/dashboard"));
            // navigate("/dashboard")
            localStorage.setItem("User", JSON.stringify(res.data));
            return res;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

export const updateProfileAction = createAsyncThunk("user/update_profile", async ({ data, setDialog, navigate }, { dispatch }) => {
    try {
        dispatch(showLoader());

        const payload = data.deactivate
            ? { password: data.oldPassword }
            : {
                ...data,
            };
        const res = await api(data.deactivate ? "post" : "put", data.deactivate ? Constants.END_POINT.DEACTIVATE_PROFILE : Constants.END_POINT.UPDATE_PROFILE, payload);

        if (res.success) {
            dispatch(loginSlice.actions.setUserDetail(res.data));
            localStorage.setItem("User", JSON.stringify(res.data));
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            setDialog({ visible: false });
            if (data.deactivate) {
                localStorage.clear();
                navigate("/");
            }
            if (data.oldImage) {
                let image = data.oldImage.split("/")[1];
                await api("delete", Constants.END_POINT.UPLOAD_PROFILE_IMAGE + "/" + image, {});
            }
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
            setDialog({ visible: false });
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

const loginSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserDetail: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setUserDetail } = loginSlice.actions;

export default loginSlice.reducer;
