import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../utils/commonFunctions";
import { useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import { Link } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { Chip } from "primereact/chip";

export default function CustomTable({ data, columns, view, edit, trash, shareFunction, tableClass, emptyMessage, analytics, extraClassName, showLines = true, ...props }) {
    const isLoading = useSelector((state) => state.loader.isLoading);
    const items = Array.from({ length: 3 }, (v, i) => i);
    const actionTemplate = (col) => {
        return (
            <>
                {view ? <i className="pi pi-eye mr-3 cursor-pointer" onClick={() => view(col)} style={{ color: "grey" }} /> : null}
                {shareFunction ? <i className="pi pi-user mr-3 cursor-pointer" onClick={() => shareFunction(col)} style={{ color: "grey" }} /> : null}
                {edit ? <i className="pi pi-pencil mr-3 cursor-pointer" onClick={() => edit(col)} style={{ color: "green" }} /> : null}
                {analytics ? <i className="pi pi-chart-line mr-3 cursor-pointer" onClick={() => analytics(col._id)} style={{ color: "#66C2CB" }} /> : null}
                {trash ? <i className="pi pi-trash cursor-pointer" onClick={() => trash(col._id)} style={{ color: "red" }} /> : null}
            </>
        );
    };

    const dateTemplate = (col, field) => {
        if (col[field] === null || (field === "nextSurveyDate" && col.repetition === "ONE_TIME")) return <span className="text-500">-</span>;
        else return formatDate(col[field], "UTC");
    };

    const statusTemplate = (col, field) => {
        return (
            <div className="first-caps" style={{ color: col[field] === "REQUESTED" ? "#FFD248" : col[field] === "SCHEDULED" ? "#28B446" : "" }}>
                {col[field].toLowerCase()}
            </div>
        );
    };
    const licenseTemplate = (col, field) => {
        return (
            <div className="text-center p-1 text-xs border-round-xl" style={{ color: col[field] === true ? "#ffffff" : "#ffffff", backgroundColor: col[field] === true ? "#36626B" : "#CBCBCB" }}>
                {col[field] === true ? "Licensed" : "Unlicensed"}
            </div>
        );
    };
    const statusAction = (col, field) => {
        return (
            <div>
                <InputSwitch checked={col[field] === "ACTIVE" ? true : false} onChange={(e) => props.handlecSurveyStatus(col._id)} />
            </div>
        );
    };

    const scoreTemplate = (col, field) => {
        if (col[field] === 0) return <span className="p-3 border-round-xl text-900 inline-block flex align-items-center justify-content-center">0</span>;
        else if (!col[field] || col[field] === "") return <span className="p-3">-</span>;
        else if (col[field] > 0 && col[field] <= 49)
            return (
                <span className="p-3 border-round-xl text-900 inline-block flex align-items-center justify-content-center" style={{ background: "#FFCECE", width: "43px", height: "43px" }}>
                    {col[field]}
                </span>
            );
        else if (col[field] >= 50 && col[field] <= 74)
            return (
                <span className=" p-3 border-round-xl text-900 inline-block flex align-items-center justify-content-center" style={{ background: "#FDF4D1", width: "43px", height: "43px" }}>
                    {col[field]}
                </span>
            );
        else if (col[field] >= 75 && col[field] <= 100)
            return (
                <span className=" border-round-xl text-900 p-3 inline-block flex align-items-center justify-content-center" style={{ background: "#98DFBB", width: "43px", height: "43px" }}>
                    {col[field]}
                </span>
            );
        // else if (col[field] > 70 && col[field] <= 79)
        //     return (
        //         <span className="text-gray-500 p-3 inline-block flex align-items-center justify-content-center" style={{ width: "43px", height: "43px" }}>
        //             {col[field]}
        //         </span>
        //     );
        // else
        //     return (
        //         <span className="bg-green-100 border-round-xl text-green-800 p-3 inline-block flex align-items-center justify-content-center" style={{ width: "43px", height: "43px" }}>
        //             {col[field]}
        //         </span>
        //     );
    };

    const departmentTemplate = (col) => {
        return (
            <Link to={"/dashboard/department/" + col.id} className="no-underline cursor-pointer text-gray-500">
                {col.name}
            </Link>
        );
    };

    const focusAreaTemplate = (col) => {
        return (
            <Chip
                label={col?.departmentName || col?.Category}
                style={{
                    background: col?.score < 50 ? "#FFCECE" : col?.score >= 50 && col?.score < 75 ? "#FFF8E3" : "",
                    color: col?.score < 50 ? "#CF2933" : col?.score >= 50 && col?.score < 75 ? "#FFD248" : "",
                }}
            />
        );
    };

    const paragrapghTemplate = (col, field) => {
        return (
            <>
                <p className="w-full m-0 p-0" style={{ textWrap: 'wrap' }}>{col[field]}</p>
                {/* <div>
                    <Link className="text-primary" to={"/services"}>
                        Get our Recommendations
                    </Link>
                </div> */}
            </>
        );
    };

    return (
        <div className={extraClassName}>
            <DataTable
                value={isLoading ? items : data}
                resizableColumns
                emptyMessage={emptyMessage ? () => emptyMessage() : "No Data Available"}
                className={`${!showLines ? "hide-table-lines" : ""} ${tableClass}`}
                tableStyle={{ minWidth: "100%" }}
                sortMode="multiple"
            >
                {columns.map((col, i) => (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        body={
                            isLoading ? (
                                <Skeleton />
                            ) : col.body === "ACTION" ? (
                                actionTemplate
                            ) : col.body === "STATUS" ? (
                                (item) => statusTemplate(item, col.field)
                            ) : col.body === "STATUS_ACTION" ? (
                                (item) => statusAction(item, col.field)
                            ) : col.body === "DATE" ? (
                                (item) => dateTemplate(item, col.field)
                            ) : col.body === "SCORE" ? (
                                (item) => scoreTemplate(item, col.field)
                            ) : col.body === "DEPARTMENT_NAME" ? (
                                (item) => departmentTemplate(item, col.field)
                            ) : col.body === "LICENSE" ? (
                                (item) => licenseTemplate(item, col.field)
                            ) : col.body === "PARAGRAPH" ? (
                                (item) => paragrapghTemplate(item, col.field)
                            ) : col.body === "FOCUS_AREA" ? (
                                (item) => focusAreaTemplate(item, col.field)
                            ) : (
                                col.body
                            )
                        }
                        style={{ ...col.style }}
                        sortable={col.sortable}
                    />
                ))}
            </DataTable>
        </div>
    );
}
