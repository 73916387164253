import React from 'react';
import { ReactComponent as SuccssImg } from "../../../assets/svg/success.svg";
import PrimaryButton from '../../../components/buttons/button';
import { useNavigate, useParams } from 'react-router-dom';

export default function Success() {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div className='w-full h-screen flex align-items-center justify-content-center'>
      <div className='border p-6 bg-white shadow-1'>
        <SuccssImg className='mb-2' />
        {params.isPrivateSurvey ? <PrimaryButton label={"Go back"} onClick={() => navigate("/employee/surveys")} /> : ""}
      </div>
    </div>
  )
}
