import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    data: [],
    // isLoading: false,
    // error: null,
};

export const addDepartmentAction = createAsyncThunk("department/add_department", async ({ data, getDepartment }, { dispatch }) => {
    try {
        dispatch(showLoader());

        const payload = {
            ...data,
        };
        const res = await api("post", Constants.END_POINT.ADD_DEPARTMENT, payload);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            getDepartment();

            return res.data;
        } else {
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

export const updateDepartmentAction = createAsyncThunk("department/update_department", async ({ data, getDepartment }, { dispatch }) => {
    try {
        dispatch(showLoader());

        const payload = {
            ...data,
        };
        const res = await api("put", payload.enableDelete ? Constants.END_POINT.DELETE_DEPARTMENT + payload.id : Constants.END_POINT.UPDATE_DEPARTMENT + payload.id, payload);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            getDepartment();

            return res.data;
        } else {
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

export const departmentAction = createAsyncThunk("department/get_department", async ({ search = "" }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api("get", Constants.END_POINT.GET_DEPARTMENT + search);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(departmentSlice.actions.setDepartmentDetail(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));

            return Promise.reject(res.message);
        }
    } catch (error) {
        // dispatch(showToast({ severity: "error", summary: error.message }));

        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const departmentByIdAction = createAsyncThunk("department/get_departmentById", async ({ data }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.GET_DEPARTMENT_BY_ID + data);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            // dispatch(departmentSlice.actions.setDepartmentDetail(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));

            return Promise.reject(res.message);
        }
    } catch (error) {
        // dispatch(showToast({ severity: "error", summary: error.message }));

        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

const departmentSlice = createSlice({
    name: "department",
    initialState,
    reducers: {
        setDepartmentDetail: (state, action) => {
            state.data = action.payload;
        },
    },
    // extraReducers: (builder) => {
    //     builder.addCase(addDepartmentAction.pending, (state) => {
    //         state.isLoading = true;
    //         state.error = null; // Reset error on pending
    //     });
    //     builder.addCase(addDepartmentAction.fulfilled, (state, action) => {
    //         state.isLoading = false;
    //         state.data = action.payload;
    //         state.error = null;
    //     });
    //     builder.addCase(addDepartmentAction.rejected, (state, action) => {
    //         state.isLoading = false;
    //         state.error = action.error.message;
    //     });
    // },
});

export const { setDepartmentDetail } = departmentSlice.actions;

export default departmentSlice.reducer;
