import DashboardIcon from "../assets/icons/dashboard.png";
import SurveyIcon from "../assets/icons/surveys.png";
import ServiceIcon from "../assets/icons/services.png";
import JobOpeningIcon from "../assets/icons/job-opening.png";
import EmployeeIcon from "../assets/icons/employees.png";
import NotificationIcon from "../assets/icons/notification-bing.png";
import HelpIcon from "../assets/icons/help.png";
import SettingIcon from "../assets/icons/setting.png";
import { useState } from "react";

const LayoutData = () => {
    const [isFullSidebar, setIsFullSidebar] = useState(true);
    const menuItems = [
        {
            name: "Dashboard",
            link: "/dashboard",
            icon: DashboardIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Surveys",
            link: "/surveys",
            icon: SurveyIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Services",
            link: "/services",
            icon: JobOpeningIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Employees",
            link: "/employees",
            icon: EmployeeIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Notifications",
            link: "/notifications",
            icon: NotificationIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Help",
            link: "/help",
            icon: HelpIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
        {
            name: "Settings",
            link: "/settings",
            icon: SettingIcon,
            iconWidth: "24px",
            iconHeight: "24px",
        },
    ];
    return { menuItems, isFullSidebar, setIsFullSidebar };
};
export default LayoutData;
