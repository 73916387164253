import React, { useState } from 'react'
import { MainCard } from '../../../components/cards/card';
import { CustomForm, CustomPassword } from '../../../components/input/input';
import { updatePasswordAction } from '../../../redux1/Slices/loginSlice';
import { showFormErrors } from '../../../utils/commonFunctions';
import { useDispatch } from 'react-redux';
import { allValidations } from '../../../utils/formValidations';

export default function ChangePassword() {
    const dispatch = useDispatch();
    const [dialog, setDialog] = useState({
        visible: false,
        title: "",
        description: null,
        type: "",
        cancel: false,
        saveFunction: null,
    });
    const [data, setData] = useState({
        oldPassword: "",
        password: "",
        newConfirmPassword: "",
    });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };


    const saveFunction = () => {
        if (showFormErrors(data, setData)) {
        setDialog({
            visible: true,
            title: "Are you sure you want to change password ?",
            type: "SAVE",
            cancel: true,
            saveFunction: changePassword,
        });
    }
    };
    const changePassword = () =>{
        dispatch(updatePasswordAction({data, setDialog, setData}))
    }

    const cancelFuncation = () =>{
        setData({
            oldPassword: "",
            password: "",
            newConfirmPassword: "",
        })
    }
    return (
        <MainCard title="Change Password" dialog={dialog} setDialog={setDialog} saveFunction={saveFunction} cancelFuncation={cancelFuncation}>
            <CustomForm>
                <CustomPassword label="Old Password" hideFooter required name="oldPassword" placeholder="*********" col={12} />
                <CustomPassword label="New Password" required name="password" placeholder="*********" col={12} />
                <CustomPassword label="Confirm Password" name="newConfirmPassword" placeholder="*********" col={12} />
            </CustomForm>
        </MainCard>
    );
}
