import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layout";
import ServiceForm from "./ServiceForm";
import { useDispatch, useSelector } from "react-redux";
import { categoryAction } from "../../redux1/Slices/categorySlice";
import { jobDetailAction, requestServiceAction, serviceAction } from "../../redux1/Slices/servicesSlice";
import { allValidations } from "../../utils/formValidations";
import { MainCard, ServiceCard } from "../../components/cards/card";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showFormErrors } from "../../utils/commonFunctions";
import PrimaryButton, { PrimaryButtonOutlined } from "../../components/buttons/button";
import { Menu } from "primereact/menu";

export default function RequestService() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    const service = useSelector((state) => state.services.details);
    const categories = useSelector((state) => state.category.data);
    const services = useSelector((state) => state.services.data);
    const isLoading = useSelector((state) => state.loader.isLoading);
    const [dialog, setDialog] = useState({ visible: false });
    const [selectedCategory, setSelectedCategory] = useState(null);
    const serviceFilterMenu = useRef(null);
    const [serviceFilter, setServiceFilter] = useState("");
    const catFilter = useRef(null);
    const [data, setData] = useState({
        category: null,
        service: null,
        minBudget: "",
        maxBudget: "",
        lengthOfContract: "",
        timeframe: "",
        location: "",
        notes: "",
    });

    const serviceOptions = [
        { label: "Most Popular", command: () => setServiceFilter("Most Popular") },
        { label: "View All", command: () => setServiceFilter("View All") },
    ];

    const handleChange = ({ name, value }) => {
        let ingoreFields = ["notes"];
        const formErrors = allValidations(name, value, data, ingoreFields);
        if (name === "category") {
            setData((prev) => ({ ...prev, [name]: value, service: prev?.service === null ? null : "", formErrors }));
            setSelectedCategory(value);
        } else setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleRequestService = () => {
        let ingoreFields = ["notes"];

        if (showFormErrors(data, setData, ingoreFields)) {
            setDialog({
                visible: true,
                title: " Are you sure you want to save the details?",
                cancel: true,
                saveLabel: "Save",
                buttonPosition: "center",
                saveFunction: () => dispatch(requestServiceAction({ data, id: id || null, next: () => navigate("/services") })),
            });
        }
    };

    useEffect(() => {
        dispatch(categoryAction({}));
        // dispatch(serviceAction({}));
    }, []);

    useEffect(() => {
        if (serviceFilter !== "" && serviceFilter !== "View All") {
            dispatch(serviceAction({ category: selectedCategory ? selectedCategory._id : "", search: `searchKeys=["popularity"]&searchString=${serviceFilter.split(" ").join("_").toUpperCase()}` }));
        } else {
            dispatch(serviceAction({ category: selectedCategory ? selectedCategory._id : "", search: `searchKeys=["popularity"]&searchString=` }));
        }
    }, [serviceFilter, selectedCategory, dispatch]);
    // useEffect(() => {
    //     if (selectedCategory !== "") {
    //         dispatch(serviceAction({ category: selectedCategory }));
    //     }
    // }, [selectedCategory]);

    // console.log("selectedCategory", selectedCategory);

    useEffect(() => {
        if (id) dispatch(jobDetailAction({ id }));
        else {
            setData({
                service: state?.service || null,
                category: state?.category || null,
                maxBudget: "",
                lengthOfContract: "",
                timeframe: "",
                location: "",
                notes: "",
            });
        }
    }, [id]);

    useEffect(() => {
        if (service?._id && id) {
            setData({
                category: service?.category?._id,
                service: service?.service?._id,
                maxBudget: service?.maxBudget,
                lengthOfContract: service?.lengthOfContract,
                timeframe: service?.timeframe,
                location: service?.location,
                notes: service?.notes,
            });
            setSelectedCategory(service?.category?._id);
        }
    }, [service]);

    return (
        <MainCard
            dialog={dialog}
            setDialog={setDialog}
            saveLabel={id ? "Update" : data.service !== null && !id ? "Save" : null}
            saveFunction={data.service !== null ? () => handleRequestService() : null}
            cancelFuncation={data.service !== null ? () => navigate("/services") : null}
            background={"bg-transparent"}
            border="none"
            padding="p-0"
        >
            <Menu model={serviceOptions} popup ref={serviceFilterMenu} className="filter-menu" />
            {data.service === null ? <div className="flex justify-content-between">
                <h3 className="mt-0">Available Services</h3>
                <div className="col flex gap-3 justify-content-end">
                    {selectedCategory || serviceFilter === "Most Popular" ? <PrimaryButton
                        label="Clear"
                        style={{ background: "var(--color1)", border: "none" }}
                        onClick={() => {
                            setServiceFilter("View All");
                            setSelectedCategory(null);
                        }}
                    /> : null}
                    {/* style={{ maxWidth: "190px" }} */}
                    <PrimaryButtonOutlined
                        label={selectedCategory ? selectedCategory.name : "Select Category"}
                        icon="pi pi-filter"
                        iconPos="right"
                        className=""
                        onClick={(event) => catFilter.current.toggle(event)}
                    />
                    <Menu
                        model={categories.map((item) => ({ label: item.name, command: () => setSelectedCategory(item), className: selectedCategory === item ? "bg-skyblue" : "" }))}
                        popup
                        ref={catFilter}
                        id="popup_menu_right"
                    />
                    <PrimaryButtonOutlined label={serviceFilter === "" ? "View All" : serviceFilter} icon="pi pi-filter" iconPos="right" onClick={(event) => serviceFilterMenu.current.toggle(event)} />
                </div>
            </div> : null}
            {/* <ServiceForm data={data} categories={categories} handleChange={handleChange} services={services} /> */}
            {/* {data?.service === null && data?.category !== null ? (
                <>
                    <h3 className="mt-0">Available Services</h3>
                    {isLoading ? "" : services.length ? <ServiceCard services={services} data={data} setData={setData} /> : <p>Sorry, currently no service available for selected category</p>}
                </>
            ) : null} */}
            {data.service === null ? (
                <ServiceCard services={services} data={data} setData={setData} setSelectedCategory={setSelectedCategory} />
            ) : (
                <ServiceForm data={data} categories={categories} handleChange={handleChange} services={services} />
            )}
        </MainCard>
    );
}
