import React from "react";
import { Topbar } from "../../layout/topbar";
import Tabs from "../../components/Tabs";
import Monthly from "./Monthly";
import Yearly from "./Yearly";

const Membership = () => {
    const tabs = [
        {
            name: "Monthly",
            path: "monthly",
            element: Monthly,
            exact: true,
        },
        {
            name: "Yearly",
            path: "yearly",
            element: Yearly,
            exact: true,
        },
    ];
    return (
        <>
            <Topbar onBoardingScreen={true} />
            <div className="container">
                <h1 className="text-semibold">Unlock unlimited benefits with Our Plans.</h1>
                <h4 className="text-lg font-normal text-gray-500 mt-0">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </h4>

                <Tabs tabs={tabs} className={"border-1 p-1 border-gray-300 border-round-lg max-w-max bg-white"} buttonBorder={"border-0"} />
            </div>
        </>
    );
};

export default Membership;
