import React, { useState } from "react";
import { Topbar } from "../../layout/topbar";
import { Steps } from "primereact/steps";
import { useNavigate } from "react-router-dom";
import AddDepartment from "./addDepartment";
import CreateSurvey from "./createSurvey";
import EmployeeForm from "../Common/EmployeeForm";
import AddEmployees from "./addEmployees";

export default function OnBoarding() {
    const [activeStep, setActiveStep] = useState(0);
    const [showSurveyForm, setSurveyForm] = useState(false);
    const navigate = useNavigate();

    const stepItems = [
        {
            label: "Add Department",
        },
        {
            label: "Add Employees",
        },
        {
            label: "Create Survey",
        },
    ];

    return (
        <div className="onboarding-page">
            <Topbar onBoardingScreen={true} />
            <div className="container">
                <h2 className="w-full text-center relative">
                    {activeStep !== 0 ? (
                        <span
                            className="absolute back-btn-boarding text-base text-primary bg-gray-400 cursor-pointer"
                            onClick={() => (showSurveyForm ? setSurveyForm(false) : setActiveStep((prev) => prev - 1))}
                        >
                            <i className="pi pi-angle-left text-white text-lg" />
                        </span>
                    ) : null}

                    <span>Let's get started with adding departments!</span>
                    <span className="absolute skip-boarding text-base text-primary" onClick={() => (activeStep !== 2 ? setActiveStep((prev) => prev + 1) : navigate("/dashboard"))}>
                        Skip
                    </span>
                </h2>
                <div className="lg:w-6 m-auto">
                    <Steps model={stepItems} activeIndex={activeStep} onSelect={(e) => setActiveStep(e.index)} readOnly={true} />
                </div>
                {activeStep === 0 ? (
                    <AddDepartment setActiveStep={setActiveStep} />
                ) : activeStep === 1 ? (
                    <AddEmployees setActiveStep={setActiveStep} />
                ) : (
                    <CreateSurvey setActiveStep={setActiveStep} showSurveyForm={showSurveyForm} setSurveyForm={setSurveyForm}></CreateSurvey>
                )}
            </div>
        </div>
    );
}
