import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutData from "./LayoutData";
import LogoMain from "../../assets/images/Logo-white.png";
import { useSelector } from "react-redux";
import { Menu } from "primereact/menu";
import ConfirmDailog from "../../components/overlay/Dialog";
import { imagePath } from "../../utils/commonFunctions";
import { Avatar } from "primereact/avatar";

export default function Sidebar({ style, extraClass }) {
    const location = useLocation();
    const user = useSelector((state) => state.user.data);
    const logoutBox = useRef(null);
    const navigate = useNavigate();
    const activeLink = location?.pathname;
    const { menuItems } = LayoutData();

    const [dialog, setDialog] = useState({
        visible: false
    });

    const logout = () =>{
        localStorage.clear();
        navigate("/");
    }

    return (
        <div className={`flex sidebar-nav flex-column bg-primary justify-content-between h-full fixed overflow-y-scroll bg-lightest pt-3 z-5 left-0 text-center ${extraClass}`} style={style}>
            <ConfirmDailog dialog={dialog} setDialog={setDialog} />

            <div className="">
                <div className="p-4 text-left">
                    <img src={LogoMain} style={{ width: "210px", height: "37px" }} alt="" className="mb-3" />
                </div>

                <ul className="list-none m-0 pl-4">
                    {menuItems.map((item, key) => (
                        <li key={key}>
                            <Link
                                to={item.link}
                                className={`p-3 flex align-items-center font-medium no-underline side_menuitem text-md ${activeLink.includes(item.link) ? "text-primary active " : " text-gray"}`}
                            >
                                <img src={item.icon} alt="" width={item.iconWidth} height={item.iconHeight} />
                                &nbsp;&nbsp;
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <ul className="my-8 m-0 px-4 list-none">
                <li className="flex justify-content-between align-items-center "   onClick={(event) => logoutBox.current.toggle(event)}>
                    <div className="flex align-items-center">
                        <div className="profile-img-container px-2">
                        {user?.image ? <img src={imagePath(user?.image)} className="w-3rem h-3rem border-circle" alt="Profile " /> : <Avatar icon="pi pi-user" className="mt-2" size="large" shape="circle" />}
                        </div>
                        <div className="ml-2 text-left">
                            <p className="text-sm font-medium text-white m-0 capitalize">{user?.firstName ? `${user?.firstName}  ${user?.lastName ? user?.lastName : ""}` : "" || user?.companyName}</p>
                            <p className="text-xs m-0 text-white capitalize">{user?.firstName ? user?.companyName : "Company"}</p>
                        </div>
                    </div>
                    <i className="pi pi-chevron-down text-white"></i>

                </li>
                <Menu
                        model={[
                            {
                                label: "Logout",
                                icon: "pi pi-sign-out",
                                command: () => {
                                    setDialog({
                                        visible: true,
                                        title: "Are you sure you want to Logout?",
                                        type: "LOGOUT",
                                        cancel: true,
                                        saveFunction: logout,
                                    });
                                },
                            },
                        ]}
                        popup
                        ref={logoutBox}
                    />
            </ul>
        </div>
    );
}
