import React from "react";
import { PlansCard } from "../../components/cards/card";
import { useNavigate } from "react-router-dom";

const Monthly = () => {
    const navigate = useNavigate();
    const MonthlyData = {
        isPurchased: false,
        features: ["1 admin user", "2 surveys ", "3 categories", "30 employee registrations"],
    };
    const yearlyData = {
        isPurchased: false,
        price: 1777,
        type: "yearly",
        type1: "monthly",
        features: ["1 admin user", "2 surveys ", "3 categories", "30 employee registrations"],
    };
    return (
        <div className="my-4">
            <div>
                <PlansCard type="year" extraClassName={"bg-white"} heading=" Culture Quest Zero" data={MonthlyData} onClick={() => navigate("/membership/customize")}></PlansCard>
            </div>
            <div>
                <h2 className="text-3xl text-semibold">Customize your plan based on needs and go PRO</h2>
                <PlansCard type="year" extraClassName={"bg-white"} data={yearlyData} onClick={() => navigate("/membership/customize")}></PlansCard>
            </div>
        </div>
    );
};

export default Monthly;
