import React, { useEffect, useState } from "react";
import PrimaryButton, { PrimaryButtonOutlined } from "./buttons/button";
import { useLocation, useNavigate } from "react-router-dom";

export default function Tabs({ tabs, status, tabPedding, className, buttonBorder }) {
    const [activeIndex, setActiveIndex] = useState();
    const navigate = useNavigate();

    const location = useLocation();

    const checkCurrentTab = () => {
        tabs?.forEach((item, index) => {
            if (location?.pathname.includes(item.path)) setActiveIndex(index);
        });
    };

    const ActiveComponent = ({ index }) => {
        let Component = tabs[index]?.element;
        if (!Component) return <></>;

        return <Component status={status ? tabs[index].name : ""} />;
    };

    useEffect(() => {
        if (location?.pathname === "/surveys" || location?.pathname === "/surveys/") {
            setActiveIndex(0);
        } else checkCurrentTab();
    }, [location]);

    useEffect(() => {
        if (location?.pathname === "/settings" || location?.pathname === "/settings/") {
            setActiveIndex(0);
        } else checkCurrentTab();
    }, [location]);
    useEffect(() => {
        if (location?.pathname === "/membership" || location?.pathname === "/membership/") {
            setActiveIndex(0);
        } else checkCurrentTab();
    }, [location]);
    useEffect(() => {
        if (location?.pathname === "/employee/settings" || location?.pathname === "/employee/settings/") {
            setActiveIndex(0);
        } else checkCurrentTab();
    }, [location]);

    return (
        <>
            <div className={`flex flex-wrap gap-3 ${className} `}>
                {tabs?.map((tab, index) => {
                    return activeIndex === index ? (
                        <PrimaryButton
                            label={tab.name}
                            key={index}
                            className={`p-2 ${tabPedding ? tabPedding : ""}`}
                            extraClass="min-w-min"
                            onClick={() => {
                                navigate(tab.path);
                                setActiveIndex(index);
                            }}
                        />
                    ) : (
                        <PrimaryButtonOutlined
                            label={tab.name}
                            key={index}
                            className={`p-2 text-500 ${tabPedding ? tabPedding : ""} ${buttonBorder} `}
                            onClick={() => {
                                navigate(tab.path);
                                setActiveIndex(index);
                            }}
                        />
                    );
                })}
            </div>
            <ActiveComponent index={activeIndex} />
        </>
    );
}
