import React from "react";
import LogoImg from "../../assets/images/Logo-white.png";

export const Privatelayout = ({ src, subtext, children, heading, subheading, childClassName, SuccessImg, subtextsize }) => {
    return (
        <div className="flex overflow-auto md:overflow bg-primary  " style={{ minHeight: "100vh" }}>
            <div className=" flex lg:col-5 col-12 lg:p-0 md:p-0 align-items-center justify-content-center m-auto overflow h-full " style={{ height: "100vh" }}>
                <div className={` px-3  ${childClassName}`}>
                    <div className="mb-4">
                        <img src={LogoImg} alt="" style={{ width: "351px", height: "65px" }} />
                    </div>
                    {SuccessImg ? <img src={SuccessImg} alt="" width={111} /> : null}
                    {heading ? <h2 className="text-3xl text-center text-white mb-2 w-full font-semibold">{heading}</h2> : null}
                    {subheading ? (
                        <p className={`${subtextsize}  font-normal text-center text-white mt-2 mb-3 w-full`}>
                            {subheading} {subtext ? <span className="text-skyblue font-medium">{subtext}</span> : null}
                        </p>
                    ) : null}
                    <div className="">{children}</div>
                </div>
            </div>
        </div>
    );
};
