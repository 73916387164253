import React, { useEffect, useState } from "react";
import { CustomCalenderInput, CustomDropDown, CustomForm, CustomInput, CustomInputNumber, CustomRadioButtons, CustomTextArea } from "../../components/input/input";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { getQuestionsAction } from "../../redux1/Slices/categorySlice";

export default function SurveyForm({ data, setData, departments, selectedCategories, disable, disableActiveSurvey, survey = {}, surveyId = null }) {
    const dispatch = useDispatch();
    const questions = useSelector((state) => state.category.questions);
    const [applicableFor, setApplicableFor] = useState({ name: "All Departments", value: "ALL" });
    const [selectedAplicableFor, setSelectedApplicableFor] = useState([]);

    const applicableOptions = [
        {
            name: "All Departments",
            value: "ALL",
        },
        {
            name: "Single Department",
            value: "SINGLE",
        },
        {
            name: "Multiple Departments",
            value: "MULTIPLE",
        },
    ];

    const repetitionOptions = [
        {
            name: "One time",
            value: "ONE_TIME",
        },
        {
            name: "Every month",
            value: "EVERY_MONTH",
        },
        {
            name: "Every second month",
            value: "EVERY_SECOND_MONTH",
        },
        {
            name: "Every quarter",
            value: "EVERY_QUARTER",
        },
        {
            name: "Every half year",
            value: "EVERY_HALF_YEAR",
        },
        {
            name: "Every year",
            value: "EVERY_YEAR",
        },
    ];

    const selectApplicable = ({ name, value, e }) => {
        setData({ ...data, applicableFor: [] });
        setApplicableFor(value);
        setSelectedApplicableFor([]);
    };

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        // if(name === "expiringOn") value = value.toISOString();
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const getCategoryQuestions = () => {
        dispatch(getQuestionsAction({ selectedCategories }));
        let companyDepartments = departments?.map((dept) => dept._id);
        setData({
            ...data,
            categories: [...selectedCategories],
            departments: [...companyDepartments],
            applicableFor: [...companyDepartments],
        });
    };

    const changeApplicableDepartment = () => {
        if (applicableFor.value === "SINGLE") {
            setData({ ...data, applicableFor: selectedAplicableFor?._id ? [selectedAplicableFor._id] : [] });
        } else if (applicableFor.value === "ALL") {
            setData({ ...data, applicableFor: [...departments?.map((dept) => dept._id)] });
        } else {
            let applicable = selectedAplicableFor.map((dept) => dept._id);
            setData({
                ...data,
                applicableFor: [...applicable],
            });
        }
    };

    useEffect(() => {
        changeApplicableDepartment();
    }, [selectedAplicableFor]);

    useEffect(() => {
        getCategoryQuestions(selectedCategories);
    }, [selectedCategories]);

    useEffect(() => {
        if (survey && surveyId) {
            if (survey?.applicableFor?.length === 1) {
                setApplicableFor({ name: "Single Department", value: "SINGLE" });
                setSelectedApplicableFor(survey?.applicableFor[0]);
            } else if (survey?.applicableFor?.length > 1) {
                setApplicableFor({ name: "Multiple Departments", value: "MULTIPLE" });
                setSelectedApplicableFor(survey?.applicableFor);
            } else return;
        }
    }, [survey]);

    return (
        <>
            {/* Survey Inputs */}
            <CustomForm extraclass="my-4 -mx-2 ">
                <CustomInput
                    col="3"
                    label="Title"
                    inputClass="bg-white"
                    extraClassName="p-2"
                    name="title"
                    data={data}
                    value={data?.title}
                    onChange={handleChange}
                    placeholder="Enter"
                    required={true}
                    disabled={disable}
                />
                <CustomCalenderInput
                    col="3"
                    label="Start Date"
                    extraClassName="p-2"
                    name="startDate"
                    minDate={new Date()}
                    maxDate={data?.expiringOn}
                    data={data}
                    onChange={handleChange}
                    placeholder="MM/DD/YYYY"
                    required={true}
                    disabled={disable || (disableActiveSurvey && surveyId)}
                />
                <CustomCalenderInput
                    col="3"
                    label="Expiring on"
                    extraClassName="p-2"
                    name="expiringOn"
                    data={data}
                    minDate={data?.startDate || new Date()}
                    onChange={handleChange}
                    placeholder="MM/DD/YYYY"
                    required={true}
                    disabled={disable || (disableActiveSurvey && surveyId)}
                />
                <CustomDropDown
                    col="3"
                    label="Repetition"
                    name="repetition"
                    value={data.repetition}
                    data={data}
                    options={repetitionOptions}
                    onChange={handleChange}
                    extraClassName="p-2"
                    placeholder="Enter"
                    required={true}
                    disabled={disable}
                />
                <CustomTextArea
                    label="Description"
                    extraClassName="p-2"
                    maxLength={150}
                    placeholder="Enter"
                    name="description"
                    value={data.description}
                    data={data}
                    onChange={handleChange}
                    disabled={disable}
                />
                <CustomRadioButtons
                    label="Applicable For"
                    data={applicableFor}
                    required={true}
                    extraClassName="p-2"
                    onChange={selectApplicable}
                    name="applicableFor"
                    options={applicableOptions}
                    disabled={disable || (disableActiveSurvey && surveyId)}
                />
                {applicableFor?.value === "SINGLE" ? (
                    <>
                        <CustomDropDown
                            value={selectedAplicableFor}
                            onChange={(e) => setSelectedApplicableFor(e.value)}
                            options={departments}
                            placeholder="Select a Department"
                            required
                            extraClassName="mb-0 p-2"
                            errorMessage={!selectedAplicableFor._id ? <small className="p-error">Department Required</small> : null}
                            disabled={disable || (disableActiveSurvey && surveyId)}
                        />
                        {/* <div className="pl-2">{!selectedAplicableFor._id ? <small className="p-error">Department Required</small> : null}</div> */}
                    </>
                ) : applicableFor?.value === "MULTIPLE" ? (
                    <>
                        <MultiSelect
                            value={selectedAplicableFor}
                            onChange={(e) => setSelectedApplicableFor(e.value)}
                            options={departments}
                            optionLabel="name"
                            placeholder="Select Departments"
                            maxSelectedLabels={selectedAplicableFor?.length}
                            className="w-full border mx-2"
                            display="chip"
                            required
                            disabled={disable || (disableActiveSurvey && surveyId)}
                        />
                        <div>{!selectedAplicableFor.length ? <small className="p-error text-xs ml-2">Department Required</small> : null}</div>
                    </>
                ) : null}
            </CustomForm>

            {/* Questions */}

            {Object.keys(questions)?.map((category, index) => {
                return (
                    <div key={index} className=" my-3  p-3 border bg-white">
                        <h2>{category}</h2>
                        {questions[category]?.map((item, key) => {
                            return (
                                <div key={key}>
                                    <h3>{key + 1 + ". " + item?.question}</h3>
                                    <CustomRadioButtons options={item.choices} disabled className="cursor-auto" />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
}
