import React from "react";
import { MainCard } from "../../../components/cards/card";
import CustomTable from "../../../components/table/table";
import moment from "moment";

export default function FilledSurveyTable({ data }) {
    // Table Columns
    const FilledSurveyColumns = [
        { field: "survey.title", header: "Survey Name", sortable: true },
        { field: "survey.description", header: "Description" },
        { field: "createdAt", header: "Filled On", body: (col) => moment(col.createdAt).format("DD-MM-YYYY") },
    ];

    return (
        <MainCard title={`Filled Surveys`} extraClass="p-0">
            <CustomTable columns={FilledSurveyColumns} data={data} />
        </MainCard>
    );
}
