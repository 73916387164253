import React, { useState } from "react";
import Constants from "../../services/constants";
import { CategoryOverviewcard } from "../../components/cards/card";
import plusIcon from "../../assets/icons/plus-icon.png";
import editIcon from "../../assets/icons/edit-icon.png";
import { CategoryDialog } from "../../components/overlay/Dialog";

export default function ShowCategories({
    showSurveyForm = false,
    categories,
    selectedCategories = [],
    setSelectedCategories,
    hideCheckbox = false,
    hideDescription = false,
    handleCategorySelection,
    hideAddMore = false,
}) {
    const [visible, setVisible] = useState(false);

    const handleAddMoreCategories = () => {
        localStorage.setItem("selectedCategories", JSON.stringify(selectedCategories));
        setVisible(true);
    };

    const cancelAddCategories = () => {
        setSelectedCategories(JSON.parse(localStorage.getItem("selectedCategories")));
        localStorage.removeItem("selectedCategories");
        setVisible(false);
    };

    return (
        <div className="flex flex-wrap gap-3">
            {showSurveyForm ? (
                <>
                    {categories.map((category, index) => {
                        return selectedCategories.includes(category._id) ? (
                            <div className="category-overview-card" key={index} style={{ width: "22%", minWidth: "250px" }}>
                                <CategoryOverviewcard
                                    imgclass=""
                                    name={category._id}
                                    selected={selectedCategories.includes(category._id)}
                                    onChange={() => handleCategorySelection(category._id)}
                                    src={Constants.HOST + category?.icon}
                                    hideDescription={showSurveyForm || hideDescription}
                                    hideCheckbox={hideCheckbox}
                                    disable={showSurveyForm}
                                    title={category?.name}
                                    subtitle={category?.description}
                                    extraClass="w-full h-full bg-white"
                                />
                            </div>
                        ) : null;
                    })}
                    {!hideAddMore ? (
                        <div className="category-overview-card" style={{ width: "22%", minWidth: "250px" }} onClick={handleAddMoreCategories}>
                            <CategoryOverviewcard
                                imgclass="w-full text-center"
                                hideDescription={showSurveyForm}
                                src={selectedCategories.length ===  categories.length ? editIcon : plusIcon}
                                title={selectedCategories.length ===  categories.length ? "Edit" : "Add More"}
                                extraClass="w-full h-full text-center cursor-pointer"
                                hideCheckbox={true}
                            />
                        </div>
                    ) : null}
                </>
            ) : (
                categories?.map((category, index) => {
                    return (
                        <div className="category-overview-card" key={index} style={{ width: "22%", minWidth: "250px" }}>
                            <CategoryOverviewcard
                                imgclass=""
                                name={category._id}
                                selected={selectedCategories.includes(category._id)}
                                onChange={selectedCategories.includes(category._id) && selectedCategories.length === 1 ? null : () => handleCategorySelection(category._id)}
                                src={Constants.HOST + category?.icon}
                                title={category?.name}
                                subtitle={category?.description}
                                extraClass="w-full h-full bg-white"
                                hideDescription={hideDescription}
                                hideCheckbox={hideCheckbox}
                            />
                        </div>
                    );
                })
            )}

            <CategoryDialog visible={visible} setVisible={setVisible} saveFunction={() => setVisible(false)} cancelFunction={() => cancelAddCategories()}>
                <ShowCategories categories={categories} selectedCategories={selectedCategories} handleCategorySelection={handleCategorySelection} />
            </CategoryDialog>
        </div>
    );
}
