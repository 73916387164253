import React, { useEffect, useState } from "react";
import EmployeeDashboardLayout from "../../../layout/employee";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PrimaryButton from "../../../components/buttons/button";
import DocumentIcon from "../../../assets/icons/document-text.png";
import { Dialog } from "primereact/dialog";
import { CustomTextArea } from "../../../components/input/input";
import ConfirmationDialog from "../../../components/overlay/confirmationDianlog";
import ConfirmDailog from "../../../components/overlay/Dialog";
import { addCoachRequestAction, coachRequestAction, updateCoachRequestAction } from "../../../redux1/Slices/coachRequestsSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, showFormErrors } from "../../../utils/commonFunctions";
import Constants from "../../../services/constants";

export default function CoachingSession() {
    const dispatch = useDispatch();

    const [requestCoachModal, setRequestCoachModal] = useState(false);
    const [dialog, setDialog] = useState({ visible: false });
    const [selectedRequest, setSelectedRequest] = useState({});
    const coachRequests = useSelector((state) => state.coachRequests.data);
    const [requestCoach, setRequestCoach] = useState("");
    const [isAdd, setIsAdd] = useState(true);

    const actionTemplate = (col) => {
        return (
            <>
                <div className="flex align-items-center">
                    <img src={DocumentIcon} alt="" className=" mr-3 cursor-pointer" style={{ width: "18px" }} onClick={() => (setIsAdd(false), setSelectedRequest({ ...col }), setRequestCoachModal(true))} />
                    <i className="pi pi-trash cursor-pointer text-red-600" onClick={() => deleteEmployee(col._id)}></i>
                </div>
            </>
        );
    };
    const BookSessionTemplate = (col) => {
        return (
            col.status === "Approved" ?
                <div className="flex justify-content-center">
                    <PrimaryButton className="border-round-2xl" label="Book session" onClick={() => window.open(Constants.BOOK_COACH_URL, '_blank')} />
                </div> : null
        );
    };
    const StatusTemplate = (col) => {
        return (
            <>
                {col.status === "Waiting" ? (
                    <span className="text-orange-400">{col.status}</span>
                ) : col.status === "Rejected" ? (
                    <span className="text-red-600">{"Declined"}</span>
                ) : (
                    <span className="text-green-600">{col.status}</span>
                )}
            </>
        );
    };
    const deleteEmployee = (id) => {
        setDialog({
            visible: true,
            title: "Are you sure you want to delete this coach request?",
            description: "Are you sure you want to delete this coach request? This action cannot be undone.",
            type: "DELETE",
            cancel: true,
            saveFunction: () => dispatch(updateCoachRequestAction({ data: { id: id, isDeleted: true }, setDialog })),
        });
    };

    useEffect(() => {
        dispatch(coachRequestAction(""));
    }, [dispatch]);

    const handleSubmitCoachRequest = () => {
        if (showFormErrors(requestCoach, setRequestCoach)) {
            dispatch(addCoachRequestAction({ payload: { description: requestCoach }, setRequestCoachModal }));
            setRequestCoach("")
        }
    };

    return (

        <EmployeeDashboardLayout heading="Coaching Sessions" label={"Request a Coach"} onClick={() => setRequestCoachModal(true)}>
            <DataTable value={coachRequests} dataKey="_id" tableStyle={{ minWidth: "50rem" }} emptyMessage={"No Data Available"} editMode="row">
                <Column field="createdAt" header="Created Date" body={(col) => formatDate(col.createdAt, "UTC")} sortable></Column>
                <Column field="service" header="Service" sortable></Column>
                <Column field="status" header="Status" body={StatusTemplate} sortable></Column>
                <Column header="" body={BookSessionTemplate}></Column>
                <Column field="" header="Actions" body={actionTemplate}></Column>
            </DataTable>
            <Dialog
                header="Note "
                headerClassName="text-center text-main-color"
                className="border-round-xl overflow-hidden"
                visible={requestCoachModal}
                style={{ width: "400px" }}
                onHide={() => {
                    if (!requestCoachModal) return;
                    setIsAdd(true);
                    setRequestCoachModal(false);
                }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <CustomTextArea name="note" placeholder="Type here.." value={isAdd ? requestCoach : selectedRequest.description} onChange={(e) => setRequestCoach(e.value)} disabled={isAdd ? false : true} />
                {isAdd ? <div className="flex justify-content-end">
                    <PrimaryButton label="Request" extraClass={"w-4"} onClick={handleSubmitCoachRequest} />
                </div> : null}
            </Dialog>
            <ConfirmDailog dialog={dialog} setDialog={setDialog} />

        </EmployeeDashboardLayout>
    );
}
