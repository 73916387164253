import React, { useState } from 'react';
import { MainCard } from '../../../components/cards/card';
import { CustomForm, CustomPassword } from '../../../components/input/input';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showFormErrors } from '../../../utils/commonFunctions';
import { allValidations } from '../../../utils/formValidations';
import { updateProfileAction } from '../../../redux1/Slices/loginSlice';

export default function DeactiveAccount() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        visible: false,
        title: "",
        description: null,
        type: "",
        cancel: false,
        saveFunction: null,
    });
    const [data, setData] = useState({
        deactivate: true,
        oldPassword: ""
    });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const saveFunction = () =>{
        if (showFormErrors(data, setData)) {
            setDialog({
                visible: true,
                title: "Are you sure you want to Deactivate Account?",
                type: "SAVE",
                cancel: true,
                saveLabel: "DEACTIVATE",
                saveFunction: deactivateProfile,
            });
    }
}

    const deactivateProfile = () =>{
        dispatch(updateProfileAction({data, setDialog, navigate}))
    }

    const cancelFuncation = () =>{
        return null
    }
  
    return (
        <MainCard title="Deactivate Account"  saveLabel="Deactivate Account" saveFunction={saveFunction} dialog={dialog} setDialog={setDialog} cancelFuncation={cancelFuncation}>
            <CustomForm>
                <CustomPassword label="Current Password" hideFooter required name="oldPassword" placeholder="*********" col={12} />
            </CustomForm>
        </MainCard>
    );
}
