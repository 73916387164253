import React from "react";
import DashboardLayout from "../../layout";
import Tabs from "../../components/Tabs";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import ManageDepartments from "./Departments/ManageDepartments";
import Plans from "./Plans";
import Terms from "./Terms";
import About from "./About";
import PrivacyPolicy from "./PrivacyPolicy";
import DeactiveAccount from "./DeactiveAccount";

export default function Settings() {
    const tabs = [
        {
            name: "Edit Profile",
            path: "edit-profile",
            element: EditProfile,
            exact: true,
        },
        {
            name: "Change Password",
            path: "change-password",
            element: ChangePassword,
            exact: true,
        },
        {
            name: "Manage Departments",
            path: "manage-departments",
            element: ManageDepartments,
            exact: true,
        },
        {
            name: "Plans",
            path: "plans",
            element: Plans,
            exact: true,
        },
        {
            name: "Terms of Use",
            path: "tems-of-use",
            element: Terms,
            exact: true,
        },
        {
            name: "About",
            path: "about",
            element: About,
            exact: true,
        },
        {
            name: "Privacy Policy",
            path: "privacy-policy",
            element: PrivacyPolicy,
            exact: true,
        },
        {
            name: "Deactivate Account",
            path: "deactivate-account",
            element: DeactiveAccount,
            exact: true,
        },
    ];

    return (
        <DashboardLayout heading="Settings">
            <Tabs tabs={tabs} />
        </DashboardLayout>
    );
}
