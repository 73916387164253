import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    data: [],
    errorData: [],
    employee: {},
    employeeByDept: [],
    requested: [],
};

export const employeeAction = createAsyncThunk("employee/get_employees", async ({ search = "" }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.EMPLOYEES + search);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(employeesSlice.actions.setEmployeeList(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(employeesSlice.actions.setEmployeeList(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});
export const employeeByDeptAction = createAsyncThunk("employee/get_dept_employees", async ({ departments }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("post", Constants.END_POINT.EMPLOYEES_BY_DEPT, { deptIds: departments });
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(employeesSlice.actions.setDeptEmployeeList(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(employeesSlice.actions.setDeptEmployeeList(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const employeeByIdAction = createAsyncThunk("employee/get_employee", async ({ id }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("get", Constants.END_POINT.EMPLOYEES + "/" + id);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(employeesSlice.actions.setEmployee(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(employeesSlice.actions.setEmployee(res.data));

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const manageEmployeeAction = createAsyncThunk("employee/manage_employee", async ({ data, id = null, setDialog, next }, { dispatch, getState }) => {
    try {
        // dispatch(showLoader());

        const res = id ? await api("put", Constants.END_POINT.EMPLOYEES + `/${id}`, data) : await api("post", Constants.END_POINT.EMPLOYEES, data);
        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            // dispatch(employeesSlice.actions.setEmployeeList(res.data));
            next();
            setDialog({ visible: false });

            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            setDialog({ visible: false });
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        // dispatch(hideLoader());
    }
});

export const uploadEmployeeAction = createAsyncThunk("employee/upload_employee", async ({ data, setDialog, setEmployeesData, next }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        setDialog({ visible: false });
        const res = await api("post", Constants.END_POINT.EMPLOYEES_UPLOAD, data);
        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            setEmployeesData([]);
            dispatch(resetErrorList());
            // dispatch(employeesSlice.actions.setEmployeeList(res.data));
            next();
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            setDialog({ visible: false });
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const validateEmployeeAction = createAsyncThunk("employee/validate_employee", async ({ data, next }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api("post", Constants.END_POINT.EMPLOYEES_VALIDATE_CSV, { ...data });
        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(employeesSlice.actions.setEmployeeErrorList(res.data));

            next();
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const updateBulkDepartmentsAction = createAsyncThunk("employee/update_bulk_employee", async ({ payload, next, setDialog }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("post", Constants.END_POINT.EMPLOYEES_BULK_UPDATE_DEPT, payload);
        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            // dispatch(employeesSlice.actions.setEmployeeList(res.data));
            next();
            setDialog({ visible: false });
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            next();
            setDialog({ visible: false });
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const deleteEmployeeAction = createAsyncThunk("employee/delete_employee", async ({ id = null, next, setDialog }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const res = await api("delete", Constants.END_POINT.EMPLOYEES + `/${id}`, {});
        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            // dispatch(employeesSlice.actions.setEmployeeList(res.data));
            next();
            setDialog({ visible: false });
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            next();
            setDialog({ visible: false });
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

const employeesSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {
        setEmployeeErrorList: (state, action) => {
            state.errorData = action.payload;
        },
        setEmployeeList: (state, action) => {
            state.data = action.payload;
        },
        setDeptEmployeeList: (state, action) => {
            state.employeeByDept = action.payload;
        },
        setEmployee: (state, action) => {
            state.employee = action.payload;
        },
        resetErrorList: (state, action) => {
            state.errorData = [];

        }
    },
});

export const { setEmployeeList, setEmployeeErrorList, resetErrorList } = employeesSlice.actions;

export default employeesSlice.reducer;
