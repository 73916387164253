import React, { useEffect, useState } from "react";
import { Privatelayout } from "../layout/Privatelayout";
import EmployeeImg from "../../assets/images/employee.png";
import CompanyImg from "../../assets/images/company.png";
import PrimaryButton from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, role } from "../../services/auth";

export default function SelectRole() {
    const [activeRole, setActiveRole] = useState("");
    const navigate = useNavigate()

    const roles = [
        { title: "Are you an Employee?", img: EmployeeImg, name: "employee" },
        { title: "Are you a Company Admin?", img: CompanyImg, name: "admin" },
    ];
    const toggleClass = (i, name) => {
        setActiveRole(name);
    };

    const handleNavigate = () => {
        if (activeRole === "employee") {
            navigate("/employee-login")

        } else {
            navigate("/login")
        }
    }

    useEffect(() => {
        if (isAuthenticated()) {
            if (role() === 'EMPLOYEE') {
                navigate("/employee/dashboard");
            } else {
                navigate("/dashboard");
            }
        }
    }, [navigate]);

    return (
        <Privatelayout childClassName=" flex flex-column justify-content-center align-items-center col-8 " heading="Select your Profile">
            <div className="flex gap-6">
                {roles.map((item, i) => (
                    <div className={`select-role-box `} key={i}>
                        <p className="font-medium text-white text-center">{item.title}</p>
                        <div className={`bg-white role-card ${activeRole === item.name ? "active" : ""}`} onClick={() => toggleClass(i, item.name)}>
                            <img src={item.img} alt="" />
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex justify-content-center mt-5">
                <PrimaryButton label="Next" className="bg-white text-main-color w-20rem" onClick={handleNavigate} />
            </div>
        </Privatelayout>
    );
}
